// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration_header{
    margin-top: 2rem;
}

.registration-checkbox_container{
    display: flex;
    flex-direction: row;
    width: 400px;
    justify-content: space-between;
    margin: 1rem auto;
    align-items: flex-start;
}

.registration-checkbox_container .form-check-input{
    border-radius: 0;
}

.profile-registration_buisness{
    width: 80%;
    margin: 0 auto;
}

.profile-registration_buisness_col{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
}

.profile-registration_buisness_col .form-control, .profile-registration_buisness_col .form-select {
    border-radius: 0px;
}

.registration-buisness_radio{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 1rem;
}

.registration_btn{
    margin: 2rem auto;
    width: -moz-fit-content;
    width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/styles/registration.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,8BAA8B;IAC9B,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,uBAAkB;IAAlB,kBAAkB;AACtB","sourcesContent":[".registration_header{\n    margin-top: 2rem;\n}\n\n.registration-checkbox_container{\n    display: flex;\n    flex-direction: row;\n    width: 400px;\n    justify-content: space-between;\n    margin: 1rem auto;\n    align-items: flex-start;\n}\n\n.registration-checkbox_container .form-check-input{\n    border-radius: 0;\n}\n\n.profile-registration_buisness{\n    width: 80%;\n    margin: 0 auto;\n}\n\n.profile-registration_buisness_col{\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n    margin-top: 1rem;\n}\n\n.profile-registration_buisness_col .form-control, .profile-registration_buisness_col .form-select {\n    border-radius: 0px;\n}\n\n.registration-buisness_radio{\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    justify-content: space-between;\n    margin-top: 1rem;\n}\n\n.registration_btn{\n    margin: 2rem auto;\n    width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
