// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    /* --bs-nav-link-hover-color: #2bd191be; */
    --bs-nav-link-hover-color:  #1fbb22;
    /* --main-green-color: #3AC9B5; */
    --main-green-color:  #1fbb22;
}`, "",{"version":3,"sources":["webpack://./src/styles/variables.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,mCAAmC;IACnC,iCAAiC;IACjC,4BAA4B;AAChC","sourcesContent":[":root {\n    /* --bs-nav-link-hover-color: #2bd191be; */\n    --bs-nav-link-hover-color:  #1fbb22;\n    /* --main-green-color: #3AC9B5; */\n    --main-green-color:  #1fbb22;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
