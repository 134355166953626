import React, { useState, useRef, useEffect } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import AxiosInstance from '../Axios';
import dayjs from 'dayjs';
import Capcha from './capcha/Capcha';



function RegistrationFormLviv({ userType }) {

    const [selectedComponentMaterial, setSelectedComponentMaterial] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState(false);
    const [selectedComponent1, setSelectedComponent1] = useState(false);
    const button = useRef(null);
    const [values, setValues] = useState({});
    let sendData = useRef({});
    const [valid1, setValid1] = useState(false);
    const [valid2, setValid2] = useState(false);
    const [success1, setSuccess1] = useState(false);
    const [success2, setSuccess2] = useState(false);
    const [capcha, setCapcha] = useState(false);
    const [openCapcha, setOpenCapcha] = useState(false);
    const [userAlert, setUserAlert] = useState(null);
    // Локація центру звернення
    const centerLocations = [
        "м. Львів", "м. Дрогобич", "м. Стрий", "м. Городок", "м. Самбір", "м. Золочів", "м. Перемишляни", "м. Червоноград", "м. Кам’янка-Бузька",
        "м. Червоноград", "м. Новояворівськ", "м. Яворів"
    ];
    // Для капчі
    const capchaRef = useRef(null);

    const { register, handleSubmit, formState, watch } = useForm({
        values: {
            firstName: '',
            lastName: '',
            phone: '',
            username: '',
            email: '',
            plainPassword: '',
            confirmPassword: '',
            employed: '',
            age: '',
            exDirector: '',
            currentDirector: '',
            gender: '',
            address: '',
            supportType: []
        }
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    // Коли відобразиється капча проскорлити екран, щоб капча була зверху екрана
    useEffect(() => {
        if (capchaRef.current) {
            capchaRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [openCapcha]);

    function sendForm() {
        return new Promise((resolve, reject) => {
            button.current.disabled = true;
            const configAll = { headers: { 'content-type': 'application/json', } }

            let isSelectedForm = false

            if (selectedComponent || selectedComponent1) {
                setOpenCapcha(true)
                isSelectedForm = true
            }
            const article = {
                ...sendData.current,
                roles: [
                    "ROLE_USER"
                ],
                active: "on",
                username: sendData.current.email,
                userType: userType,
                isSentForPYSupport: isSelectedForm
            }

            const { confirmPassword, ...changedArticle } = article;
            // console.log('changedArticle', changedArticle)
            AxiosInstance.post('/users', changedArticle, configAll).then((response) => {
                if (response.status == 201) {
                    setSuccess1(true);
                    resolve();
                }
            }).catch((error) => {
                setUserAlert(<Alert style={{ marginTop: '1rem' }} variant={'danger'}>
                    Такий логін вже існує
                </Alert>);
                button.current.disabled = false
            })
        });

    };

    useEffect(() => {
        if (valid1 && valid2) {
            setOpenCapcha(true);
            if (capcha) {
                sendFormGreen();
                sendForm();
            }
        }
    }, [valid1, valid2, capcha])

    useEffect(() => {
        if (success1 && success2 && capcha) {
            window.location.replace("/login");
        }
    }, [success1, success2])

    const handleOnSubmit = (data) => {
        setOpenCapcha(true);
        //setCapcha(true);
        sendData.current = data;
        if (!(selectedComponent || selectedComponent1) && capcha) {
            //console.log('умова виконана')
            sendForm().then(() => {
                window.location.replace('/login');
            })
        } else {
            setOpenCapcha(true);
            setValid1(true);
        }
    }

    const password = watch('plainPassword');

    //FOR GREEN FORM
    const FORM_ID = 6;
    const [formDataGreen, SetFormDataGreen] = useState(false);
    const [valuesGreen, setValuesGreen] = useState({});

    const sendDataGreen = useRef({})

    const { register: registerGreen, handleSubmit: handleSubmitGreen, formState: formStateGreen } = useForm({
        values: {
            typeOfSupport: '',
            nameSurname: '',
            number: '',
            email: '',
            question: '',
            location: ''
        }
    })

    useEffect(() => {
        let active = false;
        let res = AxiosInstance.get(`/forms?page=1&itemsPerPage=30&id=${FORM_ID}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            SetFormDataGreen(response.data[0]);
        }).then(() => {

        })
        return () => {
            active = true;
        };
    }, [])

    const handleInputChangeGreen = (e) => {
        const { name, value } = e.target;
        setValuesGreen({
            ...valuesGreen,
            [name]: value
        });
    };

    function sendFormGreen() {

        const formFiltred = formDataGreen.formFields.filter(field => {
            return field.type !== 'file';
        })

        const initalNames = formFiltred.map(field => {
            return field.name;
        }).reduce((a, v) => ({ ...a, [v]: '' }), {});

        const formAnswerFields = formFiltred.map(field => {
            return {
                name: field.name,
                formField: `/api/form_fields/${field.id}`,
                value: sendDataGreen.current[field.name],
                label: field.label,
                type: field.type
            };
        })

        const configFile = { headers: { 'content-type': 'multipart/form-data', } }

        const configAll = { headers: { 'content-type': 'application/json', } }

        const article = {
            form: `/api/forms/${formDataGreen.id}`,
            media: [],
            dateEntered: dayjs().format(),
            "view": false,
            formAnswerFields: formAnswerFields,
        }
        // console.log('article', article)
        AxiosInstance.post('/form_answers', article, configAll).then((response) => {
            if (response.status == 201) {
                setSuccess2(true)
            }
        }).catch(() => {

        })

    };

    const onSubmitGreen = (data) => {
        sendDataGreen.current = data
        setValid2(true)
    }

    const bothHandleSubmit = () => {
        if (selectedComponent || selectedComponent1) {
            handleSubmitGreen(onSubmitGreen)();
        }
        handleSubmit(handleOnSubmit)();
    }

    useEffect(() => {
        if (openCapcha) {
            if (!capcha) {
                button.current.disabled = true;
            }
            else {
                button.current.disabled = false
            }
        }
    }, [capcha, openCapcha])

    return (
        <div className='main-container '>
            <Form className='profile-registration_buisness' noValidate >
                <Row id='feedback_row'>
                    {/* <Col className='profile-registration_buisness_col'>
                        <Form.Label>Логін</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            {...register('username', { required: 'is reqired!', minLength: { value: 5, message: 'length error' } })}
                            className={formState.errors.username !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.username && formState.errors.username.type === "required" && <span>Це обов'язкове поле!</span>}
                        {formState.errors.username && formState.errors.username.type === "minLength" && <span>Мінімальна довжина 5 символів!</span>}
                    </Col> */}
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Електронна пошта</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            {...register('email', { required: 'is reqired!', pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                            className={formState.errors.email !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.email && formState.errors.email.type === "required" && <span>Це обов'язкове поле</span>}
                        {formState.errors.email && formState.errors.email.type === "pattern" && <span>Перевірте емейл</span>}
                    </Col>
                    {/* </Row> */}
                    {/* <Row id='feedback_row'> */}
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Номер телефону</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.phone !== undefined && 'forms-invalid'}
                            {...register('phone', { minLength: 10, maxLength: 12 })}></Form.Control>
                        {formState.errors.phone && formState.errors.phone.type === "minLength" && <span>Перевірте номер!</span>}

                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control
                            type='password'
                            onChange={handleInputChange}
                            {...register('plainPassword', { required: 'is reqired!', minLength: 5 })}
                            className={formState.errors.plainPassword !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.plainPassword && formState.errors.plainPassword.type === "required" && <span>Це обов'язкове поле!</span>}
                        {formState.errors.plainPassword && formState.errors.plainPassword.type === "minLength" && <span>Мінімальна довжина 5 символи!</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Повторіть пароль</Form.Label>
                        <Form.Control
                            type='password'
                            onChange={handleInputChange}
                            {...register('confirmPassword', {
                                required: 'is reqired!', minLength: 5,
                                validate: (value) => value == password || 'паролі не збігаються'
                            })}
                            className={formState.errors.confirmPassword !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.confirmPassword && formState.errors.confirmPassword.type === "required" && <span>Це обов'язкове поле</span>}
                        {formState.errors.confirmPassword && formState.errors.confirmPassword.type === "validate" && <span>паролі не збігаються</span>}
                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label>Ім'я</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.firstName !== undefined && 'forms-invalid'}
                            {...register('firstName', { required: 'is reqired!', minLength: { value: 3, message: 'length err' } })}>
                        </Form.Control>
                        {formState.errors.firstName && formState.errors.firstName.type === "required" && <span>Це обов'язкове поле!</span>}
                        {formState.errors.firstName && formState.errors.firstName.type === "minLength" && <span>Мінімальна довжина 3 символи!</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label>Прізвище</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.lastName !== undefined && 'forms-invalid'}
                            {...register('lastName', { required: 'is reqired!', minLength: { value: 3, message: 'length err' } })}>
                        </Form.Control>
                        {formState.errors.lastName && formState.errors.lastName.type === "required" && <span>Це обов'язкове поле</span>}
                        {formState.errors.lastName && formState.errors.lastName.type === "minLength" && <span>Мінімальна довжина 3 символи</span>}
                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Зайнятість</Form.Label>
                        {/* <Form.Control placeholder='Обрати(Працюю - Не Працюю)'></Form.Control>     */}
                        <Form.Select aria-label="Default select example"
                            onChange={handleInputChange}
                            className={formState.errors.employed !== undefined && 'forms-invalid'}
                            {...register('employed', { required: 'is reqired!' })}>
                            <option disabled>Зайнятість</option>
                            <option value="Працюю">Працюю</option>
                            <option value="Не працюю">Не працюю</option>

                        </Form.Select>
                        {formState.errors.employed && formState.errors.employed.type === "required" && <span>Це обов'язкове поле</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Вік</Form.Label>
                        {/* <Form.Control placeholder='Обрати (18-30, 30-50, 50-60)'></Form.Control>  */}
                        <Form.Select aria-label="Default select example"
                            onChange={handleInputChange}
                            className={formState.errors.age !== undefined && 'forms-invalid'}
                            {...register('age', { required: 'is reqired!' })}>
                            <option disabled>Виберіть вік</option>
                            <option value="18-30">18-30</option>
                            <option value="30-50">30-50</option>
                            <option value="50-60">50-60</option>
                        </Form.Select>
                        {formState.errors.age && formState.errors.age.type === "required" && <span>Це обов'язкове поле</span>}
                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Діючий Роботодавець</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.currentDirector !== undefined && 'forms-invalid'}
                            {...register('currentDirector', { required: 'is reqired!' })}></Form.Control>
                        {formState.errors.currentDirector && formState.errors.currentDirector.type === "required" && <span>Це обов'язкове поле</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Стать</Form.Label>
                        {/* <Form.Control placeholder='Обрати (Чоловік - Жінка)'></Form.Control>  */}
                        <Form.Select aria-label="Default select example"
                            onChange={handleInputChange}
                            className={formState.errors.gender !== undefined && 'forms-invalid'}
                            {...register('gender', { required: 'is reqired!' })}>
                            <option disabled>Стать</option>
                            <option value="Чоловік">Чоловік</option>
                            <option value="Жінка">Жінка</option>
                        </Form.Select>
                        {formState.errors.gender && formState.errors.gender.type === "required" && <span>Це обов'язкове поле</span>}

                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Попередній Роботодавець</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.exDirector !== undefined && 'forms-invalid'}
                            {...register('exDirector', { required: 'is reqired!' })}></Form.Control>
                        {formState.errors.exDirector && formState.errors.exDirector.type === "required" && <span>Це обов'язкове поле</span>}

                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Адреса Проживання</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.adress !== undefined && 'forms-invalid'}
                            {...register("address", { required: 'is reqired!' })}></Form.Control>
                        {formState.errors.address && formState.errors.address.type === "required" && <span>Це обов'язкове поле</span>}

                    </Col>
                </Row>

                <h5 style={{ marginTop: '3rem', textAlign: 'center' }}>Мені необхідна/я шукаю:</h5>
                <div className="registration-buisness_radio" id='feedback_row'>
                    <Form.Check
                        id='castom_check_green_sw-material'
                        label='Матеріальна підтримка'
                        name='supportType'
                        value='Матеріальна'
                        // checked={formState?.values.supportType === 'material'}
                        checked={selectedComponentMaterial}
                        onClick={() => setSelectedComponentMaterial(!selectedComponentMaterial)}
                        className={formState.errors.supportType !== undefined && 'forms-invalid'}
                        {...register('supportType')}></Form.Check>
                    <Form.Check
                        id='castom_check_green_sw-legal'
                        name='supportType'
                        label='Юридична підтримка'
                        value='Юридична'
                        checked={selectedComponent}
                        // checked={formState?.values.supportType === 'Юридична'}
                        onClick={() => setSelectedComponent(!selectedComponent)}
                        className={formState.errors.supportType !== undefined && 'forms-invalid'}
                        {...register('supportType')}>
                    </Form.Check>
                    <Form.Check
                        id='castom_check_green_sw-psyco'
                        name='supportType'
                        value='Психологічна'
                        label='Психологічна підтримка'
                        checked={selectedComponent1}
                        // checked={formState?.values.supportType === 'Психологічна'}
                        onClick={() => setSelectedComponent1(!selectedComponent1)}
                        className={formState.errors.supportType !== undefined && 'forms-invalid'}
                        {...register('supportType')}>
                    </Form.Check>
                </div>

            </Form>
            {
                (selectedComponent || selectedComponent1) &&
                <div >
                    <h3 className='mt-5' style={{ textAlign: 'center' }}>Форма заявки на Психолого-Юридичну підтримку:</h3>
                    <Form className='forms-mentoring_form px-5 mt-4 mb-2 main-content' noValidate autoComplete='off'
                    >
                        <Row>
                            <Col className='forms-form-col'>
                                <Form.Label className='forms_label'>  <span className='forms_red_star'>*</span> Яку підтримку вам потрібно</Form.Label>

                                <Form.Select
                                    className={formStateGreen.errors.typeOfSupport !== undefined && 'forms-invalid'}
                                    {...registerGreen('typeOfSupport', { required: true })}

                                    onChange={handleInputChangeGreen}
                                >
                                    <option>Юридична</option>
                                    <option>Психологічна</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='forms-form-col'>
                                <Form.Label className='forms_label'>  <span className='forms_red_star'>*</span> Локація центру звернення <small>(оберіть місто)</small></Form.Label>

                                <Form.Select
                                    className={formStateGreen.errors.location !== undefined && 'forms-invalid'}
                                    {...registerGreen('location', { required: true })}

                                    onChange={handleInputChangeGreen}
                                >
                                    {
                                        centerLocations.map((city, index) => {
                                            return (<option key={index}>{city}</option>)
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='forms-form-col'>
                                <Form.Label className='forms_label'>  <span className='forms_red_star'>*</span> Ваше Ім'я та Прізвище</Form.Label>

                                <Form.Control
                                    className={formStateGreen.errors.nameSurname !== undefined && 'forms-invalid'}
                                    {...registerGreen('nameSurname', { required: true })}

                                    onChange={handleInputChangeGreen}
                                ></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='forms-form-col'>
                                <Form.Label className='forms_label'>  <span className='forms_red_star'>*</span> Ваша електронна пошта</Form.Label>

                                <Form.Control
                                    className={formStateGreen.errors.email !== undefined && 'forms-invalid'}
                                    {...registerGreen('email', { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}

                                    onChange={handleInputChangeGreen}
                                ></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='forms-form-col'>
                                <Form.Label className='forms_label'>  <span className='forms_red_star'>*</span> Ваш контактний телефон</Form.Label>

                                <Form.Control
                                    className={formStateGreen.errors.number !== undefined && 'forms-invalid'}
                                    {...registerGreen('number', { required: true, minLength: 10, maxLength: 12, pattern: /^\d+$/ })}

                                    onChange={handleInputChangeGreen}></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='forms-form-col'>
                                <Form.Label className='forms_label' >  <span className='forms_red_star'>*</span> Запитання</Form.Label>

                                <Form.Control
                                    className={formStateGreen.errors.question !== undefined && 'forms-invalid'}
                                    style={{ height: '5rem' }}
                                    {...registerGreen('question', { required: true })}

                                    onChange={handleInputChangeGreen}
                                ></Form.Control>
                            </Col>
                        </Row>
                    </Form>
                </div>
            }

            {
                openCapcha &&
                <div className='capcha_wrapper' ref={capchaRef}>
                    <Capcha setCapcha={setCapcha} />
                    {!capcha && <span>Введіть правильне значення. Заповнювати маленькими буквами</span>}
                </div>
            }
            {userAlert}
            <Button className='btn-rout registration_btn ' type='submit' onClick={bothHandleSubmit} ref={button}>Зареєструватись</Button>
        </div>
    )
}

export default RegistrationFormLviv
