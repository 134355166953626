import React from 'react';
import PromoImg from '../../components/Main/PromoImg';
import Veterans from '../../components/Main/Veterans/Veterans';

// Сторінка /veterans
const VeteransPage = () => {
    return (
        <>
            <PromoImg imgSrc="/images/Veterans/16x9/veterans-background.jpg" alt="Ветерани - карта" />
            <Veterans />
        </>
    );
};

export default VeteransPage;