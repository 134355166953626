import React, { useState } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import SearchField from '../../components/Main/SearchField'
import RegistrationBusiness from '../../components/Profile/RegistrationBusiness'
import RegistrationVPO from '../../components/Profile/RegistrationVPO'
import RegistrationFormLviv from '../../components/Profile/RegistrationFormLviv'

function RegistrationPage() {
    const [selectedComponent, setSelectedComponent] = useState(null);

    return (
        <>
            {/* <SearchField /> */}
            <Container>

                <h3 className='registration_header'>Реєстрація користувача</h3>
                <div >
                    <Form className="registration-checkbox_container feedback_row " id='adaptive_check' >
                        <Form.Check
                            id='castom_check_green'
                            name='component'
                            value='Lviv'
                            checked={selectedComponent === 'Lviv'}
                            onChange={() => setSelectedComponent('Lviv')}
                            label='Мешканець Львівщини'></Form.Check>
                        <Form.Check
                            id='castom_check_green'
                            label='ВПО'
                            name='component'
                            value='Vpo'
                            checked={selectedComponent === 'Vpo'}
                            onChange={() => setSelectedComponent('Vpo')}></Form.Check>
                        <Form.Check
                            id='castom_check_green'
                            label='Бізнес'
                            name='component'
                            value='business'
                            checked={selectedComponent === 'business'}
                            onChange={() => setSelectedComponent('business')}></Form.Check>
                    </Form>
                </div>
                {/* <RegistrationBusiness /> */}
                {selectedComponent === 'Lviv' && <RegistrationFormLviv userType={selectedComponent} />}
                {selectedComponent === 'Vpo' && <RegistrationVPO userType={selectedComponent} />}
                {selectedComponent === 'business' && <RegistrationBusiness userType={selectedComponent} />}

                {selectedComponent == null && <Button className='btn-rout my-5'>Зареєструватись</Button>}
            </Container>
        </>
    )
}

export default RegistrationPage
