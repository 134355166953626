import Spinner from "react-bootstrap/Spinner";

// Відображаються три кружечки
const MySpinner = () => {
    return (
        <div className="d-flex  justify-content-center">
            <Spinner className="me-2 login-spinner__color" animation="grow" />
            <Spinner className="me-2 login-spinner__color" animation="grow" />
            <Spinner className="me-2 login-spinner__color" animation="grow" />
        </div>
    )
};

export default MySpinner;