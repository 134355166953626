import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLoaderData, useLocation } from 'react-router-dom';
// import BusinessProgram from '../../../../components/Main/Business/BusinessProgram';
// import SearchField from '../../../../components/Main/SearchField';
import PromoImg from '../../../../components/Main/PromoImg';
import AxiosInstance from '../../../../components/Axios';
// import NeedToRegister from '../../../../components/Main/NeedToRegister';
// import isLoggedIn from '../../../../myFunction/isLoggedIn';
import Program from '../../../../components/Main/ListOfPrograms/Program';

const urlApi = '/news';
const page = 1;

export async function LoaderBusinessProgramPage(params) {
    // console.log("params Loader", params);
    let res = await AxiosInstance.get(`${urlApi}/${params.params.id}`).then((response) => {
        // console.log("response LoaderBusinessProgramPage", response);
        return response;
    });
    return res;
}

// Сторінка /business/list-of-programs/:id
const BusinessProgramPage = () => {
    const { data } = useLoaderData();
    // Виклик функції чи залогінений
    // const isLogIn = isLoggedIn();

    return (
        <>
            <Row className="business-promo__container">
                <Col className="business-promo__col-container">
                    <div className="business-promo__text-container">
                        <div className="business-promo__text-2">
                            {
                                (data != undefined) &&
                                <div className="">{data.name}</div>
                            }
                        </div>
                    </div>
                </Col>
                <Col className="p-0">
                    {/* <PromoImg imgSrc="/images/Business/BusinessCard_1.png" alt="Бізнес - перелік програми підтримки" /> */}
                    <PromoImg imgSrc="/images/images_16_9/Програми Підтримки Бізнесу.jpg" alt="Бізнес - перелік програми підтримки" />
                </Col>
            </Row>

            {/* <SearchField /> */}

            {/* Перевірка чи залогінений */}
            {/* {
                isLogIn.isLogIn ?
                    // <BusinessProgram data={data} />
                    <Program data={data} />
                    :
                    <NeedToRegister />
                } */}
            <Program data={data} />
        </>
    );
};

export default BusinessProgramPage;