import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import axios from 'axios';
import jwtDecode from 'jwt-decode';
// import AxiosInstance from '../../components/Axios';
import { Link, useLocation } from 'react-router-dom';

// Компонент для заповнення форми для посилання на пошту
const Reset = (props) => {
    // console.log('props Reset', props);

    const [validated, setValidated] = useState(false);
    const [password, setPassword] = useState("");
    const [password_2, setPassword_2] = useState("");
    const [messageError, setMessageError] = useState("Помилка");
    const [myAlert, setMyAlert] = useState(false);
    const [myAlertPass, setMyAlertPass] = useState(false);

    useEffect(() => {
        if (password === password_2) {
            setMyAlertPass(false);
        }
    }, [password, password_2]);

    const handleSubmit = (event) => {
        event.preventDefault();

        let validation = false;

        if (password === password_2 && password != "") {
            validation = true;
            setValidated(true);
        }

        if (password !== password_2) {
            setMessageError("Паролі не збігаються");
            setMyAlertPass(true);
        }

        if (validation) {
            const article = {
                hash: props.hash,
                password: password
            };

            axios.post(process.env.REACT_APP_SERVER_URL + '/api/user/new_pasword', article).then((response) => {
                // console.log('response', response);
                if (response.status === 200) {

                    if (response.data.rezult === true) {
                        window.location.replace("/login");
                    }
                    else if (response.data.rezult === false) {
                        setMessageError(response.data.message);
                        setMyAlert(true);
                    } else {
                        setMyAlert(true);
                    }
                } else {
                    // alert("Помилка");
                    setMessageError("Помилка");
                    setMyAlert(true);
                }
            });
        }
    };

    return (
        <div>
            <div className="my-5">
                <div className="wrapper mt-4">
                    <h2 className="mb-3">Введіть новий пароль</h2>
                    <Form
                        className="login-form"
                        // noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        {
                            (myAlertPass || myAlert) &&
                            <Row className="mb-3 mx-0 login-formGroup">
                                <Alert className="login-formGroup__control login-alert login-alert__width" key="info" variant="info">
                                    <div>
                                        {messageError}
                                    </div>
                                </Alert>
                            </Row>
                        }

                        {
                            !myAlert &&
                            <>
                                <Row className="mb-3">
                                    <Form.Group as={Col} className="login-formGroup"
                                        // md="4"
                                        controlId="validationPassword_1"
                                    >
                                        <Form.Label>Пароль*</Form.Label>
                                        <Form.Control
                                            className="login-formGroup__control"
                                            type="password"
                                            placeholder="Ввведіть пароль"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} className="login-formGroup"
                                        // md="4"
                                        controlId="validationPassword_2"
                                    >
                                        <Form.Label>Повторіть пароль*</Form.Label>
                                        <Form.Control
                                            className="login-formGroup__control"
                                            type="password"
                                            placeholder="Повторіть пароль"
                                            value={password_2}
                                            onChange={(e) => setPassword_2(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Col>
                                        <Button className="px-4 btn-rout" type="submit" color="primary">
                                            Відновити пароль
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }

                    </Form>

                    <Row className="mb-3">
                        <Col>
                            <Link to="/login" className="login-link">
                                {/* <Button className="px-4 btn-rout" color="primary"> */}
                                Повернутись
                                {/* </Button> */}
                            </Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </div >
    )
};

export default Reset;