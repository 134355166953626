import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import MainCard from '../MainCard';
import isLoggedIn from '../../../myFunction/isLoggedIn';

const Business = () => {

    const data = [
        {
            id: 1,
            title: "Програма підтримки",
            text: "В даному розділі Ви маєте змогу ознайомитись з усіма наявними програмами підтримки та грантами для створення та розвитку бізнесу, освоєння нової справи.",
            // imgSrc: "/images/Business/BusinessCard_1.png",
            imgSrc: "/images/images_16_9/Програми Підтримки Бізнесу.jpg",
            link: "/business/list-of-programs"
        },
        {
            id: 2,
            title: "Менторська підтримка",
            text: "Розділ менторської підтримки допоможе Вам отримати інформацію щоб зорієнтуватись в новому середовищі чи цінну пораду взаємодіючи з кваліфікованим менторим в певній галузі.",
            // imgSrc: "/images/Business/BusinessCard_2.png",
            // imgSrc: "/images/Business/two-businessmen-standing-near-table-looking-laptop-office.jpg",
            // imgSrc: "/images/Business/two-businessmen-standing-near-table-looking-laptop-office(180).png",
            imgSrc: "/images/images_16_9/Менторська Підтримка Бізнес.jpg",
            link: "/business/mentoring-program"
        },
        {
            id: 3,
            title: "Запит на релокацію",
            text: "Тут Ви зможете отримати вичерпну інформацію щодо механізмів релокації бізнесу та сукупних до цього процесів. А також заповнити форму на релокацію Вашого бізнесу.",
            // imgSrc: "/images/Business/BusinessCard_3.png",
            imgSrc: "/images/images_16_9/Запит на релокацію Бізнес.jpg",
            link: "/business/relocation-request"
        },
        {
            id: 4,
            title: "Пропозиції розміщення",
            text: "Даний розділ допоможе зорієнтуватись в пропозиціях розміщення Вашого бізнесу в регіоні. Ознайомить з переліком усіх наявних локацій.",
            // imgSrc: "/images/Business/BusinessCard_4.png",
            imgSrc: "/images/images_16_9/Пропозиції розміщення підприємств.jpg",
            link: "/business/accomodation-offers"
        },
    ];

    // Виклик функції чи залогінений
    const isLogIn = isLoggedIn();
    // console.log('isLogIn', isLogIn);

    return (
        <Container className="mt-5">
            <Row className='wrapper business-container'>
                {
                    data.map((card) => {
                        return (
                            <Col className="col-md-6 col-12 mb-5 mainCard-card" key={card.id}>
                                <MainCard title={card.title} text={card.text} imgSrc={card.imgSrc} link={card.link} isLogIn={isLogIn.isLogIn} />
                            </Col>
                        )
                    })
                }
                <Col className="col-6 mb-5">
                </Col>
            </Row>
        </Container>
    );
};

export default Business;