import React, { useEffect } from "react";

// Для закривання елемента при кліку поза елементом
const useOutsideClick = (elementRef, handler, attached = true) => {
    useEffect(() => {
        if (!attached) return;

        const handleClick = (e) => {
            if (!elementRef.current) return;
            // Елемент не знаходиться всередині виводу пошуку
            if (!elementRef.current.contains(e.target)) {
                // Виклик функції для закривання
                handler();
            }
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        }

    }, [elementRef, handler, attached]);
};

export default useOutsideClick;