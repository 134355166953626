import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Col, Form, Row, Button } from 'react-bootstrap'
import AxiosInstance from '../Axios';
import dayjs from 'dayjs';
import Capcha from '../Profile/capcha/Capcha';

function FeedbackForm({ setIsSubmit, setNameSurname, setIsOpen, setImageURL, form, setForm }) {

    const FORM_ID = 7;
    const button = useRef(null);
    const [formData, SetFormData] = useState({});
    const [file, setFile] = useState(false);
    const [selectedOptionType, setSelectedOptionType] = useState('Бізнес');
    const [selectedOption, setSelectedOption] = useState(null);
    const [capcha, setCapcha] = useState(false) // капча заповнена
    const [openCapcha, setOpenCapcha] = useState(false) // показати\сховати капчу
    
    const [values, setValues] = useState({});
    let sendData;
    const { register, handleSubmit, formState } = useForm({
        values: {
            typeOfRequest: '',
            firmName: '',
            placeOfRegistration: '',
            firstName: '',
            code: '',
            surname: '',
            description: '',
            number: '',
            email: '',
            type: ''
        }
    })

    useEffect(() => {
        let active = false;
        let res = AxiosInstance.get(`/forms?page=1&itemsPerPage=30&id=${FORM_ID}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            SetFormData(response.data[0]);
        }).then(() => {
            // console.log(formData)
        })

        return () => {
            active = true;
        };
    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });

    };

    function sendForm() {

        button.current.disabled = true;

        const formFiltred = formData.formFields.filter(field => {
            return field.type !== 'file';
        })

        const initalNames = formFiltred.map(field => {
            return field.name;
        }).reduce((a, v) => ({ ...a, [v]: '' }), {});

        const formAnswerFields = formFiltred.map(field => {
            // console.log(field.name)
            return {
                name: field.name,
                formField: `/api/form_fields/${field.id}`,
                value: typeof (sendData[field.name]) == 'object' ? JSON.stringify(sendData[field.name]) : sendData[field.name],
                label: field.label, type: field.type
            };
        })

        const configFile = { headers: { 'content-type': 'multipart/form-data', } }

        const configAll = { headers: { 'content-type': 'application/json', } }

        const article = {
            // name: formData.name,
            // date: dayjs().format(),
            form: `/api/forms/${formData.id}`,
            media: [],
            dateEntered: dayjs().format(),
            "view": false,
            formAnswerFields: formAnswerFields,
        }

        if (file) {
            const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects';
            const formFile = new FormData();
            formFile.append('file', file);
            formFile.append('fileName', file.name);

            axios.post(url, formFile, configFile).then((response) => {

                const articleFile = { ...article, media: [`${response.data['@id']}`] }

                axios.post(process.env.REACT_APP_SERVER_URL + '/api/form_answers', articleFile, configAll)
                    .then(() => {
                        setValues(initalNames);
                        setFile(false);
                        button.current.disabled = false;
                    })
                    .catch(() => {
                        setValues(initalNames);
                        setFile(false);
                    });
            });
        }
        else {
            AxiosInstance.post('/form_answers', article, configAll).then(() => {
                setValues(initalNames);
                setFile(false);
                button.current.disabled = false;
                setIsSubmit(true);
                setIsOpen(false);
                setImageURL('/images/chat_01.png');
            }).catch(() => {

            })
        }
    };

    const onSubmit = (data) => {
        setNameSurname(`${data.firstName} ${data.surname}`)
        sendData = data
        setOpenCapcha(true)
        if(capcha){
            sendForm();
        }
    }

    const handleCheckboxClick = (value) => {
        if (selectedOption === value) {
            setSelectedOption(null);
        } else {
            setSelectedOption(value);
            // setForm(value)
        }
    };

    useEffect(() => {
        if(openCapcha){
            if(!capcha){        
                button.current.disabled = true;
            }
            else{
                button.current.disabled = false
            }        
        }
    }, [capcha, openCapcha])

    // const handleCheckboxClickType = (value) => {
    //     if (selectedOptionType === value) {
    //         setSelectedOptionType(null);
            
    //     } else {
    //         setSelectedOptionType(value);
    //         setForm(value)
    //     }
    // };
    // useEffect(() => {
    //     console.log('selectedOption', selectedOption)
    //     console.log('selectedOptionType', selectedOptionType)
    // }, [selectedOptionType, selectedOption])
    let nameSurnameInput;
    if(window.innerWidth < 800){
        nameSurnameInput = <Row className='feedback_row_mobile' id='feedback_row_mobile'>
        <Col className='forms-form-col'>
            <Form.Label>  <span className='forms_red_star'>*</span> Ім'я:</Form.Label>
        </Col>
        <Col>
            <Form.Control
                onChange={handleInputChange}
                className={formState.errors.firstName !== undefined && 'forms-invalid'}
                {...register('firstName', { required: true })}></Form.Control>
        </Col>
        <Col className='forms-form-col' id='surname_label_mobile' >
            <Form.Label>  <span className='forms_red_star'>*</span> Прізвище:</Form.Label>
        </Col>
        <Col id='surname_mobile'>
            <Form.Control
                onChange={handleInputChange}
                className={formState.errors.surname !== undefined && 'forms-invalid'}
                {...register('surname', { required: true })}></Form.Control>
        </Col>
    </Row>
    }else{
        nameSurnameInput =  <Row className='feedback_row' id='feedback_row_name'>
            <Col className='forms-form-col'>
                <Form.Label>  <span className='forms_red_star'>*</span> Ім'я:</Form.Label>
            </Col>
            <Col className='forms-form-col' id='surname_label' >
                <Form.Label>  <span className='forms_red_star'>*</span> Прізвище:</Form.Label>
            </Col>
            <Col>
                <Form.Control
                    onChange={handleInputChange}
                    className={formState.errors.firstName !== undefined && 'forms-invalid'}
                    {...register('firstName', { required: true })}></Form.Control>
            </Col>
            <Col id='surname'>
                <Form.Control
                    onChange={handleInputChange}
                    className={formState.errors.surname !== undefined && 'forms-invalid'}
                    {...register('surname', { required: true })}></Form.Control>
            </Col>
        </Row>
    }

    return (
        <Form className='forms-feedback_form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <Form.Label style={{ float: 'left' }}>  <span className='forms_red_star'>*</span> Тип:</Form.Label> <br />
            <div className="check_boxes" id='feedback_row_check' style={{paddingTop: '0'}}>
                <Form.Check
                    id='castom_check1'
                    onChange={handleInputChange}
                    checked={selectedOptionType === 'ВПО' }
                    onClick={() => setForm('ВПО')}
                    label='ВПО'
                    value='ВПО'
                    {...register('type')}
                >
                </Form.Check>
                <Form.Check
                    id='castom_check2'
                    onChange={handleInputChange}
                    checked={form == 'Бізнес'}
                    // onClick={() => handleCheckboxClickType('Бізнес')}
                    label='Бізнес'
                    value='Бізнес'
                    {...register('type')}
                >
                </Form.Check>
            </div>
            <Form.Label style={{ float: 'left', marginTop: '1rem' }}>  <span className='forms_red_star'>*</span> Тип звернення:</Form.Label> <br />
            <div className="check_boxes" id='feedback_row_check'  style={{paddingTop: '0'}}>
                <Form.Check
                    id='castom_check3'
                    onChange={handleInputChange}
                    checked={selectedOption === 'Коментарі'}
                    onClick={() => handleCheckboxClick('Коментарі')}
                    label='Коментарі'
                    value='Коментарі'
                    {...register('typeOfRequest', { required: true })}>
                </Form.Check>

                <Form.Check
                    id='castom_check4'
                    onChange={handleInputChange}
                    checked={selectedOption === 'Пропозиції'}
                    onClick={() => handleCheckboxClick('Пропозиції')}
                    label='Пропозиції'
                    value='Пропозиції'
                    {...register('typeOfRequest', { required: true })}>
                </Form.Check>

                <Form.Check
                    id='castom_check5'                
                    onChange={handleInputChange}
                    checked={selectedOption === 'Запитання'}
                    onClick={() => handleCheckboxClick('Запитання')}
                    label='Запитання'
                    value='Запитання'
                    {...register('typeOfRequest', { required: true })}>

                </Form.Check>
            </div>
            <Row className='feedback_row'>
                <Col className='forms-form-col'>
                    <Form.Label>  <span className='forms_red_star'>*</span> Назва підприємства:</Form.Label>
                </Col>
                <Col>
                    <Form.Control
                        onChange={handleInputChange}
                        className={formState.errors.firmName !== undefined && 'forms-invalid'}
                        {...register('firmName', { required: true })}></Form.Control>
                </Col>
            </Row>
            <Row className='feedback_row'>
                <Col className='forms-form-col'>
                    <Form.Label>  <span className='forms_red_star'>*</span> Місце реєстрації:</Form.Label>
                </Col>
                <Col>
                    <Form.Control
                        onChange={handleInputChange}
                        className={formState.errors.placeOfRegistration !== undefined && 'forms-invalid'}
                        {...register('placeOfRegistration', { required: true })}></Form.Control>
                </Col>
            </Row>
            <Row className='feedback_row'>
                <Col className='forms-form-col'>
                    <Form.Label>  <span className='forms_red_star'>*</span> Код ЄДРПОУ:</Form.Label>
                </Col>
                <Col>
                    <Form.Control
                        onChange={handleInputChange}
                        className={formState.errors.code !== undefined && 'forms-invalid'}
                        {...register('code', { required: true, minLength: 8, maxLength: 8 })}></Form.Control>
                        {formState.errors.code && formState.errors.code.type === "minLength" && <span className='hint'>Мінімальна довжина 8 символів</span>}
                </Col>
            </Row>
            {/* для мобільних або десктоп*/}
            {nameSurnameInput}
            <Row className='feedback_row'>
                <Col className='forms-form-col'>
                    <Form.Label>  <span className='forms_red_star'>*</span> Електронна пошта:</Form.Label>
                </Col>
                <Col>
                    <Form.Control
                        onChange={handleInputChange}
                        className={formState.errors.email !== undefined && 'forms-invalid'}
                        {...register('email', { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}></Form.Control>
                         {formState.errors.email && formState.errors.email.type === "pattern" && <span className='hint'>У вигляді: example@gmail.com</span>}
                </Col>
            </Row>
            <Row className='feedback_row'>
                <Col className='forms-form-col'>
                    <Form.Label >  <span className='forms_red_star'>*</span> Мобільний телефон:</Form.Label>
                </Col>
                <Col>
                    <Form.Control
                        onChange={handleInputChange}
                        className={formState.errors.number !== undefined && 'forms-invalid'}
                        {...register('number', { required: true, minLength: 10, maxLength: 12, pattern: /^\d+$/ })}></Form.Control>
                        {formState.errors.number && formState.errors.number.type === "minLength" && <span className='hint'>Довжина 8 або 10 символів</span>}
                </Col>
            </Row>
            <Row className='feedback_row'>
                <Col className='forms-form-col'>
                    <Form.Label >  <span className='forms_red_star'>*</span> Поле для тексту звернення:</Form.Label>

                    <Form.Control
                        onChange={handleInputChange}
                        className={formState.errors.description !== undefined && 'forms-invalid'}
                        style={{ height: '5rem' }}
                        {...register('description', { required: true })}></Form.Control>
                </Col>
            </Row>
            <Row>
            {
                openCapcha && <div className='capcha_wrapper'>
                <Capcha setCapcha = {setCapcha}/>
                {!capcha && <span>Введіть правильне значення</span>}
                </div>
            }
            <Button variant="light" style={{ fontWeight: "500", borderRadius: "0px", margin: "1rem auto", width: '10rem' }} type='submit' ref={button}>Надіслати</Button>
            </Row>
        </Form>
    )
}

export default FeedbackForm
