import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import AxiosInstance from '../Axios';



function UpdateProfileBusiness({ userType, data }) {
    const [selectedComponent, setSelectedComponent] = useState(null);
    const button = useRef(null);
    const [values, setValues] = useState({});
    const regions = ["Вінницька", "Волинська", "Дніпропетровська", "Донецька", "Житомирська", "Закарпатська", "Запорізька", "Івано-Франківська", "Київська", "Кіровоградська", "Луганська", "Львівська", "Миколаївська", "Одеська", "Полтавська", "Рівненська", "Сумська", "Тернопільська", "Харківська", "Херсонська", "Хмельницька", "Черкаська", "Чернівецька", "Чернігівська", "Автономна Республіка Крим"];

    // console.log('data from father', data)
    let sendData;


    const { register, handleSubmit, formState, watch } = useForm({
        values: {
            firstName: (data && data.firstName) && data.firstName,
            phone: (data && data.phone) && data.phone,
            //username: '',
            email: (data && data.email) && data.email,
            plainPassword: '',
            confirmPassword: '',
            regionFrom: (data && data.regionFrom) && data.regionFrom,
            numberFOP: (data && data.numberFOP) && data.numberFOP,
            codeEDRPOU: (data && data.codeEDRPOU) && data.codeEDRPOU,
            grantSupport: (data && data.grantSupport) && data.grantSupport,
            companyName: (data && data.companyName) && data.companyName,
        }
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    function sendForm() {

        button.current.disabled = true;
        const configAll = { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('user')}` } }

        // додав до обєкту roles i active
        const article = {
            ...sendData,
            roles: [
                "ROLE_USER"
            ],
            username: sendData.email,
            active: "on",
            userType: userType
        }
        // прибрав confirmPassword з обєкуту для сервера 
        const { confirmPassword, ...changedArticle } = article;

        // console.log('article', changedArticle);

        AxiosInstance.put(`/users/${data.id}`, changedArticle, configAll).then((response) => {
            // console.log('post /users response', response)

            if (response.status == 200) {
                window.location.replace("/profile");
            }

        }).catch((error) => {
            console.log(error);
        })

    };

    const handleOnSubmit = (data) => {
        sendData = data;
        sendForm();
        // console.log(JSON.stringify(data, null, 2))
    }

    const password = watch('plainPassword');
    return (
        <div className='my-5'>
            <h2>Редагувати профіль</h2>
            <Form className='profile-registration_buisness'>
                {/* <Row>
                    <Col className='d-flex align-items-center'>
                        <span style={{ fontWeight: '400', fontSize: '25px', margin: '0 auto' }}>Логін: <span style={{ fontWeight: '600' }}> {data.username}</span></span>
                    </Col>
                </Row> */}
                <Row id='feedback_row'>

                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Електронна пошта</Form.Label>
                        <Form.Control
                            defaultValue={data.email}
                            onChange={handleInputChange}
                            {...register('email', { required: 'is reqired!', pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                            className={formState.errors.email !== undefined && 'forms-invalid'}

                        >
                        </Form.Control>
                        {formState.errors.email && formState.errors.email.type === "required" && <span>Це обов'язкове поле</span>}
                        {formState.errors.email && formState.errors.email.type === "pattern" && <span>Перевірте емейл</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Номер телефону</Form.Label>
                        <Form.Control

                            onChange={handleInputChange}
                            className={formState.errors.phone !== undefined && 'forms-invalid'}
                            {...register('phone', { minLength: 10, maxLength: 12 })}
                        >
                        </Form.Control>
                        {formState.errors.phone && formState.errors.phone.type === "minLength" && <span>Перевірте номер!</span>}
                    </Col>
                </Row>
                {/* <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control
                            type='password'
                            onChange={handleInputChange}
                            {...register('plainPassword', { required: 'is reqired!', minLength: 5 })}
                            className={formState.errors.plainPassword !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.plainPassword && formState.errors.plainPassword.type === "required" && <span>Це обов'язкове поле!</span>}
                        {formState.errors.plainPassword && formState.errors.plainPassword.type === "minLength" && <span>Мінімальна довжина 5 символи!</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Повторіть пароль</Form.Label>
                        <Form.Control
                            type='password'
                            onChange={handleInputChange}
                            {...register('confirmPassword', {
                                required: 'is reqired!', minLength: 5,
                                validate: (value) => value == password || 'паролі не збігаються'
                            })}
                            className={formState.errors.confirmPassword !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.confirmPassword && formState.errors.confirmPassword.type === "required" && <span>Це обов'язкове поле</span>}
                        {formState.errors.confirmPassword && formState.errors.confirmPassword.type === "validate" && <span>паролі не збігаються</span>}
                    </Col>
                </Row> */}
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label>Ім'я контактної особи</Form.Label>
                        <Form.Control

                            defaultValue={data.firstName}
                            onChange={handleInputChange}

                            {...register('firstName', { required: 'is required' })}
                            className={formState.errors.firstName !== undefined && 'forms-invalid'}
                        >

                        </Form.Control>
                        {formState.errors.firstName && formState.errors.firstName.type === "required" && <span>Це обов'язкове поле</span>}

                    </Col>

                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Область з якої переїхали</Form.Label>
                        <Form.Select
                            onChange={handleInputChange}

                            {...register('regionFrom', { required: 'is reqired!' })}
                            className={formState.errors.regionFrom !== undefined && 'forms-invalid'}
                        >
                            <option disabled>Область з якої переїхали</option>
                            {regions.map(e => {
                                return <option key={e} value={e}>{e}</option>
                            })}
                        </Form.Select>
                        {formState.errors.regionFrom && formState.errors.regionFrom.type === "required" && <span>Це обов'язкове поле</span>}
                    </Col>

                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label style={{ textAlign: 'left' }}>  Податковий номер платника податків (ФОПа)</Form.Label>
                        <Form.Control

                            onChange={handleInputChange}
                            {...register('numberFOP', { required: 'is reqired!', minLength: 10, maxLength: 10 })}
                            className={formState.errors.numberFOP !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.numberFOP && formState.errors.numberFOP.type === "required" && <span>Це обов'язкове поле!</span>}
                        {formState.errors.numberFOP && formState.errors.numberFOP.type === "minLength" && <span>Мінімальна довжина 10 символів!</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Код ЄДРПОУ</Form.Label>
                        <Form.Control

                            onChange={handleInputChange}
                            {...register('codeEDRPOU', { required: 'is reqired!', minLength: 8, maxLength: 8 })}
                            className={formState.errors.codeEDRPOU !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.codeEDRPOU && formState.errors.codeEDRPOU.type === "required" && <span>Це обов'язкове поле!</span>}
                        {formState.errors.codeEDRPOU && formState.errors.codeEDRPOU.type === "minLength" && <span>Мінімальна довжина 8 символів!</span>}
                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col' style={{ textAlign: 'left' }}>
                        <Form.Label> Чи користувались грантовою підтримкою бізнесу?</Form.Label>
                        <Form.Select
                            onChange={handleInputChange}

                            {...register('grantSupport', { required: 'is reqired!' })}
                            className={formState.errors.grantSupport !== undefined && 'forms-invalid'}
                        >

                            <option disabled>Обрати Так-Ні</option>
                            <option>Так</option>
                            <option>Ні</option>
                        </Form.Select>
                        {formState.errors.grantSupport && formState.errors.grantSupport.type === "required" && <span>Це обов'язкове поле!</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Назва компанії</Form.Label>
                        <Form.Control

                            onChange={handleInputChange}
                            {...register('companyName', { required: 'is reqired!' })}
                            className={formState.errors.companyName !== undefined && 'forms-invalid'}
                        ></Form.Control>
                        {formState.errors.companyName && formState.errors.companyName.type === "required" && <span>Це обов'язкове поле!</span>}
                    </Col>

                </Row>

                <Button className='btn-rout registration_btn' type='submit' onClick={handleSubmit(handleOnSubmit)} ref={button}>Зберегти</Button>
            </Form>
        </div>
    )
}

export default UpdateProfileBusiness;