import React, { useEffect, useState } from 'react'
import Main from '../../components/Main/Main'
import PromoImg from '../../components/Main/PromoImg'
import SearchField from '../../components/Main/SearchField'
import { Col, Row } from 'react-bootstrap';
import AxiosInstance from '../../components/Axios';

function MainPage() {
    const urlApi = '/pages';
    const synonym = "mainPage";
    const page = 1;
    const [data, setData] = useState([]);

    useEffect(() => {
        AxiosInstance.get(`${urlApi}?page=${page}&synonym=${synonym}`).then((response) => {
            // console.log("response", response);
            setData(response.data['hydra:member']);
        });
    }, []);

    return (
        <div className='main-inner'>
            <Row className="business-promo__container">
                <Col className="business-promo__col-container">
                    <div className="business-promo__text-container">
                        <div className="business-promo__text-2 ">
                            {
                                (data.length > 0) &&
                                <div dangerouslySetInnerHTML={{ __html: data[0].title }}></div>
                            }
                        </div>
                    </div>
                </Col>
                <Col className="p-0">
                    {/* <PromoImg imgSrc="/images/Slide_1.png" alt="Бізнес - перелік програми підтримки" /> */}
                    {/* <PromoImg imgSrc="/images/Main/business-people-shaking-hands-greeting.jpg" alt="Бізнес - перелік програми підтримки" /> */}
                    <PromoImg imgSrc="/images/images_16_9/Головна.jpg" alt="Бізнес - перелік програми підтримки" />
                </Col>
            </Row>

            {/* <SearchField /> */}

            <Main data={data} />
        </div>
    )
}

export default MainPage
