import React, { useEffect, useRef, useState } from 'react'
import AxiosInstance from '../Axios';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import * as dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ApiForm = ({id, setIsSubmit}) => {

const [loading, SetLoading] = useState(true);
const [formData, SetFormData] = useState({});
const [file, setFile] = useState(false);
const [values, setValues] = useState({});
const button = useRef(null);

useEffect(() => {
    let active = false;
    let res = AxiosInstance.get(`/forms?page=1&itemsPerPage=30&id=${id}`, { headers: { 'accept': 'application/json' } }).then((response) => {
        SetFormData(response.data[0]);
        
    }).then(() => {
        // console.log(formData)
    })

    return () => {
        active = true;
    }; 
},[id])

const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
    
};

// надсилання
function sendForm (event) {
    event.preventDefault();
    button.current.disabled = true;

    const formFiltred = formData.formFields.filter(field => {       
        return  field.type !== 'file';
    })

    const initalNames = formFiltred.map(field => {
        return field.name;
    }).reduce((a, v) => ({ ...a, [v]: ''}), {}); 

    const formAnswerFields = formFiltred.map(field => {
        return {name: field.name, formField: `/api/form_fields/${field.id}`, value: values[field.name], label: field.label, type: field.type};
    })
    
    const configFile = { headers: { 'content-type': 'multipart/form-data',} }

    const configAll = { headers: { 'content-type': 'application/json', } }
    
    const article = {
        // name: formData.name,
        // date: dayjs().format(),
        form: `/api/forms/${formData.id}`,
        media : [],
        dateEntered: dayjs().format(),
        "view": false,
        formAnswerFields: formAnswerFields,
    }


    if(file) {
        const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects';
        const formFile = new FormData();
        formFile.append('file', file);
        formFile.append('fileName', file.name);

        axios.post(url, formFile, configFile).then((response) => {

            const articleFile = {...article, media: [`${response.data['@id']}`]}

            axios.post(process.env.REACT_APP_SERVER_URL  + '/api/form_answers', articleFile, configAll)
                .then(() => {
                    setValues(initalNames);
                    setFile(false);
                    button.current.disabled = false;
                    // .success("Дані відправлено в обробку!", {
                    //     position: toast.POSITION.BOTTOM_LEFT
                    // });
                })
                .catch(() => {
                    setValues(initalNames);
                    setFile(false);
                    // toast.error("Помилка при відправці даних", {
                    //     position: toast.POSITION.BOTTOM_LEFT
                    // });
            });
        }); 
    }
    else {        
        AxiosInstance.post( '/form_answers', article, configAll).then(() => {
            setValues(initalNames);
            setFile(false);
            button.current.disabled = false;
            toast.success('Успішно надіслано !', {
                position: toast.POSITION.TOP_RIGHT
            });
            setIsSubmit(true)
        }).catch(() => {
            toast.error('Виникла помилка !', {
                position: toast.POSITION.TOP_RIGHT
            });
        })
    }
};


return (
    
    <section className="green application-form main-content">
        <div>
            <div className="application-form__inner">
                <Form onSubmit={sendForm} type='submit'>
                {formData.formFields && formData.formFields.map((field, index) => {
                    return <div className="green-inner " key={field.id} >
                                {
                                    field.type === 'select' &&
                                    <>
                                        <label htmlFor="" className='mt-3'> {field.label}</label> 
                                        <Form.Select onChange={handleInputChange} value={values[field.name]} name={field.name} required >
                                            <option value="">{field.label}</option>
                                            {
                                                field.defaultValue.split(", ").map((el, index) => {
                                                    return <option key={index} value={el}>{el}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </>
                                        
                                }
                                {
                                    field.type === 'textarea' &&
                                    <>
                                        <label htmlFor="" className='mt-3'> {field.label}</label> 
                                        <textarea 
                                            onChange={handleInputChange} 
                                            value={values[field.name]} 
                                            name={field.name} 
                                            className="form-control" 
                                            placeholder={field.defaultValue} 
                                            aria-label="default textarea" 
                                            style={{minHeight:"144px"}} 
                                            required
                                        />
                                    </>    
                                }
                                {
                                    field.type === 'text' && 
                                        <>
                                            <label htmlFor="" className='mt-3'> {field.label}</label>
                                            <input
                                                onChange={handleInputChange} 
                                                value={values[field.name]} 
                                                name={field.name} 
                                                className="form-control" 
                                                placeholder={field.defaultValue} 
                                                aria-label="default textarea">
                                            
                                            </input>
                                        </>
                                }
                                <ToastContainer />
                            </div>
                          
                })}
            
             <div className= "header-green">  
                <Button type="submit" className="btn-submit mt-3 mx-auto" ref={button}>Надіслати</Button>                  
             </div>
            
            </Form>
          </div>
        </div>
         
    </section>
  )
}

export default ApiForm