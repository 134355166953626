import React from 'react';
import SearchField from '../../../../components/Main/SearchField';
import { Col, Row } from 'react-bootstrap';
import PromoImg from '../../../../components/Main/PromoImg';
import CurrentProgram_1 from '../../../../components/Main/VPO/ListOfProgramsVPO/CurrentProgram_1';
import NeedToRegister from '../../../../components/Main/NeedToRegister';
import isLoggedIn from '../../../../myFunction/isLoggedIn';

// Сторінка /vpo/list-of-programs/id
const CurrentProgramsVPOPage = () => {

    // Виклик функції чи залогінений
    const isLogIn = isLoggedIn();

    return (
        <>
            <Row className="business-promo__container">
                <Col className="business-promo__text-container">
                    <div className="business-promo__text">
                        <span>ДІЮЧІ ПРОГРАМИ</span><br />
                        <span>ДЛЯ ПІДТРИМКИ</span><br />
                        <span>ВПО</span>
                    </div>
                </Col>
                <Col className="p-0">
                    <PromoImg imgSrc="/images/Business/BusinessCard_1.png" alt="ВПО - перелік програм підтримки" />
                </Col>
            </Row>
            {/* <SearchField /> */}

            {/* Перевірка чи залогінений */}
            {
                isLogIn.isLogIn ?
                    <CurrentProgram_1 />
                    :
                    <NeedToRegister />

            }

        </>
    );
};

export default CurrentProgramsVPOPage;