import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
// import isLoggedIn from '../../../myFunction/isLoggedIn';
import AxiosInstance from '../../../components/Axios';
import PromoImg from '../../../components/Main/PromoImg';
import ListOfPrograms from '../../../components/Main/ListOfPrograms/ListOfPrograms';
// import NeedToRegister from '../../../components/Main/NeedToRegister';
// import SearchField from '../../../components/Main/SearchField';

// Сторінка /vpo/list-of-programs
const ListOfProgramsVPOPage = () => {

    const urlApi_1 = '/categories';
    const urlApi_2 = '/news';
    const urlApi_3 = '/pages';
    const synonym = "vpo/list-of-programs";
    const page = 1;
    const [data, setData] = useState([]);
    const [promo, setPromo] = useState([]);
    // //  title для Промо-заголовку
    // const [modifiedTitle, setModifiedTitle] = useState("");
    // Всі категорії
    const [categories, setCategories] = useState(false);
    // Категорія сторінки
    const сategoryName = "ВПО";
    const [pageCategory, setPageCategory] = useState(false);

    // Виклик функції чи залогінений
    // const isLogIn = isLoggedIn();
    // console.log('isLogIn', isLogIn);

    // При завантаженні сторінки взяти всі категорії
    useEffect(() => {
        // Запит за категоріями
        AxiosInstance.get(`${urlApi_1}?page=${page}`).then((response) => {
            // console.log("response categories", response);
            setCategories(response.data['hydra:member']);
        });

        // Запит за promo
        AxiosInstance.get(`${urlApi_3}?page=${page}&synonym=${synonym}`).then((response) => {
            // console.log("response", response);
            setPromo(response.data['hydra:member']);
        });
    }, []);

    // Визначити категорію сторінки
    useEffect(() => {
        // console.log('categories', categories);
        if (categories != false) {
            // Визначити категорію сторінки
            setPageCategory(categories.find(category => category.name === сategoryName));
        }
    }, [categories]);

    // // Для Промо-заголовку, щоб кожне слово було з нового ряка
    // useEffect(() => {
    //     if (promo.length > 0) {
    //         setModifiedTitle(promo[0].title.replace(/ /g, "<br />"));
    //     }
    // }, [promo]);

    // Запит на сервер за текстовим вмістом сторінки
    useEffect(() => {
        // console.log('pageCategory', pageCategory);
        if (pageCategory != false) {
            AxiosInstance.get(`${urlApi_2}?page=${page}&categories.id=${pageCategory.id}&pagination=false`).then((response) => {
                // console.log("response", response);
                setData(response.data['hydra:member']);
            });
        }
    }, [pageCategory]);

    return (
        <>
            <Row className="business-promo__container">
                <Col className="business-promo__col-container">
                    <div className="business-promo__text-container">
                        <div className="business-promo__text">
                            {/* {
                                (data.length > 0) &&
                                <div className="pages-promo__title">
                                    <div className="business-promo__text" dangerouslySetInnerHTML={{ __html: modifiedTitle }}></div>
                                </div>
                            } */}

                            {
                                (data.length > 0) &&
                                <div className="" dangerouslySetInnerHTML={{ __html: promo[0].title }}></div>
                            }
                        </div>
                    </div>
                </Col>
                <Col className="p-0">
                    {/* <PromoImg imgSrc="/images/Business/BusinessCard_1.png" alt="ВПО - перелік програм підтримки" /> */}
                    <PromoImg imgSrc="/images/images_16_9/Програми підтримки ВПО.jpg" alt="ВПО - перелік програм підтримки" />
                </Col>
            </Row>

            {/* <SearchField /> */}

            {/* Перевірка чи залогінений */}
            {/* {
                isLogIn.isLogIn ?
                    <ListOfPrograms data={data} title="Перелік програм підтримки ВПО" />
                    :
                    <NeedToRegister />
                } */}
            <ListOfPrograms data={data} title="Перелік програм підтримки ВПО" />
        </>
    );
};

export default ListOfProgramsVPOPage;