import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
// import isLoggedIn from '../../../myFunction/isLoggedIn';
import PromoImg from '../../../components/Main/PromoImg';
import ListOfPrograms from '../../../components/Main/ListOfPrograms/ListOfPrograms';
import AxiosInstance from '../../../components/Axios';
// import NeedToRegister from '../../../components/Main/NeedToRegister';

// Сторінка /business/list-of-programs
const ListOfProgramsVeteransPage = () => {

    const urlApi_1 = '/categories';
    const urlApi_2 = '/news';
    const urlApi_3 = '/pages';
    const synonym = "veterans/list-of-programs";
    const page = 1;
    const [data, setData] = useState([]);
    const [promo, setPromo] = useState([]);
    // Всі категорії
    const [categories, setCategories] = useState(false);
    // Категорія сторінки
    const categoryName = "Ветерани";
    const [pageCategory, setPageCategory] = useState(false);

    // При завантаженні сторінки взяти всі категорії + заголовок сторінки (promo)
    useEffect(() => {
        // Запит за категоріями
        AxiosInstance.get(`${urlApi_1}?page=${page}`).then((response) => {
            // console.log("response categories", response);
            setCategories(response.data['hydra:member']);
        });

        // Запит за promo
        AxiosInstance.get(`${urlApi_3}?page=${page}&synonym=${synonym}`).then((response) => {
            // console.log("response", response);
            setPromo(response.data['hydra:member']);
        });
    }, []);

    // Визначити категорію сторінки
    useEffect(() => {
        // console.log('categories', categories);
        if (categories != false) {
            // Визначити категорію сторінки
            setPageCategory(categories.find(category => category.name === categoryName));
        }
    }, [categories]);

    // Запит на сервер за текстовим вмістом сторінки
    useEffect(() => {
        // if (pageCategory != false && isLogIn.isLogIn) {
        if (pageCategory != false) {
            AxiosInstance.get(`${urlApi_2}?page=${page}&categories.id=${pageCategory.id}&pagination=false`).then((response) => {
                // console.log("response", response);
                setData(response.data['hydra:member']);
            });
        }
    }, [pageCategory]);

    return (
        <>
            <Row className="business-promo__container">
                <Col className="business-promo__col-container">
                    <div className="business-promo__text-container">
                        <div className="business-promo__text">
                            {
                                (data.length > 0) &&
                                <div dangerouslySetInnerHTML={{ __html: promo[0].title }}></div>
                            }
                        </div>
                    </div>
                </Col>
                <Col className="p-0">
                    <PromoImg imgSrc="/images/Veterans/16x9/1-Програми підтримки.jpg" alt="Програми підтримки бізнесу Захисників" />
                </Col>
            </Row>

            <ListOfPrograms data={data} title="Програми підтримки бізнесу Захисників" />
        </>
    );
};

export default ListOfProgramsVeteransPage;