import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const HousingSearchVPO = (props) => {
    return (
        <Container className="my-sm-5 business-mentoringProgram__container wrapper">
            <Row className='vpo-search'>
                <Col className="col-6">
                    <Image className="" src="/images/VPO/vpo-map.png" alt="ВПО - карта"  width={400} />
                </Col>
                <Col className="col-6 d-flex direction-column" style={{ flexDirection: "column", justifyContent: "center", fontSize: "20px" }}>
                    {
                        (props.data.length > 0) &&
                        <div className='vpo-search__content' dangerouslySetInnerHTML={{ __html: props.data[0].content }}></div>
                    }
                    {/* <div>
                        За цим посиланням є карта, там потрібно <br />
                        поставити галочку навпроти - Прихистки <br />
                        для ВПО. На карті є 100 прихистків з <br />
                        контактними номерами телефонів
                    </div> */}
                    <Link className="mt-4" target="_blank"
                        to="https://www.google.com/maps/d/u/0/viewer?mid=1UEQ2084QZLJHGPBrsJDA9_7IzpNjhGc&ll=49.6513552%2C24.14488060000002&z=8"
                    >
                        <Button className="btn-rout">
                            Ознайомитись
                        </Button>
                    </Link>
                </Col>
            </Row>
        </Container >
    );
};

export default HousingSearchVPO;