import React from 'react';
import PromoImg from '../../components/Main/PromoImg';
// import SearchField from '../../components/Main/SearchField';
import Business from '../../components/Main/Business/Business';

// Сторінка /business
const BusinessPage = () => {
    return (
        <>
            {/* <PromoImg imgSrc="/images/Business/Business_main.png" alt="Бізнес - рукостискання" /> */}
            {/* <PromoImg imgSrc="/images/Business/handshake-businessman-businesswoman.jpg" alt="Бізнес - рукостискання" /> */}
            <PromoImg imgSrc="/images/images_16_9/Бізнес.jpg" alt="Бізнес - рукостискання" />
            {/* <SearchField /> */}
            <Business />
        </>
    );
};

export default BusinessPage;