import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './components/Error/ErrorPage';
import MentoringProgramPage from './pages/business/mentoring-program/MentoringProgramPage';
import RelocationRequestPage from './pages/business/relocation-request/RelocationRequestPage';
import Registration from './components/Profile/Registration';
import Cabinet from './components/Profile/Cabinet';
import CurrentProgramsVPOPage from './pages/vpo/list-of-proframs/id/CurrentProgramsVPOPage';
import MainPage from './pages/home/MainPage';
import LoginPage from './pages/login/LoginPage';
import ProfilePage, { LoaderProfilePage } from './pages/profile/ProfilePage';
import BusinessPage from './pages/business/BusinessPage';
import ListOfProgramsBusinessPage from './pages/business/list-of-programs/ListOfProgramsBusinessPage';
import BusinessProgramPage, { LoaderBusinessProgramPage } from './pages/business/list-of-programs/id/BusinessProgramPage';
import VpoPage from './pages/vpo/VpoPage';
import ListOfProgramsVPOPage from './pages/vpo/list-of-proframs/ListOfProgramsVPOPage';
import JobSearchVPOPage from './pages/vpo/job-search/JobSearchVPOPage';
import HousingSearchVPOPage from './pages/vpo/housing-search/HousingSearchVPOPage';
import AccomodationOffersBusinessPage from './pages/business/accommodation-offers/AccomodationOffersBusinessPage';
import SupportPage from './pages/support/SupportPage';
import RegistrationPage from './pages/registration/RegistrationPage';
import VPOProgramPage, { LoaderVPOProgramPage } from './pages/vpo/list-of-proframs/id/VPOProgramPage';
import UpdateProfileBusiness, { LoaderUpdateProfileBusiness } from './components/Profile/UpdateProfileBusiness';
import UpdateProfileLviv, { LoaderUpdateProfileLviv } from './components/Profile/UpdateProfileLviv';
import UpdatePage, { LoaderUpdateProfile } from './components/Profile/UpdatePage';
import Restore from './pages/login/Restore';
import ResetPassword, { LoaderResetPassword } from './pages/rest-password/ResetPassword';
import VeteransPage from './pages/veterans/VeteransPage';
import ListOfProgramsVeteransPage from './pages/veterans/list-of-programs/ListOfProgramsVeteransPage';
import VetearnsProgramPage, { LoaderVetearnsProgramPage } from './pages/veterans/list-of-programs/id/VetearnsProgramPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <MainPage />
      },
      {
        path: "/business",
        element: <BusinessPage />
      },
      {
        path: "/business/list-of-programs",
        element: <ListOfProgramsBusinessPage />
      },
      {
        path: "/business/list-of-programs/:id",
        element: <BusinessProgramPage />,
        loader: LoaderBusinessProgramPage
      },
      {
        path: "/business/mentoring-program",
        element: <MentoringProgramPage />
      },
      {
        path: "/business/relocation-request",
        element: <RelocationRequestPage />
      },
      {
        path: "/business/accomodation-offers",
        element: <AccomodationOffersBusinessPage />
      },
      {
        path: "/vpo",
        element: <VpoPage />
      },
      {
        path: "/vpo/list-of-programs",
        element: <ListOfProgramsVPOPage />
      },
      {
        path: "/vpo/list-of-programs/:id",
        element: <VPOProgramPage />,
        loader: LoaderVPOProgramPage
      },
      {
        path: "/vpo/job-search",
        element: <JobSearchVPOPage />
      },
      {
        path: "/vpo/housing-search",
        element: <HousingSearchVPOPage />
      },
      {
        path: "/veterans",
        element: <VeteransPage />
      },
      {
        path: "/veterans/list-of-programs",
        element: <ListOfProgramsVeteransPage />
      },
      {
        path: "/veterans/list-of-programs/:id",
        element: <VetearnsProgramPage />,
        loader: LoaderVetearnsProgramPage
      },
      {
        path: "/support",
        element: <SupportPage />
      },
      {
        path: "/profile",
        element: <ProfilePage />,
      },
      {
        path: '/profile/update/:id',
        element: <UpdatePage />,
        loader: LoaderUpdateProfile
      },

      {
        path: "/registration",
        element: <RegistrationPage />
      },
      {
        path: "/login",
        element: <LoginPage />
      },
      {
        path: "/login/restore",
        element: <Restore />
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
        children: [
          {
            path: ":id",
            element: <ResetPassword />
          }
        ]
      },
      // {
      //   path: '/reset-password/:id',
      //   element: <ResetPassword />,
      //   loader: LoaderResetPassword
      // },


    ]
  },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
