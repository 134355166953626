import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MainCard from "../MainCard";
import isLoggedIn from "../../../myFunction/isLoggedIn";

const Vpo = () => {

    const data = [
        {
            id: 1,
            title: "Програми підтримки",
            text: "В даному розділі Ви маєте змогу ознайомитись з усіма наявними програмами підтримки та грантами для ВПО.",
            // imgSrc: "/images/VPO/VPO_1.png",
            imgSrc: "/images/images_16_9/Програми підтримки ВПО.jpg",
            link: "/vpo/list-of-programs"
        },
        {
            id: 2,
            title: "Пошук роботи",
            text: "Розділ допоможе ознайомитись з інформацією щодо можливостей працевлаштування ВПО в регіоні.",
            // imgSrc: "/images/VPO/VPO_2.png",
            imgSrc: "/images/images_16_9/Перелік Вакансій ВПО.jpg",
            link: "/vpo/job-search"
        },
        {
            id: 3,
            title: "Пошук житла",
            text: "Тут ви можете ознайомитись з інформацією щодо локацій та наявного житла для ВПО в регіоні.",
            // imgSrc: "/images/VPO/VPO_3.png",
            imgSrc: "/images/images_16_9/Пошук житлаВПО.jpg",
            link: "/vpo/housing-search"
        },
    ];

    // Виклик функції чи залогінений
    const isLogIn = isLoggedIn();
    // console.log('isLogIn', isLogIn);

    return (
        <Container className="mt-5">
            <Row className='wrapper mainCard-container'>
                {
                    data.map((card) => {
                        return (
                            <Col className="col-md-6 col-12 mb-5 mainCard-card" key={card.id}>
                                <MainCard title={card.title} text={card.text} imgSrc={card.imgSrc} link={card.link} isLogIn={isLogIn.isLogIn} />
                            </Col>
                        )
                    })
                }
                <Col className="col-6 mb-5">
                </Col>
            </Row>
        </Container>
    );
};

export default Vpo;