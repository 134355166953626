import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const MainCard = (props) => {
    // console.log('props', props);
    const location = useLocation();
    // console.log('location NeedToRegistaer', location);

    // Для показу повідомлення "Для ознайомлення розділом необхідно Зареєструватись"
    const [displayMessage, setDisplayMessage] = useState(false);
    // const isLogIn = false;
    const handleClick = () => {
        if (!props.isLogIn && (props.link !== "/business/list-of-programs") && (props.link !== "/vpo/list-of-programs")) {
            // if (!isLogIn) {
            // alert("Не залогінений");
            setDisplayMessage(true);
        }
    };

    return (
        <div>
            <h3 className={`mb-4 ${displayMessage ? "mainCard-card__opacity" : ""}`}>{props.title}</h3>
            <Card className="mainCard-card__container" style={{}}>
                <Card.Img className={`mb-3 mainCard-card__img ${displayMessage ? "mainCard-card__opacity" : ""}`} variant="top" src={props.imgSrc} />
                {displayMessage && (
                    <div className="mainCard-card__messege-container">
                        <p>Для ознайомлення з розділом необхідно</p>
                        <Link className="mainCard-card__link" to={`/login`} state={{ redirectTo: location }}>
                            Зареєструватись
                        </Link>
                    </div>
                )}

                <Card.Text className={`mb-3 mainCard-card__img ${displayMessage ? "mainCard-card__opacity" : ""}`}>
                    {props.text}
                </Card.Text>

                {/* Перевірка чи залогінений + на сторінки "/business/list-of-programs", "/vpo/list-of-programs" */}
                {
                    (props.isLogIn || (!props.isLogIn && (props.link === "/business/list-of-programs")) || (!props.isLogIn && (props.link === "/vpo/list-of-programs"))) &&
                    <Link className="mainCard-card__link" to={props.link} target={props?.target ? props.target : "_self"}>
                        <Button className={`btn-rout ${(props.link === "#") ? "btn-disabled" : ""}`}>
                            Ознайомитись
                        </Button>
                    </Link>
                }
                {
                    (!props.isLogIn && (props.link !== "/business/list-of-programs") && (props.link !== "/vpo/list-of-programs")) &&
                    <div className="mainCard-card__link" onClick={handleClick}>
                        <Button className={`btn-rout ${displayMessage ? "btn-disabled" : ""} ${(props.link === "#") ? "btn-disabled" : ""}`}>
                            Ознайомитись
                        </Button>
                    </div>
                }
            </Card>
        </div >
    );
};

export default MainCard;