import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Компонент для проскроювання на верх сторінки при зміні url
const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        const scrollUp = () => {
            // console.log("URL змінився. Проскролити на верх");
            // При зміні URL проскролити на верх сторінки
            window.scrollTo(0, 0);
        };

        return scrollUp;
    }, [location]);

    return null;
};

export default ScrollToTop;