import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import SendRequestToEmail from '../../components/Main/Login/SendRequestToEmail';
import Reset from '../../components/Main/Login/Reset';

// Відновити пароль
const ResetPassword = () => {

    let location = useLocation();
    const [resetCode, setResetCode] = useState("");

    // Взяти хеш з url
    useEffect(() => {
        // console.log('location reset', location);
        if (location.pathname != "") {
            // console.log('location.pathname', location.pathname);
            const currentURL = location.pathname;
            // Розбиваємо URL за символом '/'
            const parts = currentURL.split('/');
            // Отримуємо останній шматок URL, який містить унікальний код для скидання пароля
            const resetCode = parts[parts.length - 1];
            setResetCode(resetCode);
        }
    }, [location]);

    return (
        <>
            {
                (resetCode.length == 36) ?
                    // "Перейшов з пошти - відновити пароль"
                    <Reset
                        hash={resetCode}
                    />
                    :
                    // Відправити лист на пошту
                    <SendRequestToEmail />
            }
        </>
    );
};

export default ResetPassword;