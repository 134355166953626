// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchField {
  height: 60px;
  width: 100%;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.searchField__form {
  max-width: 1200px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #fff;
  padding: 0 0 0 5px;
}

.searchField__formControl {
  height: 100%;
  width: 100%;
  background-color: #000;
  border-radius: 0px;
  color: #fff;
  padding: 0;
  border: none;
}
.searchField__formControl:focus{
  color: #fff;
  background-color: black;
  /* border-color: #86b7fe; */
  /* outline: 0; */
  /* box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25); */
  box-shadow: none;
}

.searchField__button {
    background-color: #000;
    border-color: #000;
    padding: 0 8px 4px 0;
    vertical-align: middle;
}

.searchField__button:hover {
    background-color: #000;
    border-color: #000;
}`, "",{"version":3,"sources":["webpack://./src/styles/searchField.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,YAAY;AACd;AACA;EACE,WAAW;EACX,uBAAuB;EACvB,2BAA2B;EAC3B,gBAAgB;EAChB,oDAAoD;EACpD,gBAAgB;AAClB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,oBAAoB;IACpB,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;AACtB","sourcesContent":[".searchField {\n  height: 60px;\n  width: 100%;\n  background-color: #000;\n  color: #fff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0 20px;\n}\n\n.searchField__form {\n  max-width: 1200px;\n  height: 35px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  border: 1px solid #fff;\n  padding: 0 0 0 5px;\n}\n\n.searchField__formControl {\n  height: 100%;\n  width: 100%;\n  background-color: #000;\n  border-radius: 0px;\n  color: #fff;\n  padding: 0;\n  border: none;\n}\n.searchField__formControl:focus{\n  color: #fff;\n  background-color: black;\n  /* border-color: #86b7fe; */\n  /* outline: 0; */\n  /* box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25); */\n  box-shadow: none;\n}\n\n.searchField__button {\n    background-color: #000;\n    border-color: #000;\n    padding: 0 8px 4px 0;\n    vertical-align: middle;\n}\n\n.searchField__button:hover {\n    background-color: #000;\n    border-color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
