import React from 'react'
import '../../styles/forms.css'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'

function Registration() {
  return (
    <Container className='my-5 px-5'>
        <h3 className="mb-3">
            Реєстрація користувача
        </h3>
        <Form>
            <div className="radio_buttons">
                <Form.Check 
                label={'ВПО'}
                />
                <Form.Check 
                label={'Бізнес'}
                />
            </div>
            <div className="inputs">
                <Row>
                    <Col>
                        <Form.Group className="mb-3 input_group">
                            <Form.Label htmlFor="inputName">Ім'я</Form.Label>
                            <Form.Control type="text"></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3 input_group">
                            <Form.Label htmlFor="inputPassword5">Прізвище</Form.Label>
                            <Form.Control type="text"></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3 input_group">
                            <Form.Label htmlFor="inputPassword5">Телефон</Form.Label>
                            <Form.Control type="text"></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3 input_group">
                            <Form.Label htmlFor="inputPassword5">Email</Form.Label>
                            <Form.Control></Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                
                <Form.Group className="mb-3 input_group">
                    <Form.Label htmlFor="inputPassword5">Адреса</Form.Label>
                    <Form.Control></Form.Control>
                </Form.Group>
            </div>
            <Button style={{backgroundColor: "#78BEB3", borderRadius: "0", borderColor: "#2bd191be", color: "#fff", marginTop: "2rem"}}>Зареєструватись</Button>
        </Form>
       
    </Container>
  )
}

export default Registration
