// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modern-react-captcha{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items:normal;
}

.capcha_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.modern-react-captcha__captchaText{
    text-align: center;
    width: 100%!important;
}

.modern-react-captcha__captchaText, .modern-react-captcha__inputField {
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Profile/capcha/capcha.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".modern-react-captcha{\n    margin-top: 1rem;\n    display: flex;\n    flex-direction: column;\n    align-items:normal;\n}\n\n.capcha_wrapper{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n.modern-react-captcha__captchaText{\n    text-align: center;\n    width: 100%!important;\n}\n\n.modern-react-captcha__captchaText, .modern-react-captcha__inputField {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
