import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import SupportContent from './SupportContent'
import NeedToRegister from '../NeedToRegister'
import isLoggedIn from '../../../myFunction/isLoggedIn'
import { Link } from 'react-router-dom'
import { FaFacebookF } from "react-icons/fa";

const SupportContainer = () => {

  // Виклик функції чи залогінений
  const isLogIn = isLoggedIn();

  return (
    // <Container className='px-5 my-5 wrapper'>
    <Container className='my-5 wrapper'>
      <div className="forms-support_header">
        <h3 > Психолого-юридична підтримка</h3>
        <p className='forms-support_header-green'>
          Координаційний центр підтримки цивільного населення
          при Львівській обласній військовій адміністрації
        </p>
        <p>medpsysocial.aid@gmail.com (координаторка Галина Бордун +38 (067) 460 7614).</p>
        <p><strong>Функція:</strong> здійснення координації зі створення та функціонування Офісів з допомоги ВПО при РВА.</p>

        <div style={{ textAlign: "left" }}>
          <Link to={`https://www.facebook.com/coordination.centers.lviv.region/`} target='_blank' >
            {/* <img src="/images/Support/facebook.jpg" alt="facebook" style={{ width: "40px" }} /> */}
            <FaFacebookF className="support_facebook-icon" />
          </Link>
        </div>

      </div>

      {/* Перевірка чи залогінений */}
      {
        isLogIn.isLogIn ?
          <SupportContent />
          :
          <NeedToRegister text="Для перегляду всієї доступної інформації необхідно" />
      }

    </Container>
  )
}

export default SupportContainer;