import React, { useState, useEffect } from 'react';
import AxiosInstance from '../../../components/Axios';
import SearchField from '../../../components/Main/SearchField';
import { Col, Row } from 'react-bootstrap';
import MentoringProgram from '../../../components/Main/Business/MentoringProgram/MentoringProgram';
import PromoImg from '../../../components/Main/PromoImg';
import isLoggedIn from '../../../myFunction/isLoggedIn';
import NeedToRegister from '../../../components/Main/NeedToRegister';

// Сторінка /business/mentoring-program
const MentoringProgramPage = () => {

    // const urlApi_2 = '/options';
    // const option_key = "mentoringProgram";
    const urlApi = '/pages';
    const synonym = "business/mentoring-program";

    const page = 1;
    // const [data_2, setData_2] = useState([]);
    const [data, setData] = useState([]);
    // Виклик функції чи залогінений
    const isLogIn = isLoggedIn();

    // // title для Промо-заголовку
    // const [modifiedTitle, setModifiedTitle] = useState("");
    // // Для Промо-заголовку, щоб кожне слово було з нового ряка
    // useEffect(() => {
    //     if (data.length > 0) {
    //         setModifiedTitle(data[0].title.replace(/ /g, "<br />"));
    //     }
    // }, [data]);

    // console.log('data', data);

    // Запит за даними сторінки
    useEffect(() => {
        // AxiosInstance.get(`${urlApi_2}?page=${page}&option_key=${option_key}`).then((response) => {
        //     setData_2(response.data['hydra:member']);
        // });
        AxiosInstance.get(`${urlApi}?page=${page}&synonym=${synonym}`).then((response) => {
            // console.log("response", response);
            setData(response.data['hydra:member']);
        });
    }, []);

    return (
        <>
            <Row className="business-promo__container">
                <Col className="business-promo__col-container">
                    <div className="business-promo__text-container">
                        <div className="business-promo__text">
                            {/* {
                                (data.length > 0) &&
                                // <div className="business-promo__text" dangerouslySetInnerHTML={{ __html: data[0].value }}>
                                // <div className="" dangerouslySetInnerHTML={{ __html: data[0].title }}>
                                <div className="" dangerouslySetInnerHTML={{ __html: modifiedTitle }}></div>
                            } */}

                            {
                                (data.length > 0) &&
                                <div dangerouslySetInnerHTML={{ __html: data[0].title }}></div>
                            }
                        </div>
                    </div>
                </Col>
                <Col className="p-0">
                    {/* <PromoImg imgSrc="/images/Business/Business_mentoring-program.png" alt="Бізнес - перелік програми підтримки" /> */}
                    {/* <PromoImg imgSrc="/images/Business/two-businessmen-standing-near-table-looking-laptop-office.jpg" alt="Бізнес - перелік програми підтримки" /> */}
                    <PromoImg imgSrc="/images/images_16_9/Менторська Підтримка Бізнес.jpg" alt="Бізнес - перелік програми підтримки" />
                </Col>
            </Row>

            {/* <SearchField /> */}

            {/* Перевірка чи залогінений */}
            {
                isLogIn.isLogIn ?
                    // <MentoringProgram data={data_2} />
                    <MentoringProgram data={data} />
                    :
                    <NeedToRegister />
            }
        </>
    );
};

export default MentoringProgramPage;