import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Form, Row, Col, Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import AxiosInstance from '../Axios';
import dayjs from 'dayjs';

function MentoringForm({setIsSubmit, setNameSurname}) {

    const FORM_ID = 8;
    const button = useRef(null);
    const [formData, SetFormData] = useState(false);
    const [file, setFile] = useState(false);
    const [values, setValues] = useState({});
    const [selectedOption, setSelectedOption] = useState({
        micro: false
    });

    let sendData;
    const {register, handleSubmit, formState} = useForm({
        values:{
            firmName: '',
            adress: '',
            newAdress: '',
            IPN: '',
            contactName:'',
            description: '',
            branch: '',
            businessType: {},
            number: '',
            email: '',
            area: ''
        }
    })

    useEffect(() => {
        let active = false;
        let res = AxiosInstance.get(`/forms?page=1&itemsPerPage=30&id=${FORM_ID}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            SetFormData(response.data[0]);
        }).then(() => {
            // console.log(formData)
        })

        return () => {
            active = true;
        }; 

    }, [])

    // useEffect(() => {
    //     console.log('values', values)
    // }, [values])
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });

    };

    function sendForm () {
        button.current.disabled = true;

        const formFiltred = formData.formFields.filter(field => {
            return field.type !== 'file';
        })

        const initalNames = formFiltred.map(field => {
            return field.name;
        }).reduce((a, v) => ({ ...a, [v]: '' }), {});

        const formAnswerFields = formFiltred.map(field => {
            // console.log(field.name)
            return { 
                name: field.name, 
                formField: `/api/form_fields/${field.id}`, 
                value:  typeof(sendData[field.name]) == 'object' ? JSON.stringify(sendData[field.name]) : sendData[field.name], 
                label: field.label, 
                type: field.type };
        })

        const configFile = { headers: { 'content-type': 'multipart/form-data', } }

        const configAll = { headers: { 'content-type': 'application/json', } }

        const article = {
            form: `/api/forms/${formData.id}`,
            media: [],
            dateEntered: dayjs().format(),
            "view": false,
            formAnswerFields: formAnswerFields,
        }

        if (file) {
            const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects';
            const formFile = new FormData();
            formFile.append('file', file);
            formFile.append('fileName', file.name);

            axios.post(url, formFile, configFile).then((response) => {

                const articleFile = { ...article, media: [`${response.data['@id']}`] }

                axios.post(process.env.REACT_APP_SERVER_URL + '/api/form_answers', articleFile, configAll)
                    .then(() => {
                        setValues(initalNames);
                        setFile(false);
                        button.current.disabled = false;
                    })
                    .catch(() => {
                        setValues(initalNames);
                        setFile(false);
                    });
            });
        }
        else {
            AxiosInstance.post('/form_answers', article, configAll).then(() => {
                setValues(initalNames);
                setFile(false);
                button.current.disabled = false;
                setIsSubmit(true)
            }).catch(() => {

            })
        }
    };

    const handleCheckboxClick = (value) => {
        if (selectedOption === value) {
          setSelectedOption(null); 
        } else {
          setSelectedOption(value); 
        }
    };

    const onSubmit = (data) => {
        setIsSubmit(true);
        sendData = data;
        setNameSurname(data.contactName);
        sendForm();
        // console.log(
        //     JSON.stringify(data, null, 2)
        // )
    }
   
    return (
    <Form noValidate autoComplete='off' className='px-5 forms-mentoring_form' onSubmit={handleSubmit(onSubmit)}>
        <Row id='feedback_row' >
            <Col className='forms-form-col' >
                <Form.Label> <span className='forms_red_star'>*</span> Назва підприємства:</Form.Label>
            </Col>
            <Col>
                
                <Form.Control
                    className={formState.errors.firmName !== undefined ? 'forms-invalid' : null} 
                    {...register('firmName', {required: true})}
                    onChange={handleInputChange}></Form.Control>
            </Col>
        </Row>
        <Row id='feedback_row' style={{marginTop: "1rem"}}>
            <Col className='forms-form-col' >
                <Form.Label> <span className='forms_red_star'>*</span> Галузь:</Form.Label>
            </Col>
            <Col>
                
                <Form.Select
                    aria-label="Default select example"
                    style={{borderRadius: '0', border: '1px', boxSizing: 'border-box'}}
                    className={formState.errors.area !== undefined ? 'forms-invalid' : null} 
                    {...register('area', {required: true})}
                    onChange={handleInputChange}>
                        <option >Сільське, лісове та рибне господарство</option>
                        <option >Будівництво</option>
                        <option >Оптова та роздрібна торгівля</option>
                        <option >Медицина</option>
                        <option >Транспорт, складське господарство,поштова...</option>
                        <option >Тимчасове розміщування й організація харчування</option>
                        <option >Інформація та телекомунікації та IT</option>
                        <option >Харчова промислоість</option>
                        <option >Машинобудівна та металообробна промислоість</option>
                        <option >Хімічна промисловість</option>
                        <option >Деревообробна та меблева промисловість</option>
                        <option >Легка промисловість</option>
                        <option >Добувна промисловість</option>
                        <option >Інше</option>
                    </Form.Select>
            </Col>
        </Row>
        <Row id='feedback_row' style={{marginTop: "1rem"}}>
            <Col className='forms-form-col'>
                <Form.Label> <span className='forms_red_star'>*</span> Адреса реєстрації:</Form.Label>
            </Col>
            <Col>
                <Form.Control
                    className={formState.errors.adress !== undefined ? 'forms-invalid' : null} 
                    {...register('adress', {required: true})}
                    onChange={handleInputChange}></Form.Control>
            </Col>
        </Row>
        <Row id='feedback_row' style={{marginTop: "1rem"}}>
            <Col className='forms-form-col'>
                <Form.Label> <span className='forms_red_star'>*</span> Фактичне місцезнаходження:</Form.Label>
            </Col>
            <Col>
                <Form.Control
                    className={formState.errors.newAdress !== undefined ? 'forms-invalid' : null} 
                    {...register('newAdress', {required: true})}
                    onChange={handleInputChange}></Form.Control>
            </Col>
        </Row>
        <Row id='feedback_row' style={{marginTop: "1rem"}}>
            <Col className='forms-form-col'>
                <Form.Label> <span className='forms_red_star'>*</span> ЄДРПОУ або ІПН:</Form.Label>
            </Col>
            <Col>
                <Form.Control
                    className={formState.errors.IPN !== undefined ? 'forms-invalid' : null} 
                    {...register("IPN", {required: true, minLength: 8, maxLength: 10, pattern: /^\d+$/})}
                    onChange={handleInputChange}></Form.Control>
                    {formState.errors.IPN && formState.errors.IPN.type === "minLength" && <span className='hint'>Мінімальна довжина 8 символів</span>}

            </Col>
        </Row>
        {/* <Row>
            <Col className='forms-form-col'>
                <Form.Label>Звідки і куди релоковане підприємство?</Form.Label>
            </Col>
            <Col className='forms-form-col'>
            
            </Col>
            <Col>
                <Form.Control
                    className={formState.errors.placeFrom !== undefined && 'forms-invalid'} 
                    placeholder="З НасП" {...register('placeFrom', {required: true})}></Form.Control>
            </Col>
            <Col>
                <Form.Control
                    className={formState.errors.PlaceTo !== undefined && 'forms-invalid'} 
                    placeholder="У НасП" {...register('PlaceTo', {required: true})}></Form.Control>
            </Col>
        </Row> */}
        <Row id='feedback_row' style={{marginTop: "1rem"}}>
            <Col className='forms-form-col'>
                <Form.Label> <span className='forms_red_star'>*</span> До якого сегменту бізнесу відноситься підприємство</Form.Label>
            </Col>
            <Col>
                <div className="check_boxes" id='feedback_row_check' style={{paddingTop: '0.2rem'}}>
                    <Form.Check 
                        id='castom_check_green0'
                        onChange={handleInputChange}
                        checked={selectedOption === 'мікро'}
                        onClick={() => handleCheckboxClick('мікро')}
                        label = 'мікро' 
                        value='мікро'  
                        className='check_boxes-checks' 
                        {...register('businessType', {required: true})}></Form.Check>
                    <Form.Check
                        id='castom_check_green1'
                        onChange={handleInputChange} 
                        checked={selectedOption === 'мале'}
                        onClick={() => handleCheckboxClick('мале')} 
                        label = 'мале' 
                        value='мале' 
                        className='check_boxes-checks' 
                        {...register('businessType', {required: true})}></Form.Check>
                    <Form.Check
                        id='castom_check_green2'
                        onChange={handleInputChange} 
                        checked={selectedOption === 'середнє'}
                        onClick={() => handleCheckboxClick('середнє')}  
                        label = 'середнє'  
                        value='середнє' 
                        className='check_boxes-checks' 
                        {...register('businessType', {required: true})}></Form.Check>
                    <Form.Check
                        id='castom_check_green3'
                        onChange={handleInputChange}
                        checked={selectedOption === 'велике'}
                        onClick={() => handleCheckboxClick('велике')}  
                        label = 'велике'  
                        value='велике' 
                        className='check_boxes-checks' 
                        {...register('businessType', {required: true})}></Form.Check>
                        {formState.errors.businessType !== undefined && <span className='hint'>Оберіть значення</span>}

                </div>
            </Col>
        </Row>
        <Row id='feedback_row' style={{marginTop: "1rem"}}>
            <Col className='forms-form-col'>
                <Form.Label > <span className='forms_red_star'>*</span> Короткий опис діяльності підприємства (до 500 слів):</Form.Label>
        
                <Form.Control
                    onChange={handleInputChange} 
                    className={formState.errors.description !== undefined && 'forms-invalid'}
                    style={{height:'7rem'}} 
                    {...register('description', {required: true})}></Form.Control>
            </Col>
        </Row>
        <Row id='feedback_row'>
            <Col className='forms-form-col'>
                <Form.Label > <span className='forms_red_star'>*</span> В яких напрямках діяльності необхідна менторська підтримка? (до 200 слів)</Form.Label>
        
                <Form.Control
                    onChange={handleInputChange}
                    className={formState.errors.branch !== undefined && 'forms-invalid'} 
                    style={{height:'5rem'}} 
                    {...register('branch', {required: true})}></Form.Control>
            </Col>
        </Row>
        <Row style={{marginTop: '1rem'}} id='feedback_row'>
            <Col className='forms-form-col'>
                <Form.Label> <span className='forms_red_star'>*</span> Контактний номер телефону:</Form.Label>
            </Col>
            <Col>
                <Form.Control
                    onChange={handleInputChange}
                    className={formState.errors.number !== undefined && 'forms-invalid'} 
                    {...register('number', {required: true, minLength: 10, maxLength: 12, pattern: /^\d+$/})}></Form.Control>
                    {formState.errors.number && formState.errors.number.type === "minLength" && <span className='hint'>Довжина 8 або 10 символів</span>}
    
            </Col>
        </Row>
        <Row id='feedback_row' style={{marginTop: "1rem"}}>
            <Col className='forms-form-col'>
                <Form.Label> <span className='forms_red_star'>*</span> Електронна пошта:</Form.Label>
            </Col>
            <Col>
                <Form.Control 
                    onChange={handleInputChange}
                    type='email'
                    className={formState.errors.email !== undefined && 'forms-invalid'} 
                    {...register('email', {required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/})}></Form.Control>
                    {formState.errors.email && formState.errors.email.type === "pattern" && <span className='hint'>У вигляді: example@gmail.com</span>}

            </Col>
        </Row>
        <Row id='feedback_row' style={{marginTop: "1rem"}}>
            <Col className='forms-form-col'>
                <Form.Label> <span className='forms_red_star'>*</span> Ім'я контактної особи:</Form.Label>
            </Col>
            <Col>
                <Form.Control
                    onChange={handleInputChange}
                    className={formState.errors.contactName !== undefined ? 'forms-invalid' : null} 
                    {...register("contactName", {required: true})}></Form.Control>
            </Col>
        </Row>
        <Button variant="light" type='submit' style={{fontWeight: "500", borderRadius: "0px", marginTop: "2rem"}} ref={button}> Надіслати</Button>
    </Form>
  )
}

export default MentoringForm;

