// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.needToRegister__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 300px;
    margin: auto;
}

.needToRegister__message-container {
    line-height: 32px;
    padding: 25px;
    background-color: #fff;
    border-radius: 10px;
    font-size: 24px;
    max-width: 500px;
    box-shadow:0px 0px 12px 2px rgba(43,209,145,0.35);
    -webkit-box-shadow:0px 0px 12px 2px rgba(43,209,145,0.35);
    -moz-box-shadow:0px 0px 12px 2px rgba(43,209,145,0.35);
}

.needToRegister__link {
    text-transform: upperCase;
    /* color: #2bd191be; */
    color: var(--main-green-color);;
    text-decoration: underline;
}

.needToRegister__link:hover, .needToRegister__link:active {
    cursor: pointer;
    color: #0a58ca;
}
`, "",{"version":3,"sources":["webpack://./src/styles/needToRegister.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iDAAiD;IACjD,yDAAyD;IACzD,sDAAsD;AAC1D;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;IACtB,8BAA8B;IAC9B,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,cAAc;AAClB","sourcesContent":[".needToRegister__container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    min-height: 300px;\n    margin: auto;\n}\n\n.needToRegister__message-container {\n    line-height: 32px;\n    padding: 25px;\n    background-color: #fff;\n    border-radius: 10px;\n    font-size: 24px;\n    max-width: 500px;\n    box-shadow:0px 0px 12px 2px rgba(43,209,145,0.35);\n    -webkit-box-shadow:0px 0px 12px 2px rgba(43,209,145,0.35);\n    -moz-box-shadow:0px 0px 12px 2px rgba(43,209,145,0.35);\n}\n\n.needToRegister__link {\n    text-transform: upperCase;\n    /* color: #2bd191be; */\n    color: var(--main-green-color);;\n    text-decoration: underline;\n}\n\n.needToRegister__link:hover, .needToRegister__link:active {\n    cursor: pointer;\n    color: #0a58ca;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
