import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import AxiosInstance from '../Axios';


function UpdateVPO({ userType, data }) {
    const button = useRef(null);
    const [selectedComponentMaterial, setSelectedComponentMaterial] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState(false);
    const [selectedComponent1, setSelectedComponent1] = useState(false);
    const [values, setValues] = useState({});
    let suport = data.supportType;
    let sendData = useRef({});
    const regions = ["Вінницька", "Волинська", "Дніпропетровська", "Донецька", "Житомирська", "Закарпатська", "Запорізька", "Івано-Франківська", "Київська", "Кіровоградська", "Луганська", "Львівська", "Миколаївська", "Одеська", "Полтавська", "Рівненська", "Сумська", "Тернопільська", "Харківська", "Херсонська", "Хмельницька", "Черкаська", "Чернівецька", "Чернігівська", "Автономна Республіка Крим"];

    const { register, handleSubmit, formState, watch } = useForm({
        values: {
            firstName: (data && data.firstName) && data.firstName,
            lastName: (data && data.lastName) && data.lastName,
            phone: (data && data.phone) && data.phone,
            // username: (data && data.username) && data.username,
            email: (data && data.email) && data.email,
            plainPassword: (data && data.plainPassword) && data.plainPassword,
            confirmPassword: (data && data.confirmPassword) && data.confirmPassword,
            employed: (data && data.employed) && data.employed,
            age: (data && data.age) && data.age,
            exDirector: (data && data.exDirector) && data.exDirector,
            currentDirector: (data && data.currentDirector) && data.currentDirector,
            gender: (data && data.gender) && data.gender,
            address: (data && data.address) && data.address,
            addressFrom: (data && data.addressFrom) && data.addressFrom,
            supportType: (data && data.supportType) && suport,
            anotherProgram: (data && data.anotherProgram) && data.anotherProgram
        }
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    useEffect(() => {
        console.log('values', selectedComponentMaterial)
    }, [selectedComponentMaterial])

    const supportTypeChange = (e) => {

        console.log('e.target.value', e.target.value)
        suport = suport.filter(e => {
            if (!selectedComponentMaterial) {
                console.log('!selected com')
                return e !== 'Матеріальна';
            } else {
                suport = [...suport, 'Матеріальна']
                console.log('!selected com')
                return e;
            }
        })
    }

    function sendForm() {

        button.current.disabled = true;
        const configAll = { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('user')}` } }

        // додав до обєкту roles i active
        const article = {
            ...sendData.current,
            roles: [
                "ROLE_USER"
            ],
            username: sendData.current.email,
            active: "on",
            userType: userType
        }
        // прибрав confirmPassword з обєкуту для сервера 
        const { confirmPassword, ...changedArticle } = article;

        // console.log('article', changedArticle);

        AxiosInstance.put(`/users/${data.id}`, changedArticle, configAll).then((response) => {
            if (response.status == 200) {
                window.location.replace("/profile");
            }
        }).catch((error) => {
            console.log(error);
        })

    };

    const handleOnSubmit = (data) => {
        sendData.current = data;
        sendForm();
        // console.log(JSON.stringify(data, null, 2))
    }

    const password = watch('plainPassword');
    // useEffect(() => {
    //     console.log('sendData', values)
    // }, [values])
    return (
        <div className='main-container my-5'>
            <h2>Редагувати профіль</h2>
            <Form className='profile-registration_buisness' >
                {/* <Row>
                    <Col className='d-flex align-items-center'>
                        <span style={{ fontWeight: '400', fontSize: '25px', margin: '0 auto' }}>Логін: <span style={{ fontWeight: '600' }}> {data.username}</span></span>
                    </Col>
                </Row> */}
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Номер телефону</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.phone !== undefined && 'forms-invalid'}
                            {...register('phone', { minLength: 10, maxLength: 12 })}
                        ></Form.Control>
                        {formState.errors.phone && formState.errors.phone.type === "minLength" && <span>Перевірте номер!</span>}

                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Електронна пошта</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            {...register('email', { required: 'is reqired!', pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                            className={formState.errors.email !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.email && formState.errors.email.type === "required" && <span>Це обов'язкове поле</span>}
                        {formState.errors.email && formState.errors.email.type === "pattern" && <span>Перевірте емейл</span>}
                    </Col>
                </Row>
                {/* <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control
                            type='password'
                            onChange={handleInputChange}
                            {...register('plainPassword', { required: 'is reqired!', minLength: 5 })}
                            className={formState.errors.plainPassword !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.plainPassword && formState.errors.plainPassword.type === "required" && <span>Це обов'язкове поле!</span>}
                        {formState.errors.plainPassword && formState.errors.plainPassword.type === "minLength" && <span>Мінімальна довжина 5 символи!</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Повторіть пароль</Form.Label>
                        <Form.Control
                            type='password'
                            onChange={handleInputChange}
                            {...register('confirmPassword', {
                                required: 'is reqired!', minLength: 5,
                                validate: (value) => value == password || 'паролі не збігаються'
                            })}
                            className={formState.errors.personSurname !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.confirmPassword && formState.errors.confirmPassword.type === "required" && <span>Це обов'язкове поле</span>}
                        {formState.errors.confirmPassword && formState.errors.confirmPassword.type === "validate" && <span>паролі не збігаються</span>}
                    </Col>
                </Row> */}
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label>Ім'я</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            placeholder="Введіть ім'я"
                            className={formState.errors.firstName !== undefined && 'forms-invalid'}
                            {...register('firstName', { required: 'is reqired!', minLength: { value: 3, message: 'length err' } })}
                        ></Form.Control>
                        {formState.errors.firstName && formState.errors.firstName.type === "required" && <span>Це обов'язкове поле!</span>}
                        {formState.errors.firstName && formState.errors.firstName.type === "minLength" && <span>Мінімальна довжина 3 символи!</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label>Прізвище</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            placeholder="Введіть прізвище"
                            className={formState.errors.lastName !== undefined && 'forms-invalid'}
                            {...register('lastName', { required: 'is reqired!', minLength: { value: 3, message: 'length err' } })}
                        >
                        </Form.Control>
                        {formState.errors.lastName && formState.errors.lastName.type === "required" && <span>Це обов'язкове поле</span>}
                        {formState.errors.lastName && formState.errors.lastName.type === "minLength" && <span>Мінімальна довжина 3 символи</span>}
                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Актуальна Адреса Проживання</Form.Label>
                        {/* <Form.Control placeholder='Обрати'></Form.Control> */}
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.address !== undefined && 'forms-invalid'}
                            {...register("address", { required: 'is reqired!' })}
                        >
                        </Form.Control>
                        {formState.errors.address && formState.errors.address.type === "required" && <span>Це обов'язкове поле</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label > Область з якої переїхали</Form.Label>
                        {/* <Form.Control placeholder='Обрати'></Form.Control> */}
                        <Form.Select
                            onChange={handleInputChange}
                            defaultValue={'область'}
                            aria-label="Default select example"
                            {...register('addressFrom', { required: 'is required!' })}
                            className={formState.errors.addressFrom !== undefined && 'forms-invalid'}
                        >
                            <option disabled> Область з якої переїхали</option>
                            {regions.map(e => {
                                return <option key={e} value={e}>{e}</option>
                            })}
                        </Form.Select>
                        {formState.errors.addressFrom && formState.errors.addressFrom.type === "required" && <span>Це обов'язкове поле</span>}
                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Зайнятість</Form.Label>

                        <Form.Select
                            onChange={handleInputChange}
                            placeholder='Оберіть зайнятість'
                            aria-label="Default select example"
                            className={formState.errors.employed !== undefined && 'forms-invalid'}
                            {...register('employed', { required: 'is reqired!' })}>
                            <option disabled>Зайнятість</option>
                            <option value="Працюю">Працюю</option>
                            <option value="не працюю">Не працюю</option>

                        </Form.Select>
                        {formState.errors.employed && formState.errors.employed.type === "required" && <span>Це обов'язкове поле</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Вік</Form.Label>

                        <Form.Select
                            onChange={handleInputChange}
                            aria-label="Default select example"
                            className={formState.errors.age !== undefined && 'forms-invalid'}
                            {...register('age', { required: 'is reqired!' })}
                        >
                            <option disabled>Виберіть вік</option>
                            <option value="18-30">18-30</option>
                            <option value="30-50">30-50</option>
                            <option value="50-60">50-60</option>
                        </Form.Select>
                        {formState.errors.age && formState.errors.age.type === "required" && <span>Це обов'язкове поле</span>}
                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Діючий Роботодавець</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.currentDirector !== undefined && 'forms-invalid'}
                            {...register('currentDirector', { required: 'is reqired!' })}
                        ></Form.Control>
                        {formState.errors.currentDirector && formState.errors.currentDirector.type === "required" && <span>Це обов'язкове поле</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Стать</Form.Label>

                        <Form.Select
                            onChange={handleInputChange}
                            aria-label="Default select example"
                            className={formState.errors.gender !== undefined && 'forms-invalid'}
                            {...register('gender', { required: 'is reqired!' })}
                        >
                            <option disabled>Стать</option>
                            <option value="Чоловік">Чоловік</option>
                            <option value="Жінка">Жінка</option>

                        </Form.Select>
                        {formState.errors.gender && formState.errors.gender.type === "required" && <span>Це обов'язкове поле</span>}

                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Попередній Роботодавець</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.exDirector !== undefined && 'forms-invalid'}
                            {...register('exDirector', { required: 'is reqired!' })}
                        ></Form.Control>
                        {formState.errors.exDirector && formState.errors.exDirector.type === "required" && <span>Це обов'язкове поле</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Чи користуєтесь іншими програмами</Form.Label>

                        <Form.Select
                            onChange={handleInputChange}
                            aria-label="Default select example"
                            {...register('anotherProgram', { required: 'is reqired!' })}
                        >
                            <option disabled>Чи користуєтесь іншими програмами</option>
                            <option value="Так">Так</option>
                            <option value="Ні">Ні</option>

                        </Form.Select>
                    </Col>
                </Row>
                {/* <h5 style={{ marginTop: '2rem' }}>Мені необхідна/я шукаю:</h5> */}
                <div className="registration-buisness_radio" id='feedback_row'>
                    <Form.Check
                        id='castom_check_green_sw'
                        // onChange={handleInputChange}
                        label='Матеріальна підтримка'
                        name='supportType'
                        value='Матеріальна'
                        // checked={formState?.values.supportType === 'material'}
                        checked={selectedComponentMaterial}
                        onClick={(e) => {
                            setSelectedComponentMaterial(!selectedComponentMaterial)
                            supportTypeChange(e)
                        }}
                        className={formState.errors.supportType !== undefined && 'forms-invalid'}
                    //{...register('supportType')}
                    >
                    </Form.Check>
                    {/* <Form.Check
                        id='castom_check_green_sw'
                        onChange={handleInputChange}
                        name='supportType'
                        label='Юридична підтримка'
                        value='Юридична'
                        checked={selectedComponent}
                        // checked={formState?.values.supportType === 'Юридична'}
                        onClick={() => setSelectedComponent(!selectedComponent)}
                        className={formState.errors.supportType !== undefined && 'forms-invalid'}
                        {...register('supportType')}
                    >
                    </Form.Check>
                    <Form.Check
                        id='castom_check_green_sw'
                        onChange={handleInputChange}
                        name='supportType'
                        value='Психологічна'
                        label='Психологічна підтримка'
                        checked={selectedComponent1}
                        // checked={formState?.values.supportType === 'Психологічна'}
                        onClick={() => setSelectedComponent1(!selectedComponent1)}
                        className={formState.errors.supportType !== undefined && 'forms-invalid'}
                        {...register('supportType')}
                    >
                    </Form.Check> */}
                </div>
            </Form>
            <Button className='btn-rout registration_btn' type='submit' onClick={handleSubmit(handleOnSubmit)} ref={button}>Зберегти</Button>
        </div>
    )
}

export default UpdateVPO
