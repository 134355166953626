// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback_row_mobile {
  display: none;
}

@media (max-width: 768px) {
  .forms-feedback_form {
    width: 90%;
  }
  .feedback_row {
    display: flex;
    flex-direction: column;
  }
  #feedback_row_check {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
    gap: 15px;
  }
  #feedback_row {
    margin-top: 2px;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start;
  }
  #surname {
    margin-top: 10px;
  }
  #surname_label {
    margin-top: 0;
  }
  #adaptive_check {
    margin: 0 auto;
    width: -moz-fit-content;
    width: fit-content;
  }
  #feedback_row_name {
    display: none;
  }
  #feedback_row_mobile {
    display: block;
  }
  #surname_mobile {
    margin: 0;
  }
  #surname_label_mobile {
    margin-top: 1rem;
  }
}
.hint {
  color: red;
  font-size: 12px;
  margin-left: 5px;
}

option {
  width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/styles/formsMobile.scss"],"names":[],"mappings":"AACA;EACI,aAAA;AAAJ;;AAGA;EAEI;IACI,UAAA;EADN;EAIE;IACI,aAAA;IACA,sBAAA;EAFN;EAKE;IACI,wBAAA;IACA,iCAAA;IACA,uBAAA;IACA,SAAA;EAHN;EAME;IACI,eAAA;IACA,wBAAA;IACA,iCAAA;IACA,uBAAA;EAJN;EAOE;IACI,gBAAA;EALN;EAQE;IACI,aAAA;EANN;EASE;IACI,cAAA;IACA,uBAAA;IAAA,kBAAA;EAPN;EAUE;IACI,aAAA;EARN;EAWE;IACI,cAAA;EATN;EAWE;IACI,SAAA;EATN;EAYE;IACI,gBAAA;EAVN;AACF;AAaA;EACI,UAAA;EACA,eAAA;EACA,gBAAA;AAXJ;;AAcA;EACI,YAAA;AAXJ","sourcesContent":["\n.feedback_row_mobile{\n    display: none;       \n}\n\n@media (max-width: 768px) {\n    // для форм\n    .forms-feedback_form{\n        width: 90%;\n    }\n\n    .feedback_row{\n        display: flex;\n        flex-direction: column;\n    }\n\n    #feedback_row_check{\n        display: flex!important;\n        flex-direction: column!important;\n        align-items: flex-start;\n        gap: 15px;\n    }\n\n    #feedback_row{\n        margin-top: 2px;\n        display: flex!important;\n        flex-direction: column!important;\n        align-items: flex-start;\n    }\n\n    #surname{\n        margin-top: 10px;\n    }\n    \n    #surname_label{\n        margin-top: 0;\n    }\n    \n    #adaptive_check{\n        margin: 0 auto;\n        width: fit-content;\n    }\n\n    #feedback_row_name{\n        display: none;\n    }\n\n    #feedback_row_mobile{\n        display: block; \n    }\n    #surname_mobile{\n        margin: 0;\n    }\n\n    #surname_label_mobile{\n        margin-top: 1rem;\n    }\n}\n\n.hint{\n    color: red;\n    font-size: 12px;\n    margin-left: 5px;\n}\n\noption{\n    width: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
