import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Row, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import AxiosInstance from '../Axios';
import dayjs from 'dayjs';

function LegalSupportForm({
    setIsSubmit,
    changeParentValue,
    removeHeader,
    removeButton,
    greenFormState,
    setNameSurname,
    setIsSentForPYSupport
}) {
    const FORM_ID = 6;
    const button = useRef(null);
    const [formData, SetFormData] = useState(false);
    const [file, setFile] = useState(false);
    const [values, setValues] = useState({});

    const centerLocations = [
        "м. Львів", "м. Дрогобич", "м. Стрий", "м. Городок", "м. Самбір", "м. Золочів", "м. Перемишляни", "м. Червоноград", "м. Кам’янка-Бузька",
        "м. Новояворівськ", "м. Яворів"
    ]

    let sendData;
    const { register, handleSubmit, formState } = useForm({
        values: {
            typeOfSupport: '',
            nameSurname: '',
            number: '',
            email: '',
            question: '',
            location: ''
        }
    })

    useEffect(() => {
        if (changeParentValue !== undefined) {
            changeParentValue(values);
        }
    }, [values])

    useEffect(() => {
        let active = false;
        let res = AxiosInstance.get(`/forms?page=1&itemsPerPage=30&id=${FORM_ID}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            SetFormData(response.data[0]);
        }).then(() => {
            // console.log(formData)
        })

        return () => {
            active = true;
        };

    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    function sendForm() {
        button.current.disabled = true;

        const formFiltred = formData.formFields.filter(field => {
            return field.type !== 'file';
        })

        const initalNames = formFiltred.map(field => {
            return field.name;
        }).reduce((a, v) => ({ ...a, [v]: '' }), {});

        const formAnswerFields = formFiltred.map(field => {
            // console.log(field.name)
            return { name: field.name, formField: `/api/form_fields/${field.id}`, value: sendData[field.name], label: field.label, type: field.type };
        })

        const configFile = { headers: { 'content-type': 'multipart/form-data', } }

        const configAll = { headers: { 'content-type': 'application/json', } }

        const article = {
            form: `/api/forms/${formData.id}`,
            media: [],
            dateEntered: dayjs().format(),
            "view": false,
            formAnswerFields: formAnswerFields,
        }

        if (file) {
            const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects';
            const formFile = new FormData();
            formFile.append('file', file);
            formFile.append('fileName', file.name);

            axios.post(url, formFile, configFile).then((response) => {

                const articleFile = { ...article, media: [`${response.data['@id']}`] }

                axios.post(process.env.REACT_APP_SERVER_URL + '/api/form_answers', articleFile, configAll)
                    .then(() => {
                        setValues(initalNames);
                        setFile(false);
                        button.current.disabled = false;
                    })
                    .catch(() => {
                        setValues(initalNames);
                        setFile(false);
                    });
            });
        }
        else {
            AxiosInstance.post('/form_answers', article, configAll).then(() => {
                setValues(initalNames);
                setIsSentForPYSupport(true);
                setFile(false);
                button.current.disabled = false;
                setIsSubmit(true);
            }).catch(() => {

            })
        }
    };

    const onSubmit = (data) => {
        // console.log(data)
        setNameSurname(data.nameSurname)
        sendData = data
        sendForm();
        setIsSubmit(true);
    }

    // useEffect(() => {
    //     if(validateGreen){
    //         handleSubmit(onSubmit);
    //         alert('validateGreen');

    //     }
    // }, [validateGreen])
    return (
        <div className='my-5'>
            {/* {!removeHeader && <h3 style={{color: '#3AC9B5', marginTop:'2rem'}}>Отрмайте контактні дані після заповнення</h3>} */}
            <h3 className='mt-5' style={{ textAlign: 'center' }}>Форма заявки на Психолого-Юридичну підтримку:</h3>
            <Form className='forms-mentoring_form px-5 mt-4 mb-5 main-content' noValidate autoComplete='off'
                onSubmit={handleSubmit(onSubmit)}
            >
                <Row>
                    <Col className='forms-form-col'>
                        <Form.Label className='forms_label'>  <span className='forms_red_star'>*</span> Яку підтримку вам потрібно</Form.Label>

                        <Form.Select
                            className={formState.errors.typeOfSupport !== undefined && 'forms-invalid'}
                            {...register('typeOfSupport', { required: true })}
                            defaultValue={greenFormState !== undefined && greenFormState.typeOfSupport}
                            onChange={handleInputChange}
                        >
                            <option>Юридична</option>
                            <option>Психологічна</option>
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col className='forms-form-col'>
                        <Form.Label className='forms_label'>  <span className='forms_red_star'>*</span> Локація центру звернення <small>(оберіть місто)</small></Form.Label>

                        <Form.Select
                            className={formState.errors.location !== undefined && 'forms-invalid'}
                            {...register('location', { required: true })}
                            defaultValue={greenFormState !== undefined && greenFormState.location}
                            onChange={handleInputChange}
                        >
                            {
                                centerLocations.map((city, index) => {
                                    return (<option key={index}>{city}</option>)
                                })
                            }

                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col className='forms-form-col'>
                        <Form.Label className='forms_label'>  <span className='forms_red_star'>*</span> Ваше Ім'я та Прізвище</Form.Label>

                        <Form.Control
                            className={formState.errors.nameSurname !== undefined && 'forms-invalid'}
                            {...register('nameSurname', { required: true })}
                            defaultValue={greenFormState !== undefined && greenFormState.nameSurname}
                            onChange={handleInputChange}
                        ></Form.Control>
                    </Col>
                </Row>
                <Row>
                    <Col className='forms-form-col'>
                        <Form.Label className='forms_label'>  <span className='forms_red_star'>*</span> Ваша електронна пошта</Form.Label>

                        <Form.Control
                            className={formState.errors.email !== undefined && 'forms-invalid'}
                            {...register('email', { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                            defaultValue={greenFormState !== undefined && greenFormState.email}
                            onChange={handleInputChange}
                        ></Form.Control>
                        {formState.errors.email && formState.errors.email.type === "pattern" && <span className='hint'>У вигляді: example@gmail.com</span>}

                    </Col>
                </Row>
                <Row>
                    <Col className='forms-form-col'>
                        <Form.Label className='forms_label'>  <span className='forms_red_star'>*</span> Ваш контактний телефон</Form.Label>

                        <Form.Control
                            className={formState.errors.number !== undefined && 'forms-invalid'}
                            {...register('number', { required: true, minLength: 10, maxLength: 12, pattern: /^\d+$/ })}
                            defaultValue={greenFormState !== undefined && greenFormState.number}
                            onChange={handleInputChange}></Form.Control>
                        {formState.errors.number && formState.errors.number.type === "minLength" && <span className='hint'>Довжина 8 або 10 символів</span>}

                    </Col>
                </Row>
                <Row>
                    <Col className='forms-form-col'>
                        <Form.Label className='forms_label' >  <span className='forms_red_star'>*</span> Запитання</Form.Label>

                        <Form.Control
                            className={formState.errors.question !== undefined && 'forms-invalid'}
                            style={{ height: '5rem' }}
                            {...register('question', { required: true })}
                            defaultValue={greenFormState !== undefined && greenFormState.question}
                            onChange={handleInputChange}
                        ></Form.Control>
                    </Col>
                </Row>
                <Row>
                    {!removeButton && <Button variant="light" style={{ fontWeight: "500", borderRadius: "0px", margin: "1rem auto", width: '10rem' }} type='submit' ref={button}>Надіслати</Button>}
                </Row>
            </Form>
        </div>
    )
}

export default LegalSupportForm
