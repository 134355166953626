import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import NewMentoringForm from "../../../Forms/NewMentoringForm";
import ThanksForRequest from "../../ThanksForRequest";


const RelocationRequest = ({ data }) => {

    const [isSubmit, setIsSubmit] = useState(false);
    // Для поля з текстом-подякою про успішне надсилання форми
    const [nameSurname, setNameSurname] = useState("#вказане ІП");
    // Для поля з текстом-подякою про успішне надсилання форми
    const thanksRef = useRef(null);

    // Після відправлення форми проскорлити екран, щоб поле з текстом-подякою про успішне надсилання форми було зверху екрана
    useEffect(() => {
        if (thanksRef.current) {
            thanksRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [isSubmit]);

    return (
        <Container className="my-sm-5 business-mentoringProgram__container wrapper">
            {
                (data.length > 0) &&
                <div dangerouslySetInnerHTML={{ __html: data[0].content }}></div>
            }
            {/* <Link to={data.length > 0 && data[0].value.split(',')[1]}>
                <Button className="btn-rout">
                    Дізнатись більше
                </Button>
            </Link> */}

            {/* Форма заявки на релокацію */}
            {
                !isSubmit &&
                <div className="my-5">
                    <h4 className="mb-4">Форма заявки на релокацію:</h4>
                    <NewMentoringForm setIsSubmit={setIsSubmit} setNameSurname={setNameSurname} />
                </div>
            }

            {/* Поле з текстом-подякою про успішне надсилання форми */}
            {
                isSubmit &&
                <div className="px-5 my-5 support_green_box support_green_box_inner" ref={thanksRef}>
                    <ThanksForRequest nameSurname={nameSurname} page="business/relocation-request" />
                </div>
            }
        </Container>
    );
};

export default RelocationRequest;