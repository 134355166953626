import { useState, useRef, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import AxiosInstance from '../Axios';
import dayjs from 'dayjs';
import axios from "axios";

function NewMentoringForm({ setNameSurname, setIsSubmit }) {

    const FORM_ID = 9;
    const button = useRef(null);
    const [formData, SetFormData] = useState(false);
    const [file, setFile] = useState(false);
    const [values, setValues] = useState({});

    const [selectedOption, setSelectedOption] = useState({
        micro: false
    });
    const [selectedOptionPerson, setSelectedOptionPerson] = useState({
        micro: false
    });

    let sendData;
    const [typeOfPerson, setTypeOfPerson] = useState({});
    const { register, handleSubmit, formState } = useForm({
        values: {
            typeofPerson: '',
            code: '',
            IPN: '',
            placeOfRegist: '',
            currentPlace: '',
            directorName: '',
            type: '',
            description: '',
            descriptionParams: '',
            isLvivPlace: '',
            isLvivPerson: '',
            contactName: '',
            number: '',
            email: ''
        }
    })

    const handleCheckboxClick = (value) => {
        if (selectedOption === value) {
            setSelectedOption(null);
        } else {
            setSelectedOption(value);
        }
    };

    const handleCheckboxClickPerson = (value) => {
        if (selectedOptionPerson === value) {
            setSelectedOptionPerson(null);
        } else {
            setSelectedOptionPerson(value);
        }
    };

    const handleCheckboxClickType = (value) => {
        if (typeOfPerson === value) {
            setTypeOfPerson(null);
        } else {
            setTypeOfPerson(value);
        }
    };

    useEffect(() => {
        let active = false;
        let res = AxiosInstance.get(`/forms?page=1&itemsPerPage=30&id=${FORM_ID}`, { headers: { 'accept': 'application/json' } }).then((response) => {
            SetFormData(response.data[0]);
        }).then(() => {
            // console.log(formData)
        })

        return () => {
            active = true;
        };

    }, [])

    // useEffect(() => {
    //     console.log('values', values)
    // }, [values])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });

    };

    function sendForm() {
        button.current.disabled = true;

        const formFiltred = formData.formFields.filter(field => {
            return field.type !== 'file';
        })

        const initalNames = formFiltred.map(field => {
            return field.name;
        }).reduce((a, v) => ({ ...a, [v]: '' }), {});

        const formAnswerFields = formFiltred.map(field => {
            // console.log(field.name)
            return {
                name: field.name,
                formField: `/api/form_fields/${field.id}`,
                // value:  typeof(sendData[field.name]) == 'object' ? JSON.stringify(sendData[field.name]) : sendData[field.name], 
                value: typeof (sendData[field.name]) == 'object' ? sendData[field.name][0] : sendData[field.name],
                label: field.label,
                type: field.type
            };
        })

        const configFile = { headers: { 'content-type': 'multipart/form-data', } }

        const configAll = { headers: { 'content-type': 'application/json', } }

        const article = {
            form: `/api/forms/${formData.id}`,
            media: [],
            dateEntered: dayjs().format(),
            "view": false,
            formAnswerFields: formAnswerFields,
        }

        if (file) {
            const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects';
            const formFile = new FormData();
            formFile.append('file', file);
            formFile.append('fileName', file.name);

            axios.post(url, formFile, configFile).then((response) => {

                const articleFile = { ...article, media: [`${response.data['@id']}`] }

                axios.post(process.env.REACT_APP_SERVER_URL + '/api/form_answers', articleFile, configAll)
                    .then(() => {
                        setValues(initalNames);
                        setFile(false);
                        button.current.disabled = false;
                    })
                    .catch(() => {
                        setValues(initalNames);
                        setFile(false);
                    });
            });
        }
        else {
            AxiosInstance.post('/form_answers', article, configAll).then(() => {
                setValues(initalNames);
                setFile(false);
                button.current.disabled = false;
                setIsSubmit(true)
            }).catch(() => {

            })
        }
    };

    const onSubmit = (data) => {
        sendData = data;
        setIsSubmit(true);
        setNameSurname(data.contactName);
        sendForm();
        // console.log(
        //     JSON.stringify(data, null, 2)
        // )
    }

    return (
        <Form noValidate autoComplete='off' className='px-5 forms-mentoring_form' onSubmit={handleSubmit(onSubmit)}>
            <Col style={{ alignItems: 'left' }}>
                <div className="check_boxes" id='feedback_row'>
                    <Form.Check
                        checked={typeOfPerson === 'Юридична особа'}
                        onClick={() => handleCheckboxClickType('Юридична особа')}
                        label='Юридична особа'
                        value='Юридична особа'
                        className='check_boxes-checks'
                        {...register('typeofPerson', { required: true })}
                        onChange={handleInputChange}
                    ></Form.Check>
                    <Form.Check
                        checked={typeOfPerson === 'Фізична особа підприємець'}
                        onClick={() => handleCheckboxClickType('Фізична особа підприємець')}
                        label='Фізична особа підприємець'
                        value='Фізична особа підприємець'
                        className='check_boxes-checks'
                        {...register('typeofPerson', { required: true })}
                        onChange={handleInputChange}></Form.Check>
                    {formState.errors.typeofPerson && <span className="hint">Оберіть значення</span>}
                </div>

            </Col>
            <Row id='feedback_row' style={{ marginTop: '1rem' }}>
                <Col className='forms-form-col'>
                    <Form.Label> <span className='forms_red_star'>*</span> Код ЄДРПОУ: <small className="forms_small">(якщо юридична особа)</small></Form.Label>
                </Col>
                <Col>
                    <Form.Control
                        className={formState.errors.code !== undefined ? 'forms-invalid' : null}
                        {...register('code', { required: (typeOfPerson[0] == 'Юридична особа' && true), maxLength: 8, minLength: 8, pattern: /^\d+$/ })}
                        onChange={handleInputChange}></Form.Control>
                    {formState.errors.code && formState.errors.code.type === "minLength" && <span className='hint'>Мінімальна довжина 8 символів</span>}

                </Col>
            </Row>
            <Row id='feedback_row' style={{ marginTop: '1rem' }}>
                <Col className='forms-form-col'>
                    <Form.Label>  <span className='forms_red_star'>*</span> ІПН: <small className="forms_small">(якщо фізична особа підприємець)</small></Form.Label>
                </Col>
                <Col>
                    <Form.Control
                        className={formState.errors.IPN !== undefined ? 'forms-invalid' : null}
                        {...register('IPN', { required: (typeOfPerson[0] == 'Фізична особа підприємець' && true), maxLength: 10, minLength: 10, pattern: /^\d+$/ })}
                        onChange={handleInputChange}></Form.Control>
                    {formState.errors.IPN && formState.errors.IPN.type === "minLength" && <span className='hint'>Довжина 10 символів</span>}

                </Col>
            </Row>
            <Row id='feedback_row' style={{ marginTop: '1rem' }}>
                <Col className='forms-form-col'>
                    <Form.Label>  <span className='forms_red_star'>*</span> Місце реєстрації підприємства:</Form.Label>
                </Col>
                <Col>
                    <Form.Control
                        className={formState.errors.placeOfRegist !== undefined ? 'forms-invalid' : null}
                        {...register('placeOfRegist', { required: true })}
                        onChange={handleInputChange}></Form.Control>
                </Col>
            </Row>
            <Row id='feedback_row' style={{ marginTop: '1rem' }}>
                <Col className='forms-form-col'>
                    <Form.Label>  <span className='forms_red_star'>*</span> Фактичне місце розміщення підприємства:</Form.Label>
                </Col>
                <Col>
                    <Form.Control
                        className={formState.errors.currentPlace !== undefined ? 'forms-invalid' : null}
                        {...register('currentPlace', { required: true })}
                        onChange={handleInputChange}></Form.Control>
                </Col>
            </Row>
            <Row id='feedback_row' style={{ marginTop: '1rem' }}>
                <Col className='forms-form-col'>
                    <Form.Label>  <span className='forms_red_star'>*</span> ПІБ керівника підприємства:</Form.Label>
                </Col>
                <Col>
                    <Form.Control
                        className={formState.errors.directorName !== undefined ? 'forms-invalid' : null}
                        {...register("directorName", { required: true })}
                        onChange={handleInputChange}></Form.Control>
                </Col>
            </Row>
            <Row id='feedback_row' style={{ marginTop: '1rem' }}>
                <Col className='forms-form-col'>
                    <Form.Label>  <span className='forms_red_star'>*</span> Основний вид діяльності (основний КВЕД):</Form.Label>
                </Col>
                <Col>
                    <Form.Control
                        className={formState.errors.type !== undefined ? 'forms-invalid' : null}
                        {...register("type", { required: true })}
                        onChange={handleInputChange}></Form.Control>
                </Col>
            </Row>
            <Row id='feedback_row'>
                <Col className='forms-form-col'>
                    <Form.Label >  <span className='forms_red_star'>*</span> Опис діяльності підприємства (до 300 слів):</Form.Label>

                    <Form.Control
                        className={formState.errors.description !== undefined && 'forms-invalid'}
                        style={{ height: '5rem' }}
                        {...register('description', { required: true })}
                        onChange={handleInputChange}></Form.Control>
                </Col>
            </Row>
            <Row id='feedback_row'>
                <Col className='forms-form-col'>
                    <Form.Label >  <span className='forms_red_star'>*</span> Опис необхідних параметрів об'єкту куди має здійснюватись релокація підприємства (до 300 слів):</Form.Label>

                    <Form.Control
                        className={formState.errors.descriptionParams !== undefined && 'forms-invalid'}
                        style={{ height: '5rem' }}
                        {...register('descriptionParams', { required: true })}
                        onChange={handleInputChange}></Form.Control>
                </Col>
            </Row>
            <Row id='feedback_row'>
                <Col className='forms-form-col'>
                    <Form.Label>  <span className='forms_red_star'>*</span> Чи потрібна допомога з перевезенням обладнання на територію Львівської області?</Form.Label>
                </Col>
                <Col>
                    <div className="check_boxes" >
                        <Form.Check
                            checked={selectedOption === 'Так'}
                            onClick={() => handleCheckboxClick('Так')}
                            label='Так'
                            value='Так'
                            className='check_boxes-checks'
                            {...register('isLvivPlace', { required: true })}
                            onChange={handleInputChange}></Form.Check>
                        <Form.Check
                            checked={selectedOption === 'Ні'}
                            onClick={() => handleCheckboxClick('Ні')}
                            label='Ні'
                            value='Ні'
                            className='check_boxes-checks'
                            {...register('isLvivPlace', { required: true })}
                            onChange={handleInputChange}></Form.Check>
                        {formState.errors.isLvivPlace && <span className="hint">Оберіть значення</span>}
                    </div>

                </Col>
            </Row>
            <Row id='feedback_row'>
                <Col className='forms-form-col'>
                    <Form.Label>
                        <span className='forms_red_star'>*</span> Чи потрібна допомога з поселенням працівників на території Львівської області?
                    </Form.Label>
                </Col>
                <Col>
                    <div className="check_boxes" >
                        <Form.Check
                            checked={selectedOptionPerson === 'Так'}
                            onClick={() => handleCheckboxClickPerson('Так')}
                            label='Так'
                            value='Так'
                            className='check_boxes-checks'
                            {...register('isLvivPerson', { required: true })}
                            onChange={handleInputChange}></Form.Check>
                        <Form.Check
                            checked={selectedOptionPerson === 'Ні'}
                            onClick={() => handleCheckboxClickPerson('Ні')}
                            label='Ні'
                            value='Ні'
                            className='check_boxes-checks'
                            {...register('isLvivPerson', { required: true })}
                            onChange={handleInputChange}></Form.Check>
                        {formState.errors.isLvivPerson && <span className="hint">Оберіть значення</span>}
                    </div>

                </Col>
            </Row>
            <Row id='feedback_row' style={{ marginTop: '1rem' }}>
                <Col className='forms-form-col'>
                    <Form.Label>  <span className='forms_red_star'>*</span> Контактна особа ПІБ:</Form.Label>
                </Col>
                <Col>
                    <Form.Control
                        className={formState.errors.contactName !== undefined ? 'forms-invalid' : null}
                        {...register("contactName", { required: true })}
                        onChange={handleInputChange}></Form.Control>
                </Col>
            </Row>
            <Row id='feedback_row' style={{ marginTop: '1rem' }}>
                <Col className='forms-form-col'>
                    <Form.Label>  <span className='forms_red_star'>*</span> Контактний номер телефону:</Form.Label>
                </Col>
                <Col>
                    <Form.Control
                        className={formState.errors.number !== undefined && 'forms-invalid'}
                        {...register('number', { required: true, minLength: 10, maxLength: 12, pattern: /^\d+$/ })}
                        onChange={handleInputChange}></Form.Control>
                    {formState.errors.number && formState.errors.number.type === "minLength" && <span className='hint'>Довжина 8 або 10 символів</span>}

                </Col>
            </Row>
            <Row id='feedback_row' style={{ marginTop: '1rem' }}>
                <Col className='forms-form-col'>
                    <Form.Label>  <span className='forms_red_star'>*</span> Електронна пошта:</Form.Label>
                </Col>
                <Col style={{ alignItems: 'flex-start' }}>
                    <Form.Control
                        type='email'
                        className={formState.errors.email !== undefined && 'forms-invalid'}
                        {...register('email', { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}></Form.Control>
                    {formState.errors.email && formState.errors.email.type === "pattern" && <span className='hint'>У вигляді: example@gmail.com</span>}

                </Col>
            </Row>
            <Button variant="light" type='submit' style={{ fontWeight: "500", borderRadius: "0px", marginTop: "2rem" }} ref={button}> Надіслати</Button>
        </Form>
    )
}

export default NewMentoringForm;