import axios from 'axios';

// const tokenStr = localStorage.getItem('user');
// const tokenStr = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2OTY4MzY3MzUsImV4cCI6MTY5Njg2NTUzNSwicm9sZXMiOlsiUk9MRV9BRE1JTiIsIlJPTEVfVVNFUiJdLCJ1c2VybmFtZSI6ImFkbWluIn0.tHspnuqjnI8aRDy4dYB8OQv6pDPL5DgP0UFBJw3eLJpQI1oZWEu8cRbJNibIs8b9vbvTJ3YsZJ8nj0qXfBRLof3FEGgqPoUwgZQvb8EU6CyntQwc80O82ahcaKliLbaWdLcFPGqbGF3IVhUCbfUMGmlcy_-rvcE6BdyzE-JB3cpYJoj55DOqz71n0EGJUbO1_2kI9YS_TTrcXJYjPhozU0mnltpboH7r6aJiOjK0hFYtUVbTVrtzTICEvxcJDTXXASMlR0Nn0O-91hKuG2uSI1miqRsoiVntRqf1vRzFzw1tnlqJeIx3FFWqAM-mZXtQaoXtOjl6Rc9dA8lEKb_Ikw";

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL + '/api',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    // "Authorization": `Bearer ${tokenStr}`,
    // 'accept': 'application/json'

  },
});

AxiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent
  // console.log(11111);
  // console.log(config);
  if (config.method == "patch")
    config.headers['Content-Type'] = 'application/merge-patch+json';
  if (config.method == "post")
    config.headers['Content-Type'] = 'application/json';
  // console.log(config);
  return config;
});


AxiosInstance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  // console.log(response);
  return response;
}, function (error) {

  if (error.response && error.code == "ERR_NETWORK") {
    alert('Помилка зєднання з сервером!')
    console.log(error);
    // console.log(error.response);
  }

  return Promise.reject(error);
});

export default AxiosInstance;
