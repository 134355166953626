import React from 'react'

function SupportBaner() {
  return (
    <div className='support-baner '>
      <h3 className='support-baner_header'>
        ОП співпрацюють з наступними міжнародними організаціями для потреб ВПО
      </h3>
      <div className="support-baner_images">
        <img src="/images/Support/spport1.jpg" alt="helpAge" />
        <img src="/images/Support/spport2.jpg" alt="giz" />
        <img src="/images/Support/spport3.jpg" alt="nrc" />
        <img src="/images/Support/spport4.jpg" alt="medical corps" />
      </div>
    </div>
  )
}

export default SupportBaner
