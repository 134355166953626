import React, { useState, useRef, useEffect } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import AxiosInstance from '../Axios';


const urlApi = '/users';
const page = 1;

export async function LoaderUpdateProfileLviv(params) {
    // console.log("params Loader", params);
    let res = await AxiosInstance.get(`${urlApi}/${params.params.id}`).then((response) => {
        // console.log("response LoaderBusinessProgramPage", response);
        return response;
    });
    return res;
}

function UpdateProfileLviv({userType, data}) {

    const [selectedComponentMaterial, setSelectedComponentMaterial] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState(false);
    const [selectedComponent1, setSelectedComponent1] = useState(false);
    const button = useRef(null);
    const [values, setValues] = useState({});
    let sendData = useRef({});
    
    const { register, handleSubmit, formState, watch } = useForm({
        values: {
            firstName: (data && data.firstName) && data.firstName,
            lastName: (data && data.lastName) &&data.lastName,
            phone: (data && data.phone) && data.phone,
            //username: '',
            email: (data && data.email) && data.email,
            plainPassword: '',
            confirmPassword: '',
            employed: (data && data.employed) && data.employed,
            age: (data && data.age) && data.age,
            exDirector: (data && data.exDirector) && data.exDirector,
            currentDirector: (data && data.currentDirector) && data.currentDirector,
            gender: (data && data.gender) && data.gender,
            address: (data && data.address) && data.address,
            supportType: (data && data.supportType) && data.supportType
        }
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    function sendForm() {

        button.current.disabled = true;
        const configAll = { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('user')}`} }

        const article = {
            ...sendData.current,
            roles: [
                "ROLE_USER"
            ],
            username: sendData.current.email,
            active: "on",
            userType: userType   
        }
       
        const { confirmPassword, ...changedArticle } = article;

        AxiosInstance.put(`/users/${data.id}`, changedArticle, configAll).then((response) => {   
            if (response.status == 200){
                window.location.replace('/profile')
            }
        }).catch((error) => {
            console.log(error);
        })

    };

    const handleOnSubmit = (data) => {  
        sendData.current = data;
        sendForm();
    }

    const password = watch('plainPassword');

    return (
        <div className='main-container my-5'>
             <h2>Редагувати профіль</h2>
            <Form className='profile-registration_buisness' noValidate>
                {/* <Row>
                    <Col className='d-flex align-items-center'>
                        <span style={{ fontWeight: '400', fontSize: '25px', margin: '0 auto' }}>Логін: <span style={{ fontWeight: '600' }}> {data.username}</span></span>
                    </Col>
                </Row> */}
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Номер телефону</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.phone !== undefined && 'forms-invalid'}
                            {...register('phone', { minLength: 10, maxLength: 12 })}></Form.Control>
                        {formState.errors.phone && formState.errors.phone.type === "minLength" && <span>Перевірте номер!</span>}

                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Електронна пошта</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            {...register('email', { required: 'is reqired!', pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                            className={formState.errors.email !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.email && formState.errors.email.type === "required" && <span>Це обов'язкове поле</span>}
                        {formState.errors.email && formState.errors.email.type === "pattern" && <span>Перевірте емейл</span>}
                    </Col>
                </Row>
                {/* <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control
                            type='password'
                            onChange={handleInputChange}
                            {...register('plainPassword', { required: 'is reqired!', minLength: 5 })}
                            className={formState.errors.plainPassword !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.plainPassword && formState.errors.plainPassword.type === "required" && <span>Це обов'язкове поле!</span>}
                        {formState.errors.plainPassword && formState.errors.plainPassword.type === "minLength" && <span>Мінімальна довжина 5 символи!</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Повторіть пароль</Form.Label>
                        <Form.Control
                            type='password'
                            onChange={handleInputChange}
                            {...register('confirmPassword', {
                                required: 'is reqired!', minLength: 5,
                                validate: (value) => value == password || 'паролі не збігаються'
                            })}
                            className={formState.errors.confirmPassword !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.confirmPassword && formState.errors.confirmPassword.type === "required" && <span>Це обов'язкове поле</span>}
                        {formState.errors.confirmPassword && formState.errors.confirmPassword.type === "validate" && <span>паролі не збігаються</span>}
                    </Col>
                </Row> */}
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label>Ім'я</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.firstName !== undefined && 'forms-invalid'}
                            {...register('firstName', { required: 'is reqired!', minLength: { value: 3, message: 'length err' } })}>
                        </Form.Control>
                        {formState.errors.firstName && formState.errors.firstName.type === "required" && <span>Це обов'язкове поле!</span>}
                        {formState.errors.firstName && formState.errors.firstName.type === "minLength" && <span>Мінімальна довжина 3 символи!</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label>Прізвище</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.lastName !== undefined && 'forms-invalid'}
                            {...register('lastName', { required: 'is reqired!', minLength: { value: 3, message: 'length err' } })}>
                        </Form.Control>
                        {formState.errors.lastName && formState.errors.lastName.type === "required" && <span>Це обов'язкове поле</span>}
                        {formState.errors.lastName && formState.errors.lastName.type === "minLength" && <span>Мінімальна довжина 3 символи</span>}
                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Зайнятість</Form.Label>
                        {/* <Form.Control placeholder='Обрати(Працюю - Не Працюю)'></Form.Control>     */}
                        <Form.Select aria-label="Default select example"
                            onChange={handleInputChange}
                            className={formState.errors.employed !== undefined && 'forms-invalid'}
                            {...register('employed', { required: 'is reqired!' })}>
                            <option disabled>Зайнятість</option>
                            <option value="Працюю">Працюю</option>
                            <option value="Не працюю">Не працюю</option>

                        </Form.Select>
                        {formState.errors.employed && formState.errors.employed.type === "required" && <span>Це обов'язкове поле</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Вік</Form.Label>
                        {/* <Form.Control placeholder='Обрати (18-30, 30-50, 50-60)'></Form.Control>  */}
                        <Form.Select aria-label="Default select example"
                            onChange={handleInputChange}
                            className={formState.errors.age !== undefined && 'forms-invalid'}
                            {...register('age', { required: 'is reqired!' })}>
                            <option disabled>Виберіть вік</option>
                            <option value="18-30">18-30</option>
                            <option value="30-50">30-50</option>
                            <option value="50-60">50-60</option>
                        </Form.Select>
                        {formState.errors.age && formState.errors.age.type === "required" && <span>Це обов'язкове поле</span>}
                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Діючий Роботодавець</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.currentDirector !== undefined && 'forms-invalid'}
                            {...register('currentDirector', { required: 'is reqired!' })}></Form.Control>
                        {formState.errors.currentDirector && formState.errors.currentDirector.type === "required" && <span>Це обов'язкове поле</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Стать</Form.Label>
                        {/* <Form.Control placeholder='Обрати (Чоловік - Жінка)'></Form.Control>  */}
                        <Form.Select aria-label="Default select example"
                            onChange={handleInputChange}
                            className={formState.errors.gender !== undefined && 'forms-invalid'}
                            {...register('gender', { required: 'is reqired!' })}>
                            <option disabled>Стать</option>
                            <option value="Чоловік">Чоловік</option>
                            <option value="Жінка">Жінка</option>
                        </Form.Select>
                        {formState.errors.gender && formState.errors.gender.type === "required" && <span>Це обов'язкове поле</span>}

                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Попередній Роботодавець</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.exDirector !== undefined && 'forms-invalid'}
                            {...register('exDirector', { required: 'is reqired!' })}></Form.Control>
                        {formState.errors.exDirector && formState.errors.exDirector.type === "required" && <span>Це обов'язкове поле</span>}

                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Адреса Проживання</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.adress !== undefined && 'forms-invalid'}
                            {...register("address", { required: 'is reqired!' })}></Form.Control>
                        {formState.errors.address && formState.errors.address.type === "required" && <span>Це обов'язкове поле</span>}

                    </Col>
                </Row>
                <h5 style={{ marginTop: '3rem', textAlign: 'center' }}>Мені необхідна/я шукаю:</h5>
                <div className="registration-buisness_radio" id='feedback_row'>
                    <Form.Check
                        id='castom_check_green_sw'
                        label='Матеріальна підтримка'
                        name='supportType'
                        value='Матеріальна'
                        // checked={formState?.values.supportType === 'material'}
                        checked={selectedComponentMaterial}
                        onClick={() => setSelectedComponentMaterial(!selectedComponentMaterial)}
                        className={formState.errors.supportType !== undefined && 'forms-invalid'}
                        {...register('supportType')}></Form.Check>
                    <Form.Check
                        id='castom_check_green_sw'
                        name='supportType'
                        label='Юридична підтримка'
                        value='Юридична'
                        checked={selectedComponent}
                        // checked={formState?.values.supportType === 'Юридична'}
                        onClick={() => setSelectedComponent(!selectedComponent)}
                        className={formState.errors.supportType !== undefined && 'forms-invalid'}
                        {...register('supportType')}>
                    </Form.Check>
                    <Form.Check
                        id='castom_check_green_sw'
                        name='supportType'
                        value='Психологічна'
                        label='Психологічна підтримка'
                        checked={selectedComponent1}
                        // checked={formState?.values.supportType === 'Психологічна'}
                        onClick={() => setSelectedComponent1(!selectedComponent1)}
                        className={formState.errors.supportType !== undefined && 'forms-invalid'}
                        {...register('supportType')}>
                    </Form.Check>
                </div>
            </Form>

            <Button className='btn-rout registration_btn ' type='submit' onClick={handleSubmit(handleOnSubmit)} ref={button}>Зберегти</Button>
        </div>
    )
}

export default UpdateProfileLviv;
