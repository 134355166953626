import React from 'react'
import UpdateProfileBusiness from './UpdateProfileBusiness'
import UpdateProfileLviv from './UpdateProfileLviv'
import AxiosInstance from '../Axios';
import { useLoaderData } from 'react-router-dom';
import UpdateVPO from './UpdateVPO';

const urlApi = '/users';
const page = 1;

export async function LoaderUpdateProfile(params) {
  // console.log("params Loader", params);
  let res = await AxiosInstance.get(`${urlApi}/${params.params.id}`).then((response) => {
      // console.log("response LoaderBusinessProgramPage", response);
      return response;
  });
  return res;
}

function UpdatePage() {
  const {data} = useLoaderData()
  return (
    <div>
        {
          data.userType == "business" &&
          <UpdateProfileBusiness data={data}/>
        }
        {
          (data.userType == "Lviv" || data.userType === undefined) &&
          <UpdateProfileLviv data={data} />
        }
        {
          data.userType == "Vpo" &&
          <UpdateVPO data={data}/>
       }
    </div>
  )
}

export default UpdatePage
