import React from 'react'

function SupportContact(props) {
  // console.log('props:', props);
  return (
    <div className='support-contact'>
      <h3 className='support-contact_city'>{props.data.city}</h3>
      <p>{props.data.street}</p>
      <p>{props.data.number}</p>
      <p>{props.data.email !== '' ? props.data.email: null }</p>
      <p id="support-contact_graph">{props.data.graph}</p>
    </div>
  )
}

export default SupportContact
