import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NavibarLogIn from './NavibarLogIn';
import NavibarLogOut from './NavibarLogOut';
import useOutsideClick from '../../myFunction/useOutsideClick';

const Navibar = () => {

  // --- Start --- Діставання з localStorage імені залогіненого користувача з затримкою в часі, щоб localStorage встиг прогузитися
  const [start, setStart] = useState(true);
  const [localName, setLocalName] = useState('');
  const location = useLocation();
  // Для відкивання/закривання Navbar.Collaps
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  // Посилання на елемент Navbar
  const dropdownRef = useRef(null);

  async function getDataStorage() {
    let local = await localStorage.getItem('user_data');
    // console.log("localStorage", local);
    if (local != null && local != '') {
      setLocalName(window.JSON.parse(local));
    }
    setStart(false);
    return local;
  };

  useEffect(() => {
    if (start) {
      getDataStorage();
    } else return
  });
  // --- End ---

  // const userData = JSON.parse(localStorage.getItem('user_data'));

  // Закрити Navbar.Collapse про зміні url
  useEffect(() => {
    setIsNavCollapsed(true);
  }, [location.pathname]);

  // Функція для відкриття Navbar.Collapse
  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  // Клік поза елементом Navbar - закрити меню-бургер
  useOutsideClick(dropdownRef, handleNavCollapse, !isNavCollapsed);

  return (
    <>
      {
        localName ?
          <NavibarLogIn localName={localName} isNavCollapsed={isNavCollapsed} handleNavCollapse={handleNavCollapse} dropdownRef={dropdownRef} /> :
          <NavibarLogOut isNavCollapsed={isNavCollapsed} handleNavCollapse={handleNavCollapse} dropdownRef={dropdownRef} />
      }
    </>
  );
};

export default Navibar;