import React from 'react'
import { Button, Container } from 'react-bootstrap'

function Cabinet(props) {
    // console.log('props', props);
    return (
        <Container className="wrapper" >
            <h3>
                Особистий кабінет
            </h3>
            <ol style={{ listStyleType: 'none', textAlign: 'left' }}>
                <li>Логін: {props.data.username}</li>
                <li>Ім'я: {props.data.firstName}</li>
                <li>Прізвище: {props.data.lastName}</li>
                <li>Телефон: {props.data.phone}</li>
                <li>Email: {props.data.email}</li>
                <li>Адреса</li>
            </ol>
            <Button style={{ backgroundColor: "#78BEB3", borderRadius: "0", borderColor: "#2bd191be", color: "#fff" }} disabled>
                Редагувати дані
            </Button>
        </Container>
    )
}

export default Cabinet
