// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
    width: 80%;
    margin: 0 auto;
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
}

.login-formGroup {
    margin: 0 auto;
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-formGroup__control {
    width: 300px;
}

.login-alert {
    position: relative;
}

.login-alert__btn-close {
    border: 1px black solid;
    padding: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
}

.login-alert__btn-close:hover {
    background-color: red;
    color: #fff;
    font-weight: bold;
}

.login-link {
    /* color: #2bd191be; */
    color: var(--main-green-color);
    text-decoration: underline;
    font-size: 16px;
}

.login-link:hover, .login-link:active {
    cursor: pointer;
    color: #0a58ca;
}

.login-spinner__color {
    color: var(--main-green-color);
}

.login-alert__width {
    width: 600px;
}

@media (max-width: 650px) {
    .login-alert__width {
        width: 90%;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/login.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,8BAA8B;IAC9B,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,6BAA6B;IAC7B,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,UAAU;AACd;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,8BAA8B;IAC9B,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".login-form {\n    width: 80%;\n    margin: 0 auto;\n    /* background-color: yellow; */\n    display: flex;\n    flex-direction: column;\n}\n\n.login-formGroup {\n    margin: 0 auto;\n    /* background-color: green; */\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.login-formGroup__control {\n    width: 300px;\n}\n\n.login-alert {\n    position: relative;\n}\n\n.login-alert__btn-close {\n    border: 1px black solid;\n    padding: 0 5px;\n    border-radius: 5px;\n    cursor: pointer;\n    position: absolute;\n    top: 0px;\n    right: 0px;\n}\n\n.login-alert__btn-close:hover {\n    background-color: red;\n    color: #fff;\n    font-weight: bold;\n}\n\n.login-link {\n    /* color: #2bd191be; */\n    color: var(--main-green-color);\n    text-decoration: underline;\n    font-size: 16px;\n}\n\n.login-link:hover, .login-link:active {\n    cursor: pointer;\n    color: #0a58ca;\n}\n\n.login-spinner__color {\n    color: var(--main-green-color);\n}\n\n.login-alert__width {\n    width: 600px;\n}\n\n@media (max-width: 650px) {\n    .login-alert__width {\n        width: 90%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
