import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import AxiosInstance from '../../components/Axios';
import { Link, useLocation } from 'react-router-dom';

const LoginPage = () => {

    const [validated, setValidated] = useState(false);
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [myAlert, seyMyAlert] = useState(false);
    let location = useLocation();
    // Куди після логівання перенаправити
    const [redirectTo, setRedirectTo] = useState("/");

    useEffect(() => {
        // console.log('location login', location);
        if (location.state !== null) {
            setRedirectTo(location.state.redirectTo.pathname);
        }
    }, [location]);


    const handleSubmit = (event) => {
        event.preventDefault();

        let validation = false;
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            validation = true;
        };

        setValidated(true);

        if (validation) {
            const article = {
                username: login,
                password: password,
            };

            axios.post(process.env.REACT_APP_SERVER_URL + '/auth', article).then((response) => {
                // console.log(response);
                if (response.data.token != '') {
                    localStorage.setItem('user', response.data.token);

                    // let jwt_deecode = jwtDecode(response.data.token);
                    // localStorage.setItem('user_name', jwt_deecode.username);
                    // localStorage.setItem('user_exp', jwt_deecode.exp);

                    AxiosInstance.post('/users/me', {}, {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": `Bearer ${response.data.token}`,
                        },
                    }).then((response) => {
                        localStorage.setItem('user_data', JSON.stringify(response.data));
                        // window.location.replace("/");
                        if (response.status === 200) {
                            window.location.replace(redirectTo);
                        } else {
                            // alert("Помилка входу");
                        }
                    });
                }
                else {
                    // alert("Помилка входу");
                    seyMyAlert(true);
                }
            }).catch((e) => {
                if (e.code == 'ERR_NETWORK') {
                    // alert("Помилка");
                    seyMyAlert(true);
                }
            });;
        }
    };

    return (
        <div className="my-5">
            <Link to={`/registration`}>
                <Button className="mt-4 px-4 btn-rout" type="submit" color="primary">
                    Зареєструватись
                </Button>
            </Link>

            <div className="wrapper mt-4">
                <Form
                    className="login-form"
                    noValidate validated={validated}
                    onSubmit={handleSubmit}
                >
                    <Row className="mb-3 login-formGroup">
                        {
                            myAlert &&
                            <Alert className="login-formGroup__control login-alert" key="danger" variant="danger">
                                <span>Помилка входу</span>
                            </Alert>
                        }
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} className="login-formGroup"
                            // md="4"
                            controlId="validationLogin"
                        >
                            <Form.Label>Email*</Form.Label>
                            <Form.Control
                                className="login-formGroup__control"
                                type="text"
                                placeholder="Введіть логін"
                                // defaultValue="Admin"
                                value={login}
                                onChange={(e) => setLogin(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} className="login-formGroup"
                            // md="4"
                            controlId="validationPassword"
                        >
                            <Form.Label>Пароль*</Form.Label>
                            <Form.Control
                                className="login-formGroup__control"
                                type="password"
                                placeholder="Ввведіть пароль"
                                // defaultValue="Admin"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Row>
                    {/* <Row className="mb-3">
                        <Form.Group className="mb-3 login-formGroup">
                            <Form.Check
                                // required
                                label="зберегти дані"
                                // feedback="Перед надсиланням Ви повинні погодитися"
                                feedbackType="invalid"
                            />
                        </Form.Group>
                    </Row> */}
                    <Row className="mb-3">
                        <Col>
                            <Button className="px-4 btn-rout" type="submit" color="primary">
                                Увійти
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {/* <Link to="restore"> */}
                            <Link to={`/reset-password`} className="login-link">
                                {/* <Button className="px-5 btn-rout" color="primary"> */}
                                Забули пароль?
                                {/* </Button> */}
                            </Link>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default LoginPage;