import React, { useEffect, useState } from 'react';
import SearchField from '../../../components/Main/SearchField';
import { Col, Row } from 'react-bootstrap';
import isLoggedIn from '../../../myFunction/isLoggedIn';
import PromoImg from '../../../components/Main/PromoImg';
import NeedToRegister from '../../../components/Main/NeedToRegister';
import AccomodationOffersBussiness from '../../../components/Main/Business/accomodation-offers/AccomodationOffersBussiness';
import AxiosInstance from '../../../components/Axios';

// Сторінка /business/accomodation-offers
const AccomodationOffersBusinessPage = () => {

    const urlApi = '/pages';
    const synonym = "business/accomodation-offers";
    const page = 1;
    const [data, setData] = useState([]);
    // Виклик функції чи залогінений
    const isLogIn = isLoggedIn();
    // // title для Промо-заголовку
    // const [modifiedTitle, setModifiedTitle] = useState("");

    // Запит за даними
    useEffect(() => {
        AxiosInstance
            .get(`${urlApi}?page=${page}&synonym=${synonym}`).then((response) => {
                // console.log("response", response);
                setData(response.data['hydra:member']);
            });
    }, []);

    // // Для Промо-заголовку, щоб кожне слово було з нового ряка
    // useEffect(() => {
    //     if (data.length > 0) {
    //         setModifiedTitle(data[0].title.replace(/ /g, "<br />"));
    //     }
    // }, [data]);

    return (
        <>
            <Row className="business-promo__container">
                <Col className="business-promo__col-container">
                    <div className="business-promo__text-container">
                        <div className="business-promo__text">
                            {/* {
                                (data.length > 0) &&
                                <div className="" dangerouslySetInnerHTML={{ __html: modifiedTitle }}></div>
                            } */}

                            {
                                (data.length > 0) &&
                                <div className="" dangerouslySetInnerHTML={{ __html: data[0].title }}></div>
                            }
                        </div>
                    </div>
                </Col>
                <Col className="p-0">
                    {/* <PromoImg imgSrc="/images/business/still-life-supply-chain-representation.jpg" alt="Бізнес - пропозиції розміщення підприємств" /> */}
                    <PromoImg imgSrc="/images/images_16_9/Пропозиції розміщення підприємств.jpg" alt="Бізнес - пропозиції розміщення підприємств" />
                </Col>
            </Row>

            {/* <SearchField /> */}

            {/* Перевірка чи залогінений */}
            {
                isLogIn.isLogIn ?
                    // "Пропозиції розміщення"
                    <AccomodationOffersBussiness data={data} />
                    :
                    <NeedToRegister />

            }
        </>
    );
};

export default AccomodationOffersBusinessPage;