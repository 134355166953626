import React from 'react';
import PromoImg from '../../components/Main/PromoImg';
// import SearchField from '../../components/Main/SearchField';
import Vpo from '../../components/Main/VPO/Vpo';

// Сторінка /vpo
const VpoPage = () => {
    return (
        <>
            {/* <PromoImg imgSrc="/images/VPO/VPO_main.png" alt="VPO" /> */}
            <PromoImg imgSrc="/images/images_16_9/ВПО.jpg" alt="VPO" />
            {/* <SearchField /> */}
            <Vpo />
        </>
    );
};

export default VpoPage;