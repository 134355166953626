import React, { useEffect, useRef, useState } from 'react'
import LegalSupportForm from '../../Forms/LegalSupportForm'
import SupportBaner from './SupportBaner'
import Slider from '../../SliderInfo/Slider/Slider copy'
import ThanksForRequest from '../ThanksForRequest'
import AxiosInstance from '../../Axios'
const data = [
  {
    city: 'м. Львів',
    street: 'вул. Костя Левицького, 67',
    number: '09744322134',
    email: 'email@gmail.com',
    graph: 'Графік роботи: пн - чт з 9.00 до 18.00; пт з 9.00 до 16.00'
  },
  {
    city: 'м. Дрогобич',
    street: 'вул. 22 Січня, 28',
    number: '09744322134',
    email: 'email@gmail.com',
    graph: 'Графік роботи: пн - чт з 9.00 до 18.00; пт з 9.00 до 16.00'
  },
  {
    city: 'м. Стрий',
    street: 'вул. Тараса Шевченка, 59',
    number: '09744322134',
    email: 'email@gmail.com',
    graph: 'Графік роботи: пн - чт з 9.00 до 18.00; пт з 9.00 до 16.00'
  },
  {
    city: 'м. Городок',
    street: 'вул. Львівська, 1а',
    number: '09744322134',
    email: 'email@gmail.com',
    graph: 'Графік роботи: пн - чт з 9.00 до 18.00; пт з 9.00 до 16.00'
  },
  {
    city: 'м. Самбір',
    street: 'вул. Мазепи, 8',
    number: '09744322134',
    email: 'email@gmail.com',
    graph: 'Графік роботи: пн - чт з 9.00 до 18.00; пт з 9.00 до 16.00'
  },
  {
    city: 'м. Золочів',
    street: 'вул. Пачовського, 7',
    number: '09744322134',
    email: 'email@gmail.com',
    graph: 'Графік роботи: пн - чт з 9.00 до 18.00; пт з 9.00 до 16.00'
  }, {
    city: 'м. Перемишляни',
    street: 'вул. Омеляна Ковча, 7',
    number: '09744322134',
    email: 'email@gmail.com',
    graph: 'Графік роботи: пн - чт з 9.00 до 18.00; пт з 9.00 до 16.00'
  },
  {
    city: 'м. Кам\'янка-Бузька',
    street: 'вул. Незалежності,27',
    number: '09744322134',
    email: 'email@gmail.com',
    graph: 'Графік роботи: пн - чт з 9.00 до 18.00; пт з 9.00 до 16.00'
  },
  {
    city: 'м. Новояворівськ',
    street: 'вул. Шевченка, 1',
    number: '09744322134',
    email: 'email@gmail.com',
    graph: 'Графік роботи: пн - чт з 9.00 до 18.00; пт з 9.00 до 16.00'
  }
]
const SupportContent = () => {

  const [isSubmit, setIsSubmit] = useState(false);
  // Для відображення контактних даних, якщо хоча б раз була заповнена форма на Психолого-Юридичну підтримку
  const [isSentForPYSupport, setIsSentForPYSupport] = useState(false);
  const [nameSurname, setNameSurname] = useState('');
  // Для поля з текстом-подякою про успішне надсилання форми
  const thanksRef = useRef(null);
  const [user, setUser] = useState("");
  const tokenStr = localStorage.getItem('user');
  const configAll = {
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${tokenStr}`,
    }
  }

  // При старті сторінки взяти дані чи була заповнена форма на Психолого-Юридичну підтримку
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user_data'));
    // console.log('userData', userData);
    if (userData != undefined && userData.isSentForPYSupport) {
      setIsSentForPYSupport(userData.isSentForPYSupport);
      // setIsSubmit(true);
    }
    // if (userData != undefined && userData.first_name) {
    //   setNameSurname(userData.first_name);
    // }

    if (userData != undefined) {
      setUser(userData);
    }
  }, []);

  // Функція для відправлення інформації на user про заповнення форми підтримки
  const updateUresData = () => {
    if (user != undefined && user.id != undefined) {
      AxiosInstance.put(`/users/${user.id}`, { isSentForPYSupport: true }, configAll).then(() => {
        // console.log('Дані про користувача оновлено');
        // Додати для user що форма психолого-хридичої підтримки була заповнена
        setUser(user.isSentForPYSupport = true);
        // Оновлення localStorage зі зміненими даними
        localStorage.setItem('user_data', JSON.stringify(user));
      }).catch(() => {

      })
    }
  }

  // Після відправлення форми проскорлити екран, щоб поле з текстом-подякою про успішне надсилання форми було зверху екрана
  useEffect(() => {
    if (thanksRef.current) {
      thanksRef.current.scrollIntoView({ behavior: "smooth" });
    }

    // ВИклик функції для відправлення інформації на user про заповнення форми підтримки
    if (isSubmit) {
      updateUresData();
    }
  }, [isSubmit]);

  return (
    <>
      {
        !isSentForPYSupport &&
        <h3 className="support__text-h3">Отримайте контактні дані після заповнення</h3>
      }
      {/* Форма заявки на психолого-юридичну підтримку */}
      {
        !isSubmit && <LegalSupportForm setIsSubmit={setIsSubmit} className="main-content" setNameSurname={setNameSurname} setIsSentForPYSupport={setIsSentForPYSupport} />
      }

      <p className='forms-support_header-green mt-5'>
        Офіси при РВА з підтримки та надання допомоги внутрішньо переміщеним особам, які проживають у Львівській області (далі- ОП).
      </p>
      <p style={{ textAlign: 'left' }}>
        <strong>Функція:</strong> безоплатне надання психологічної, гуманітарної підтримки, юридичної консультації, Оформлення адресної та належних допомог, задоволення потреб у житлі, також допомога з працев лаштування, консультування щодо пенсійного забезпечення та належних соціальних виплат, освіт- ніх та медичних потреб для ВПО.<br />
        З метою задоволення потреб ВПО у житлі, ОП забезпечують скерування для поселення у заклади державної, комунальної власності або осіб приватного права (у разі їх згоди).
      </p>

      {/* Поле з текстом-подякою про успішне надсилання форми */}
      {
        isSubmit &&
        <div className="support_green_box px-5 my-5 main-content" ref={thanksRef}>
          <div className="support_green_box_inner">
            <ThanksForRequest nameSurname={nameSurname} />
          </div>
        </div>
      }

      {/* Відображати контактні дані, якщо хоча б раз була заповнена форма на Психолого-Юридичну підтримку */}
      {
        isSentForPYSupport &&
        <div className="my-5">
          <p style={{ margin: '0 auto', width: 'fit-content', fontWeight: '700' }}>Контактні дані ОП:</p>
          <hr className="support_hr-line" />
          <Slider className=''></Slider>
        </div>
      }

      {/* ОП співпрацюють з наступними міжнародними організаціями для потреб ВПО */}
      <SupportBaner />
    </>
  )
}

export default SupportContent;