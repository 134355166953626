import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function CabinetVPO({ data }) {
    return (
        <Container className="wrapper mb-4" >
            <div style={{ margin: '0 auto', width: 'fit-content' }}>
                <h3 className='my-4'>
                    Особистий кабінет
                </h3>
                <ol style={{ listStyleType: 'none', textAlign: 'left', lineHeight: "30px" }}>
                    {/* <li><span style={{ fontWeight: 'bold' }}>Логін:</span>&nbsp;{data.username}</li> */}
                    <li><span style={{ fontWeight: 'bold' }}>Email:</span>&nbsp;{data.email}</li>
                    <li><span style={{ fontWeight: 'bold' }}>Ім'я:</span>&nbsp;{data.firstName}</li>
                    <li><span style={{ fontWeight: 'bold' }}>Прізвище:</span>&nbsp;{data.lastName}</li>
                    <li><span style={{ fontWeight: 'bold' }}>Телефон:</span>&nbsp;{data.phone}</li>
                    <li><span style={{ fontWeight: 'bold' }}>Актуальна адреса проживання:</span>&nbsp;{data.address}</li>
                    {/* <li><span style={{ fontWeight: 'bold' }}>Область з якої переїхали:</span>&nbsp;{data.regionFrom}</li> */}
                    <li><span style={{ fontWeight: 'bold' }}>Область з якої переїхали:</span>&nbsp;{data.addressFrom}</li>
                    <li><span style={{ fontWeight: 'bold' }}>Зайнятість:</span>&nbsp;{data.employed}</li>
                    <li><span style={{ fontWeight: 'bold' }}>Вік:</span>&nbsp;{data.age}</li>
                    <li><span style={{ fontWeight: 'bold' }}>Стать:</span>&nbsp;{data.gender}</li>
                    <li><span style={{ fontWeight: 'bold' }}>Діючий Роботодавець:</span>&nbsp;{data.currentDirector}</li>
                    <li><span style={{ fontWeight: 'bold' }}>Попередній Роботодавець:</span>&nbsp;{data.exDirector}</li>
                    <li><span style={{ fontWeight: 'bold' }}>Чи користуєтесь іншими програмами:</span>&nbsp;{data.anotherProgram}</li>
                    {/* <li><span style={{ fontWeight: 'bold' }}>Необхідна підтримка:</span>&nbsp;{(data.supportType == 0 || data.supportType.length == 0) ?  "Ні" : data.supportType}</li> */}
                    <li><span style={{ fontWeight: 'bold' }}>Необхідна підтримка:</span>&nbsp;{data.supportType}</li>

                </ol>
            </div>
            <Link to={`/profile/update/${data.id}`}>
                <Button
                    className='btn-rout '
                // className='btn-disabled my-3'
                // style={{ backgroundColor: "#78BEB3", borderRadius: "0", borderColor: "#2bd191be", color: "#fff" }} disabled
                >
                    Редагувати дані
                </Button>
            </Link>
        </Container >
    )
}

export default CabinetVPO
