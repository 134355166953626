import React, { useState } from "react";

import { ReactComponent as LongArrow } from "../Icons/longArrow.svg";
import { ReactComponent as ArrowLeft } from "../Icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../Icons/arrowRight.svg";
import { ReactComponent as CubePagination } from "../Icons/cubePagination.svg";
import { ReactComponent as PaginationCubeActive } from "../Icons/paginationCubeActive.svg";
import { ReactComponent as PaginationBorder } from "../Icons/paginationBorder.svg";
import SupportContact from "../../Main/Support/SupportContact";
import { useMediaQuery } from 'react-responsive'

const Slider = () => {
  const slides = [
    {
      city: 'м. Львів',
      street: 'вул. Костя Левицького, 67',
      number: '+38 (050) 555-44-61',
      email: 'centrsplviv@gmail.com',
      graph: 'Графік роботи: пн – чт з 9.00 до 18.00; пт з 9.00 до 16.00'
    },
    {
      city: 'м. Дрогобич',
      street: 'вул. 22 Січня, 28',
      number: '+38 (067) 471-02-45',
      email: '',
      graph: 'Графік роботи: пн – пт з 10.00 до 17.00'
    },
    {
      city: 'м. Стрий',
      street: 'вул. Тараса Шевченка, 59',
      number: '+38 (067) 471-01-56',
      email: '',
      graph: 'Графік роботи: пн – пт з 10.00 до 17.00; 13:14 обідня перерва'
    },
    {
      city: 'м. Городок',
      street: 'вул. Львівська, 1а',
      number: '+38 (067) 471-07-19',
      email: '',
      graph: 'Графік роботи: пн – пт з 10.00 до 17.00; 13:14 обідня перерва;'
    },
    {
      city: 'м. Самбір',
      street: 'вул. Мазепи, 8',
      number: '+38 (067) 471-02-98',
      email: '',
      graph: 'Графік роботи: пн – пт з 10.00 до 17.00; '
    },
    {
      city: 'м. Золочів',
      street: 'вул. Пачовського, 7',
      number: '+38 (067) 471-03-29',
      email: '',
      graph: 'Графік роботи: пн – пт з 10.00 до 17.00'
    }, {
      city: 'м. Городок',
      street: 'вул. Львівська, 1а',
      number: '+38 (067) 471-07-19',
      email: '',
      graph: 'Графік роботи: пн – пт з 10.00 до 17.00; 13:14 обідня перерва;'
    },
    {
      city: 'м. Кам\'янка-Бузька',
      street: 'вул. Незалежності,27',
      number: '+38 (067) 471-00-83',
      email: '',
      graph: 'Графік роботи: пн – чт з 9.00 до 18.00; пт з 9.00 до 16.45; 13:14 обідня перерва;'
    },
    {
      city: 'м. Новояворівськ',
      street: 'вул. Шевченка, 1',
      number: '+38 (067) 471-06-30',
      email: '',
      graph: 'Графік роботи: пн – пт з 09.00 до 17.00;'
    },
    {
      city: 'м. Львів',
      street: 'вул. Костя Левицького, 67',
      number: '+38 (050) 555-44-61',
      email: 'centrsplviv@gmail.com',
      graph: 'Графік роботи: пн – чт з 9.00 до 18.00; пт з 9.00 до 16.00'
    }
  ]
  const slidesPerPage = 3;
  const totalSlides = slides.length;
  // const initialIndex = Math.floor((totalSlides - slidesPerPage) / 2);
  const initialIndex = 1

  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const isPhone = useMediaQuery({ query: '(max-width: 1024px)' })
  const handlePrev = () => {
    if (currentIndex === 0) {
      setCurrentIndex(totalSlides);
    }

    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const handleNext = () => {
    if (currentIndex + 1 == totalSlides) {
      setCurrentIndex(0);
    }
    // if (currentIndex + slidesPerPage === totalSlides - 1) {
    //   return;
    // }

    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  // const itemWidth = 10;
  // const marginItem = 5.95; // компенсаціє маргінів активного елемента
  // const paginationItemWidth = itemWidth + marginItem;

  return (
    <div className="slider_container">
      <div className="contactsWrapper">

        {/* слайдер */}
        <div className={"slider"}>
          {/* кнопки */}


          {/* слайдер карусель */}
          {!isPhone ?
            <div className="slidesContainer">
              <div className="wrapperContPag">
                <ul
                  className="slidesInnerContainer"
                  style={{ transform: `translateX(-${currentIndex * 250}px)` }}
                >

                  {slides.map((item, index) => (

                    <li key={index} className="slide">

                      {index == currentIndex ?
                        <div className="active_slide">
                          <ArrowLeft className="prevArrow" onClick={handlePrev} />
                          <SupportContact data={item} id='slide_activ' />
                          <ArrowRight className="nextArrow" onClick={handleNext} />
                        </div>
                        :
                        <h3 style={{ color: 'var(--main-green-color)', fontSize: '17px' }}>{item.city}</h3>
                      }

                    </li>
                  ))}

                </ul>
              </div>
            </div> :

            <div className="slidesContainer" >
              <div style={{ width: '300px', margin: '0 auto' }} className="wrapperContPag">
                <ul
                  className="slidesInnerContainer"
                  style={{
                    transform: `translateX(-${currentIndex * 250
                      }px)`,
                    left: '-15px'


                  }}
                >

                  {slides.map((item, index) => (

                    <li key={index} className="slide">

                      {index == currentIndex ?
                        <div className="active_slide">
                          <ArrowLeft className="prevArrow" onClick={handlePrev} style={{ zIndex: '5' }} />
                          <SupportContact data={item} id='slide_activ' />
                          <ArrowRight className="nextArrow" onClick={handleNext} />
                        </div>
                        :
                        <h3 style={{ color: '#3AC9B5', fontSize: '17px' }}>{item.city}</h3>
                      }

                    </li>

                  ))}

                </ul>
              </div>
            </div>
          }

          {/* пагінація */}
          {/* <div className="pagination">
       

          <PaginationBorder className="pagination_border" />

          <div
            className="pagination_dots"
            style={{
              // transform: `translateX(-${currentIndex * (100 / totalSlides)}%)`,
              transform: `translateX(-${ paginationItemWidth * currentIndex}px)`,
            }}
          >
           
            {Array.from({ length: totalSlides }).map((_, index) => (
              <div
                key={index}
                className={`"dot" ${
                  index === currentIndex ? "activeDot" : ""
                }`}
                onClick={() => setCurrentIndex(index)}
              >
                {index === currentIndex ? (
                  <PaginationCubeActive />
                ) : (
                  <CubePagination />
                )}
              </div>
            ))}
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Slider;
