import React, { useEffect, useState } from 'react';

// Функція для перевірки чи залогінений
const isLoggedIn = () => {

    const [isLogIn, setIsLogIn] = useState(false);

    // --- Start --- Діставання з localStorage імені залогіненого користувача з затримкою в часі, щоб localStorage встиг прогузитися
    const [start, setStart] = useState(true);
    const [localName, setLocalName] = useState('');

    // Запит на localStorage чи залогінений
    async function getDataStorage() {
        let local = await localStorage.getItem('user_data');
        // console.log("localStorage", local);
        if (local != null && local != '') {
            setLocalName(window.JSON.parse(local));
        }
        setStart(false);
        return local;
    };

    // При старті сторінки зробити запит на localStorage чи залогінений
    useEffect(() => {
        if (start) {
            getDataStorage();
        } else return
    });
    // --- End ---

    // Відслідковувати чи залогінений
    useEffect(() => {
        // console.log('localName', localName);
        if (localName != null && localName != '') {
            setIsLogIn(true);
        } else {
            setIsLogIn(false);
        }
    }, [localName]);

    return {
        isLogIn
    };
};

export default isLoggedIn;