import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import AxiosInstance from '../../components/Axios';
import { Link, useLocation } from 'react-router-dom';

const Restore = () => {


    return (
        <div className="my-5">
            <div className="wrapper mt-4">
                <Form
                    className="login-form"
                >
                    <Row className="mb-3">
                        <Form.Group as={Col} className="login-formGroup"
                            // md="4"
                            controlId="validationLogin"
                        >
                            <Form.Label>Логін*</Form.Label>
                            <Form.Control
                                className="login-formGroup__control"
                                type="text"
                                placeholder="Введіть логін"
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} className="login-formGroup"
                            // md="4"
                            controlId="validationPassword"
                        >
                            <Form.Label>Електронна пошта*</Form.Label>
                            <Form.Control
                                className="login-formGroup__control"
                                placeholder="Ввведіть пошту"
                            
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} className="login-formGroup"
                            controlId="validationPassword"
                        >
                            <Form.Label>Номер телефону*</Form.Label>
                            <Form.Control
                                className="login-formGroup__control"
                                placeholder="Ввведіть номер телефону"
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="px-4 btn-rout" type="submit" color="primary">
                                Відновити пароль
                            </Button>
                            <Link to= "/login">
                                <Button className="px-4 mx-5" style={{borderRadius: '0'}} type="submit" variant="secondary">
                                    Повернутись
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default Restore;