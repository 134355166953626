import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CurrentProgram_1 = () => {
    return (
        <Container className="my-4 wrapper">
            <h2 className="currentPrograms__title">Державна Компенсація витрат роботодавцю на оплату праці за працевлаштування ВПО (онлайн послуга на порталі Дія):</h2>

            <p className="my-4 currentPrograms__text">
                Надає можливість отримання роботодавцем компенсації упродовж 2-х місяців з дня працевлаштування ВПО (за направленням центру зайнятості) на умовах строкового трудового договору. Розмір компенсації - по 6,7 тис грн упродовж 2 місяців (http://surl.li/jqwzc).
            </p>

            {/* <Link to="#">
                <Button className="btn-rout btn-disabled">Ознайомитись</Button>
            </Link> */}
        </Container >
    );
};

export default CurrentProgram_1;