import React, { useEffect, useState } from 'react';

// Подяка за звернення (після заповнення форми)
const ThanksForRequest = (props) => {
    const text_1 = "Найближчим часом через електронну пошту - або на вказаний Вами номер телефону зв'яжеться працівник обраного Вами центру підтримки!"
    const text_2 = "Найближчим часом через електронну пошту - або на вказаний Вами номер телефону зв'яжеться наш працівник!"
    const [text, setText] = useState(text_1);

    useEffect(() => {
        if (props.page === "business/relocation-request" || props.page === "business/mentoring-program") {
            setText(text_2);
        } else {
            setText(text_1);
        }
    }, [props]);

    return (
        <>
            <h3>Шановний {props.nameSurname}.</h3>
            <h3>Дякуємо за звернення!</h3>
            {/* <h4>
                Найближчим часом через електронну пошту - або на
                вказаний Вами номер телефону зв'яжеться працівник обраного Вами центру підтримки!
            </h4> */}
            <h4>{text}</h4>
        </>
    );
};

export default ThanksForRequest;