import React, { Fragment } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// Для сторінок /business/list-of-programs та /vpo/list-of-programs
const ListOfPrograms = (props) => {
    // console.log('ListOfPrograms props', props);
    let promo = null;

    if (props.title == 'Перелік програм підтримки ВПО') {
        promo =
            <div className="listofPrograms_promoVpo">
                <a className="me-3" href="https://edopomoga.gov.ua/" target="blank" style={{ textDecoration: 'none' }}>
                    <img src="/images/VPO/VPOpromo.jpg" alt="" style={{ height: "100px" }} />
                </a>
                <div className="promoVPO_text">
                    <h3><a href="https://edopomoga.gov.ua/" target="blank" style={{ textDecoration: 'none' }}>edopomoga.gov.ua</a></h3>
                    <p>Тут ви можете отримати інформацію про те, як отримати допомогу на проживання ВПО від держави, як підтримати українські сім'ї речами першої необхідності, та як звернутись за допомогою до благодійників-волонтерів, оформивши заявку</p>
                </div>
            </div >
    };

    return (
        <Container className="my-4 wrapper">
            {promo}
            <h2 className="my-md-5 my-sm-4 listOfPrograms__title">{props.title}</h2>

            {
                (props.data !== undefined) &&
                props.data.map((program, index) => {
                    return (
                        <Fragment key={program.id}>
                            <Row>
                                <Col className="col-md-9 col-sm-8 col-12 listOfPrograms__text">
                                    <span dangerouslySetInnerHTML={{ __html: program.name }}></span>
                                </Col>
                                <Col className="col-md-3 col-sm-4 col-12 listOfPrograms__button-container">
                                    <Link to={`${program.id}`} className="listOfPrograms__button-link">
                                        {/* <Button className={`btn-rout listOfPrograms__button-btn ${(program.link === "#" || program.link === undefined) ? "btn-disabled" : ""}`}> */}
                                        <Button className="btn-rout listOfPrograms__button-btn">
                                            Ознайомитись
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>

                            {index !== props.data.length - 1 && <hr />}
                        </Fragment>
                    )
                })
            }
        </Container>
    );
};

export default ListOfPrograms;