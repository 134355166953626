import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';

function FeedbackFormLayout({setForm}) {

    const [selectedOption, setSelectedOption] = useState(null);

    const handleCheckboxClick = (value) => {
        if (selectedOption === value) {
            setSelectedOption(null);
        } else {
            setSelectedOption(value);
        }
    };

    useEffect(() => {
        setForm(selectedOption)
    }, [selectedOption])
    let content = 
    <Form className='forms-feedback_form' noValidate autoComplete='off' >
        <Form.Label style={{ float: 'left' }}>  <span className='forms_red_star'>*</span> Тип звернення:</Form.Label> <br />
        <div className="check_boxes" id='feedback_row_check' >
            <Form.Check
                id='castom_check'
                checked={selectedOption === 'ВПО'}
                onClick={() => handleCheckboxClick('ВПО')}
                label='ВПО'
                value='ВПО'
            >
            </Form.Check>
            <Form.Check
                id='castom_check0'
                checked={selectedOption === 'Бізнес'}
                onClick={() => handleCheckboxClick('Бізнес')}
                label='Бізнес'
                value='Бізнес'
            >
            </Form.Check>
        </div>
    </Form>
  return (
    <div>
    {
        selectedOption == null ? content :
        null
    }
    </div>
    
  )
}

export default FeedbackFormLayout
