import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AccomodationOffersBussiness = (props) => {
    return (
        <Container className="my-sm-5 wrapper accomodation">
            {
                (props.data.length > 0) &&
                <div dangerouslySetInnerHTML={{ __html: props.data[0].content }}></div>
            }

            <Row className="my-5 accomodation-inner">
                <Col className="col-6">
                    <div className="accomodation-container right">
                        <Link to="http://apl.loda.gov.ua" className="mb-3 business-accomodationOffers__link" target="_blank">apl.loda.gov.ua</Link>
                        {/* <Image className="portal-img" src="/images/business/apl.loda.gov.ua.png" alt="Бізнес - apl.loda.gov.ua" width="400px" /> */}
                        <Image className="portal-img" src="/images/Business/apl.loda.gov.ua.png" alt="Бізнес - apl.loda.gov.ua" width="400px" />
                    </div>
                </Col>
                <Col className="col-6">
                    <div className="accomodation-container left">
                        <Link to="http://investhub.lviv.ua" className="mb-3 business-accomodationOffers__link" target="_blank">investhub.lviv.ua</Link>
                        {/* <Image className="" src="/images/business/investhub.lvivua.png" alt="Бізнес - investhub.lviv.ua" width="400px" /> */}
                        <Image className="" src="/images/Business/investhub.lvivua.png" alt="Бізнес - investhub.lviv.ua" width="400px" />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default AccomodationOffersBussiness;