import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import MentoringForm from '../Forms/MentoringForm';
import LegalSupportForm from '../Forms/LegalSupportForm';
import { useForm } from 'react-hook-form';
import AxiosInstance from '../Axios';
import axios from 'axios';
import Capcha from './capcha/Capcha';

function RegistrationBusiness({ userType }) {
    const [selectedComponent, setSelectedComponent] = useState(null);
    const button = useRef(null);
    const [values, setValues] = useState({});
    const [capcha, setCapcha] = useState(false);
    const [openCapcha, setOpenCapcha] = useState(false);
    const [userAlert, setUserAlert] = useState(null);
    let sendData;
    const regions = ["Вінницька", "Волинська", "Дніпропетровська", "Донецька", "Житомирська", "Закарпатська", "Запорізька", "Івано-Франківська", "Київська", "Кіровоградська", "Луганська", "Львівська", "Миколаївська", "Одеська", "Полтавська", "Рівненська", "Сумська", "Тернопільська", "Харківська", "Херсонська", "Хмельницька", "Черкаська", "Чернівецька", "Чернігівська", "Автономна Республіка Крим"];
    // Для капчі
    const capchaRef = useRef(null);

    const { register, handleSubmit, formState, watch } = useForm({
        values: {
            firstName: '',
            phone: '',
            username: '',
            email: '',
            plainPassword: '',
            confirmPassword: '',
            regionFrom: '',
            numberFOP: '',
            codeEDRPOU: '',
            grantSupport: '',
            companyName: '',
        }
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    // Коли відобразиється капча проскорлити екран, щоб капча була зверху екрана
    useEffect(() => {
        if (capchaRef.current) {
            capchaRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [openCapcha]);

    function sendForm() {

        button.current.disabled = true;
        const configAll = { headers: { 'content-type': 'application/json', } }

        // додав до обєкту roles i active
        const article = {
            ...sendData,
            roles: [
                "ROLE_USER"
            ],
            active: "on",
            username: sendData.email,
            userType: userType
        }
        // прибрав confirmPassword з обєкуту для сервера 
        const { confirmPassword, ...changedArticle } = article;

        // console.log('article', changedArticle);

        AxiosInstance.post('/users', changedArticle, configAll).then((response) => {
            // console.log('post /users response', response)

            if (response.status == 201) {
                window.location.replace("/login");
            }

        }).catch((error) => {
            setUserAlert(<Alert style={{ marginTop: '1rem' }} variant={'danger'}>
                Такий логін вже існує
            </Alert>);
            button.current.disabled = false;
        })

    };

    const handleOnSubmit = (data) => {
        setOpenCapcha(true)
        //setCapcha(true);

        sendData = data;
        if (capcha) {
            sendForm();
        }
        // console.log(JSON.stringify(data, null, 2))
    }

    useEffect(() => {
        if (openCapcha) {
            if (!capcha) {
                button.current.disabled = true;
            }
            else {
                button.current.disabled = false
            }
        }
    }, [capcha, openCapcha])

    const password = watch('plainPassword');
    return (
        <div className='main-container'>
            <Form className='profile-registration_buisness'>
                {/*  */}
                <Row id='feedback_row'>
                    {/* <Col className='profile-registration_buisness_col' >
                        <Form.Label>Логін</Form.Label>
                        <Form.Control

                            onChange={handleInputChange}
                            {...register('username', { required: 'is reqired!', minLength: { value: 5, message: 'length error' } })}
                            className={formState.errors.username !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.username && formState.errors.username.type === "required" && <span>Це обов'язкове поле!</span>}
                        {formState.errors.username && formState.errors.username.type === "minLength" && <span>Мінімальна довжина 5 символів!</span>}
                    </Col> */}
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Електронна пошта</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            {...register('email', { required: 'is reqired!', pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                            //{...register('username', {  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                            className={formState.errors.email !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.email && formState.errors.email.type === "required" && <span>Це обов'язкове поле</span>}
                        {formState.errors.email && formState.errors.email.type === "pattern" && <span>Перевірте емейл</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Номер телефону</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            className={formState.errors.phone !== undefined && 'forms-invalid'}
                            {...register('phone', { minLength: 10, maxLength: 12 })}
                        >
                        </Form.Control>
                        {formState.errors.phone && formState.errors.phone.type === "minLength" && <span>Перевірте номер!</span>}
                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label>Пароль</Form.Label>
                        <Form.Control
                            type='password'
                            onChange={handleInputChange}
                            {...register('plainPassword', { required: 'is reqired!', minLength: 5 })}
                            className={formState.errors.plainPassword !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.plainPassword && formState.errors.plainPassword.type === "required" && <span>Це обов'язкове поле!</span>}
                        {formState.errors.plainPassword && formState.errors.plainPassword.type === "minLength" && <span>Мінімальна довжина 5 символи!</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Повторіть пароль</Form.Label>
                        <Form.Control
                            type='password'
                            onChange={handleInputChange}
                            {...register('confirmPassword', {
                                required: 'is reqired!', minLength: 5,
                                validate: (value) => value == password || 'паролі не збігаються'
                            })}
                            className={formState.errors.confirmPassword !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.confirmPassword && formState.errors.confirmPassword.type === "required" && <span>Це обов'язкове поле</span>}
                        {formState.errors.confirmPassword && formState.errors.confirmPassword.type === "validate" && <span>паролі не збігаються</span>}
                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label>Ім'я контактної особи</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            placeholder="Введіть ім'я"
                            {...register('firstName', { required: 'is required' })}
                            className={formState.errors.firstName !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.firstName && formState.errors.firstName.type === "required" && <span>Це обов'язкове поле</span>}

                    </Col>

                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Область з якої переїхали</Form.Label>
                        <Form.Select
                            onChange={handleInputChange}
                            placeholder='Обрати'
                            {...register('regionFrom', { required: 'is reqired!' })}
                            className={formState.errors.regionFrom !== undefined && 'forms-invalid'}
                        >
                            <option disabled>Область з якої переїхали</option>
                            {regions.map(e => {
                                return <option value={e} key={e} >{e}</option>
                            })}
                        </Form.Select>
                        {formState.errors.regionFrom && formState.errors.regionFrom.type === "required" && <span>Це обов'язкове поле</span>}
                    </Col>

                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label style={{ textAlign: 'left' }}> Податковий номер платника податків (ФОПа)</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            {...register('numberFOP', { required: 'is reqired!', minLength: 10, maxLength: 10 })}
                            className={formState.errors.numberFOP !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.numberFOP && formState.errors.numberFOP.type === "required" && <span>Це обов'язкове поле!</span>}
                        {formState.errors.numberFOP && formState.errors.numberFOP.type === "minLength" && <span>Мінімальна довжина 10 символів!</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Код ЄДРПОУ</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            placeholder='Введіть код ЄДРПОУ'
                            {...register('codeEDRPOU', { required: 'is reqired!', minLength: 8, maxLength: 8 })}
                            className={formState.errors.codeEDRPOU !== undefined && 'forms-invalid'}
                        >
                        </Form.Control>
                        {formState.errors.codeEDRPOU && formState.errors.codeEDRPOU.type === "required" && <span>Це обов'язкове поле!</span>}
                        {formState.errors.codeEDRPOU && formState.errors.codeEDRPOU.type === "minLength" && <span>Мінімальна довжина 8 символів!</span>}
                    </Col>
                </Row>
                <Row id='feedback_row'>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label style={{ textAlign: 'left' }}> Чи користувались грантовою підтримкою бізнесу?</Form.Label>
                        <Form.Select
                            onChange={handleInputChange}
                            placeholder='Обрати (Так-Так, перелік грантів? - Ні)'
                            {...register('grantSupport', { required: 'is reqired!' })}
                            className={formState.errors.grantSupport !== undefined && 'forms-invalid'}
                        >
                            <option disabled>Обрати Так-Ні</option>
                            <option>Так</option>
                            <option>Ні</option>
                        </Form.Select>
                        {formState.errors.grantSupport && formState.errors.grantSupport.type === "required" && <span>Це обов'язкове поле!</span>}
                    </Col>
                    <Col className='profile-registration_buisness_col'>
                        <Form.Label> Назва компанії</Form.Label>
                        <Form.Control
                            onChange={handleInputChange}
                            {...register('companyName', { required: 'is reqired!' })}
                            className={formState.errors.companyName !== undefined && 'forms-invalid'}
                        ></Form.Control>
                        {formState.errors.companyName && formState.errors.companyName.type === "required" && <span>Це обов'язкове поле!</span>}
                    </Col>

                </Row>

                {/* <h5 style={{marginTop: '2rem'}} >Мені необхідна/я шукаю</h5>  */}
                {/* <h5 className='my-5' >Мені необхідна/я шукаю</h5> 
        <div className="registration-buisness_radio">
               
                <Form.Check 
                    label = 'Матеріальна підтримка'
                    name = 'component'
                    value= 'material'
                    checked={selectedComponent === 'material'}
                    onChange={() => setSelectedComponent('material')}></Form.Check>
                <Form.Check 
                    label = 'Юридична підтримка'
                    name = 'component'
                    value= 'law'
                    checked={selectedComponent === 'law'}
                    onChange={() => setSelectedComponent('law')}></Form.Check>
                <Form.Check 
                    label = 'Психологічна підтримка'
                    name = 'component'
                    value= 'psycological'
                    checked={selectedComponent === 'psycological'}
                    onChange={() => setSelectedComponent('psycological')}></Form.Check>
               
            
        </div> */}
                {/* {selectedComponent === 'material' && <></> } */}
                {selectedComponent === 'law' && <LegalSupportForm />}

                {selectedComponent === 'psycological' && <LegalSupportForm />}

                {
                    openCapcha &&
                    <div className='capcha_wrapper' ref={capchaRef}>
                        <Capcha setCapcha={setCapcha} />
                        {!capcha && <span>Введіть правильне значення. Заповнювати маленькими буквами</span>}
                    </div>
                }
                {userAlert}
                <Button className='btn-rout registration_btn' type='submit' onClick={handleSubmit(handleOnSubmit)} ref={button}>Зареєструватись</Button>
            </Form>
        </div>
    )
}

export default RegistrationBusiness
