import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import axios from 'axios';
import jwtDecode from 'jwt-decode';
// import AxiosInstance from '../../components/Axios';
import { Link, useLocation } from 'react-router-dom';
import MySpinner from '../../store/mySpinner';

// Компонент для заповнення форми для посилання на пошту
const SendRequestToEmail = () => {

    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState("");
    const [myAlert, setMyAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [isRequest, setIsRequest] = useState(false);

    const messageFail = "Користувача з даною електронною поштою не знайдено!";
    const messageSuccess = "Вам на пошту відправлено мейл!";

    const textSuccess = 'На вказану Вами пошту відправлено лист. Для відновлення паролю перейдіть за посиланням.';
    const textFail = 'Користувача з даною поштою не знайдено!';


    const handleSubmit = (event) => {
        event.preventDefault();

        let validation = false;
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            validation = true;
        };

        setValidated(true);

        if (validation) {
            const article = {
                email: email,
            };

            // console.log('article', article);

            // Ввімкнути спінер
            setIsRequest(true);

            axios.post(process.env.REACT_APP_SERVER_URL + '/api/user/reset_pasword', article).then((response) => {
                // console.log('response', response);
                if (response.status === 200) {
                    setIsRequest(false);
                    // alert("На вказану вами пошту відправлено мейл! Перейдіть для відновлення паролю.");
                    if (response.data.message === messageSuccess) {
                        setAlertText(textSuccess);
                    } else {
                        setAlertText(textFail);
                    }
                    setMyAlert(true);
                } else {
                    setAlertText("Помилка сервера");
                }
            });
        }
    };

    return (
        <div>
            <div className="my-5">
                <div className="wrapper mt-4">
                    {/* <h2 className="mb-4">Відновити пароль</h2> */}
                    <h2 className="">Відновити пароль</h2>

                    {
                        !isRequest ?
                            <>
                                <Form
                                    className="login-form"
                                    noValidate validated={validated}
                                    onSubmit={handleSubmit}
                                >

                                    {/* <Row className="mb-3 login-formGroup"> */}
                                    <Row className="my-3 login-formGroup">
                                        {
                                            myAlert &&
                                            <Alert className="login-formGroup__control login-alert login-alert__width" key="info" variant="info">
                                                <div>{alertText}</div>
                                            </Alert>
                                        }
                                    </Row>

                                    {
                                        !myAlert &&
                                        <>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} className="login-formGroup"
                                                    // md="4"
                                                    controlId="validationPassword"
                                                >
                                                    <Form.Label>Електронна пошта*</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        className="login-formGroup__control"
                                                        placeholder="Ввведіть пошту"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        // pattern="^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$"
                                                        pattern="^\w+[\w.-]*@\w+[\w.-]*\.[a-zA-Z]{2,3}$"
                                                        title="Будь ласка, введіть коректний Email у форматі mail@gmail.com і тільки латинськими літерами"
                                                        required
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col>
                                                    <Button className="px-4 btn-rout" type="submit" color="primary">
                                                        Відновити пароль
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                    }


                                </Form>

                            </>
                            :
                            <div className="my-4">
                                <MySpinner />
                            </div>
                    }

                    {/* <Row className="m-3"> */}
                    <Row className="">
                        <Col>
                            <Link to="/login" className="login-link">
                                {/* <Button className="px-4 btn-rout" color="primary"> */}
                                Повернутись
                                {/* </Button> */}
                            </Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
};

export default SendRequestToEmail;