import React, { useEffect, useState } from 'react';
import Cabinet from '../../components/Profile/Cabinet';
import AxiosInstance from '../../components/Axios';
import CabinetBusiness from '../../components/Profile/CabinetBusiness';
import CabinetLviv from '../../components/Profile/CabinetLviv';
import CabinetVPO from '../../components/Profile/CabinetVPO';

const ProfilePage = () => {

    const initialData = {
        username: "",
        firstName: "",
        lastName: "",
        // roles: [],
        phone: "",
        email: ""
    }

    const urlApi = '/users';
    const [data, setData] = useState(initialData);
    const userData = JSON.parse(localStorage.getItem('user_data'));


    // useEffect(() => {
    //     console.log('data', data);
    // }, [data]);

    // Запит за даними користувача
    useEffect(() => {
        if (userData != undefined) {
            // console.log('userData.id', userData.id);
            AxiosInstance.get(`${urlApi}/${userData.id}`).then((response) => {
                 //console.log('response', response);
                if (response.status === 200) {
                    setData(response.data);
                }
            })
        }
    }, []);


    return (
        <div className='mt-3'>
            {
                data.userType == "business" &&
                <CabinetBusiness data={data} />
            }
            {
                (data.userType == "Lviv" || data.userType === undefined) &&
                <CabinetLviv data={data} />
            }
            {
                data.userType == "Vpo" &&
                <CabinetVPO data={data} />
            }
        </div>
    );
};

export default ProfilePage;