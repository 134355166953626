import React from 'react';
import SearchField from '../../components/Main/SearchField';
import PromoImg from '../../components/Main/PromoImg';
import { Col, Row } from 'react-bootstrap';
import SupportContainer from '../../components/Main/Support/SupportContainer';

// Сторінка /support
const SupportPage = () => {
    return (
        <>
            <Row className="business-promo__container">
                <Col className="business-promo__col-container">
                    <div className="business-promo__text-container">
                        <div className="business-promo__text">
                            {/* <span>Психолого-</span><br />
                            <span>юридична</span><br />
                            <span>підтримка</span> */}
                            <span>Психолого-юридична підтримка</span>
                        </div>
                    </div>
                </Col>
                <Col className="p-0">
                    {/* <PromoImg imgSrc="/images/Support/Support_main.png" alt="Підтримка" /> */}
                    <PromoImg imgSrc="/images/images_16_9/Підтримка.jpg" alt="Підтримка" />
                </Col>
            </Row>

            {/* <SearchField /> */}

            <SupportContainer />
        </>
    );
};

export default SupportPage;