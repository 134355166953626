import React from 'react';
import { Link, useLocation } from 'react-router-dom';

// Показується при перегляді деяких сторінок якщо незалогінений
const NeedToRegister = (props) => {

    // Текст повідомлення
    let text = (props.text !== undefined) ? props.text : "Для ознайомлення з розділом необхідно";
    const location = useLocation();
    // console.log('location NeedToRegistaer', location);

    return (
        <div className="needToRegister__container">
            <div className="needToRegister__message-container">
                <p>{text}</p>
                <Link className="needToRegister__link" to={`/login`} state={{ redirectTo: location }}>
                    Зареєструватись
                </Link>
            </div>
        </div>
    );
};

export default NeedToRegister;