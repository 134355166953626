import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import FeedbackForm from '../Forms/FeedbackForm';
import ApiForm from '../Forms/TestApiForm';
import ThanksForRequest from './ThanksForRequest';
import FeedbackFormLayout from '../Forms/FeedbackFormLayout';
import FeedbackFormVPO from '../Forms/FeedbackFormVPO';

const Main = ({ data }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [imageURL, setImageURL] = useState('/images/chat_01.png');
    const [form, setForm] = useState(null)
    // Для поля з текстом-подякою про успішне надсилання форми
    const [nameSurname, setNameSurname] = useState('');
    // Для поля з текстом-подякою про успішне надсилання форми
    const thanksRef = useRef(null);

    const openForm = (e) => {
        e.target.classList.toggle('active');
        setIsOpen(!isOpen);
        setImageURL('/images/chat_02.png');
        setIsSubmit(false);
        if (isOpen) {
            setImageURL('/images/chat_01.png');
        };
    };

    // Після відправлення форми проскорлити екран, щоб поле з текстом-подякою про успішне надсилання форми було зверху екрана
    useEffect(() => {
        if (thanksRef.current) {
            thanksRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [isSubmit]);

    return (
        <>
        <Container className="my-5 wrapper">
            {/* <div className="main-content"> */}
            <div style={{ textAlign: "justify" }}>
                {
                    (data.length > 0) &&
                    // <div dangerouslySetInnerHTML={{ __html: data[0].content }} className='main-content'></div>
                    <div className="mb-4" dangerouslySetInnerHTML={{ __html: data[0].content }}></div>
                }

                <Row className="mb-4">
                    <Col className="col-md-5 col-12 mainPage-logo__container" >
                        <img src="/images/Logo/logo_ready_short.png" alt="Логотип Опліч" />
                    </Col>
                    <Col className="col-md-7 col-12">
                        Львівське регіональне партнерство зайнятості "Опліч" створене задля сприяння вирішенню місцевих потреб у сфері зайнятості, підтримки і розвитку підприємництва, підвищення професійних компетентностей і кваліфікацій місцевих трудових ресурсів, сприяння працевлаштуванню ключових груп населення.
                    </Col>
                </Row>

                <div className="mb-4">
                    До складу партнерства увійшли представники громадських організацій, органів державної влади, об'єднань підприємців, навчальних установ і дві територіальні громади. Партнерство сформоване на добровільних засадах і є відкритим для нових учасників.
                </div>

                <div className="mb-4">
                    ЛРПЗ "Опліч" створене в межах проєкту "Інклюзивний ринок праці для створення робочих місць в Україні".
                </div>

                <div className="mb-5">
                    Фінансову підтримку проєкту забезпечує Міжнародна організація праці у співпраці з Міністерством закордонних справ Данії та Федерального уряду Німеччини та підтримки Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH.
                </div>
            </div>

            <Row className="my-4 mainPage-logoPartners__container">
                <Col className="mb-5 mainPage-logoPartners__row" lg={4} md={12}>
                    <Row>
                        <Col className="mainPage-logoPartners__container-img"><img className="mainPage-logoPartners__img" src="/images/Main/EBA.png" alt="Логотип EBA" /></Col>
                        <Col className="mainPage-logoPartners__container-img"><img className="mainPage-logoPartners__img" src="/images/Main/ЦПП.png" alt="Логотип ЦПП" /></Col>
                        <Col className="mainPage-logoPartners__container-img"><img className="mainPage-logoPartners__img" src="/images/Main/Моршинська міська рада.png" alt="Логотип Моршинська міська рада" /></Col>
                    </Row>
                </Col>
                <Col className="mb-5" lg={4} md={12}>
                    <Row>
                        <Col className="mainPage-logoPartners__container-img"><img className="mainPage-logoPartners__img" src="/images/Main/Львівський обласний центр зайнятості.png" alt="Логотип Львівський обласний центр зайнятості" /></Col>
                        <Col className="mainPage-logoPartners__container-img"><img className="mainPage-logoPartners__img" src="/images/Main/ЛТПП.png" alt="Логотип ЛТПП" /></Col>
                        <Col className="mainPage-logoPartners__container-img"><img className="mainPage-logoPartners__img" src="/images/Main/ЛОООР.png" alt="Логотип ЛОООР" /></Col>
                    </Row>
                </Col>
                <Col lg={4} md={12}>
                    <Row>
                        <Col className="mainPage-logoPartners__container-img"><img className="mainPage-logoPartners__img" src="/images/Main/ЛОДА.png" alt="Логотип ЛОДА" /></Col>
                        <Col className="mainPage-logoPartners__container-img"><img className="mainPage-logoPartners__img" src="/images/Main/ЛМР.png" alt="Логотип ЛМР" /></Col>
                        <Col className="mainPage-logoPartners__container-img"><img className="mainPage-logoPartners__img" src="/images/Main/ГО Жіночі перспективи.png" alt="Логотип ГО Жіночі перспективи" /></Col>
                    </Row>
                </Col>
            </Row>

            <div className=" main-content mt-3" >
                {
                    isOpen && !isSubmit &&
                    <div className='main-content'>
                        <h4 className='mb-3 forms_main_header' >Форма зворотнього зв'язку</h4>
                        {/* <FeedbackForm setIsSubmit={setIsSubmit} setNameSurname={setNameSurname} setIsOpen={setIsOpen} setImageURL={setImageURL}></FeedbackForm> */}
                        <FeedbackFormLayout setForm = { setForm }/>
                        {
                            form == 'Бізнес' &&
                            <FeedbackForm setIsSubmit={setIsSubmit} setNameSurname={setNameSurname} setIsOpen={setIsOpen} setImageURL={setImageURL}  form={form} setForm = {setForm}></FeedbackForm> 
                        }
                        {
                            form == 'ВПО' && 
                            <FeedbackFormVPO 
                                setIsSubmit={setIsSubmit} setNameSurname={setNameSurname} 
                                setIsOpen={setIsOpen} setImageURL={setImageURL} 
                                form={form} setForm = {setForm}
                            />
                        }
                    </div>
                }

                {/* Поле з текстом-подякою про успішне надсилання форми */}
                {
                    isSubmit &&
                    <div className="support_green_box px-5 my-5 main-content" ref={thanksRef}>
                        <div className="support_green_box_inner">
                            <ThanksForRequest nameSurname={nameSurname} />
                        </div>
                    </div>
                }
                {/* <ApiForm id={2}/> */}
            </div>

        </Container>
        <img
            src={imageURL}
            className='button_img'
            onClick={(e) => { openForm(e) }}
            alt='chat'>
        </img>
        </>

    );
};

export default Main;