import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import MainCard from '../MainCard';
// import isLoggedIn from '../../../myFunction/isLoggedIn';

const Veterans = () => {

    const data = [
        {
            id: 1,
            title: "Програми підтримки",
            text: "Перелік доступних на Львівщині пропозицій щодо ґрантових програм із підтримки або започаткування бізнесу Захисників та Захисниць. Обирайте пропозицію, найбільш прийнятну для реалізації власних бізнес ідей та позитивних змін життя.",
            imgSrc: "/images/Veterans/16x9/1-Програми підтримки.jpg",
            link: "/veterans/list-of-programs",
            target: "_self"
        },
        {
            id: 2,
            title: "Сім'ям Захисників та Захисниць",
            text: "У центрі уваги сім’ї учасників бойових дій, що не повернулися із зони військового конфлікту (зокрема, у полоні/вважаються зниклими безвісти).",
            imgSrc: "/images/Veterans/16x9/2-Сімям Захисників та Захисниць.jpg",
            link: "https://loda.gov.ua/key-projects/75381",
            target: "_blank"
        },
        {
            id: 3,
            title: "Медична допомога",
            text: "Пряма медична підтримки та після бойова гуманітарна допомога сприяють пом’якшенню негативних наслідків, які присутні після повернення Захисників та Захисниць із зони військового конфлікту, і передбачають реінтеграцію у суспільство, як ключовий елемент одужання від отриманої травми.",
            imgSrc: "/images/Veterans/16x9/3-Медична допомога.jpg",
            link: "https://loda.gov.ua/key-projects/70237",
            target: "_blank"
        },
        {
            id: 4,
            title: "Соціальне забезпечення",
            text: "Учасники бойових дій та особи, прирівняні до них, мають гарантовані державою соціальні пільги згідно зі статтями 12-14 Закону України «Про статус ветеранів війни, гарантії їх соціального захисту», а також наступні можливості.",
            imgSrc: "/images/Veterans/16x9/4-Соціальне забезпечення.jpg",
            link: "https://loda.gov.ua/key-projects/70247",
            target: "_blank"
        },
        {
            id: 5,
            title: "Психологічна допомога",
            text: "Швидшому відновленню Захисників та Захисниць сприяє не лише підтримка рідними та близькими людьми, але комплексна медико-психологічна фахова реабілітація. Куди звертатись, що необхідно для її отримання та інші дії, задля відновлення ментального здоров’я.",
            imgSrc: "/images/Veterans/16x9/5-Психологічна допомога.jpg",
            link: "https://loda.gov.ua/key-projects/70246",
            target: "_blank"
        },
        {
            id: 6,
            title: "Юридична допомога",
            text: "Контакти організацій та установ Львівщини, де працюють військові юристи, які допомагають Захисникам, Захисницям та їх сім'ям у вирішенні складних юридичних питань.",
            imgSrc: "/images/Veterans/16x9/6-Юридична допомога.jpg",
            link: "https://loda.gov.ua/key-projects/75375",
            target: "_blank"
        },
        {
            id: 7,
            title: "Дозвілля",
            text: "Для Захисників та Захисниць організовано проєкти, які запрошують їх на екскурсійні мандрівки Львівщиною, відвідини мистецьких закладів, цікавого проведення дозвілля.",
            imgSrc: "/images/Veterans/16x9/7-Дозвілля.jpg",
            link: "https://loda.gov.ua/key-projects/75378",
            target: "_blank"
        },
        {
            id: 8,
            title: "Навчання",
            text: "Розроблено програми соціально-професійної адаптації для отримання якісної безкоштовної освіти через різноманітні курси, інтерактивні підручники, е-платформи та форуми, інші ініціативи, покликані забезпечити доступ охочих до освіти.",
            imgSrc: "/images/Veterans/16x9/8-Навчання.jpg",
            link: "https://loda.gov.ua/key-projects/70238",
            target: "_blank"
        },
        {
            id: 9,
            title: "Активний ветеран",
            text: "Ініціативи від Захисників та Захисниць щодо реалізації корисних та актуальних для розвитку українського суспільства ідей, зокрема: патріотичне виховання молоді, утворення спілок ветеранів, ведення груп рівний-рівному тощо. Маєте власну думку, Вам сюди.",
            imgSrc: "/images/Veterans/16x9/9-Активний ветеран.jpg",
            link: "https://docs.google.com/forms/d/e/1FAIpQLSeLQbWvQZ0AxJOHE1nU48hUblms_503h5Hg-Rd-9him4Z5i3w/viewform?vc=0&c=0&w=1&flr=0",
            target: "_blank"
        },
        {
            id: 10,
            title: "Спорт",
            text: "Здоровий спосіб життя, спорт та фізична культура вважаються чи не найдієвішими засобами реабілітації (абілітації) учасників бойових дій, насамперед з інвалідністю. Тож, адаптивний спорт та фізкультурно-спортивна реабілітація через «Лігу Нескорених», «Активні парки» у громадах Львівщини та зручні локації спортивних майданчиків до Ваших послуг.",
            imgSrc: "/images/Veterans/16x9/10-Спорт.jpg",
            link: "https://loda.gov.ua/key-projects/75393",
            target: "_blank"
        },
        {
            id: 11,
            title: "Корисні контакти",
            text: "Маєте запитання, пропозиції тощо? Запрошуємо ознайомитись з корисними відкритими ресурсами, покликаними задля допомоги Захисникам та Захисницям.",
            imgSrc: "/images/Veterans/16x9/11-Корисні контакти.jpg",
            link: "https://loda.gov.ua/key-projects/75376",
            target: "_blank"
        },
    ];

    // Виклик функції чи залогінений
    // const isLogIn = isLoggedIn();
    // console.log('isLogIn', isLogIn);

    return (
        <Container className="mt-5">
            <Row className='wrapper business-container'>
                {
                    data.map((card) => {
                        return (
                            <Col className="col-md-6 col-12 mb-5 mainCard-card" key={card.id}>
                                <MainCard title={card.title} text={card.text} imgSrc={card.imgSrc} link={card.link} isLogIn={true} target={card.target} />
                            </Col>
                        )
                    })
                }
                <Col className="col-6 mb-5">
                </Col>
            </Row>
        </Container>
    );
};

export default Veterans;