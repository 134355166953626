import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import MentoringForm from "../../../Forms/MentoringForm";
import ThanksForRequest from "../../ThanksForRequest";

const MentoringProgram = ({ data }) => {

    const [isSubmit, setIsSubmit] = useState(false);
    // Для поля з текстом-подякою про успішне надсилання форми
    const [nameSurname, setNameSurname] = useState("#вказане ІП");
    // Для поля з текстом-подякою про успішне надсилання форми
    const thanksRef = useRef(null);

    // Після відправлення форми проскорлити екран, щоб поле з текстом-подякою про успішне надсилання форми було зверху екрана
    useEffect(() => {
        if (thanksRef.current) {
            thanksRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [isSubmit]);

    return (
        <div className="wrapper">
            <Container className="my-sm-5 business-mentoringProgram__container ">
                {
                    (data.length > 0) &&
                    // <div>
                    //     <div dangerouslySetInnerHTML={{ __html: data[0].data }}></div>
                    // </div>
                    <div dangerouslySetInnerHTML={{ __html: data[0].content }}></div>
                }

                {/* Форма заявки на менторську підтримку */}
                {
                    !isSubmit &&
                    <div className="my-5">
                        <h4 className="mb-4">Форма заявки на менторську підтримку:</h4>
                        <MentoringForm setIsSubmit={setIsSubmit} setNameSurname={setNameSurname} />
                    </div>
                }

                {/* Поле з текстом-подякою про успішне надсилання форми */}
                {
                    isSubmit &&
                    <div className="px-5 my-5 support_green_box support_green_box_inner" ref={thanksRef}>
                        <ThanksForRequest nameSurname={nameSurname} page={"business/mentoring-program"} />
                    </div>
                }
            </Container>
        </div>
    );
};

export default MentoringProgram;