import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';

// Для сторінки business/list-of-programs/:id та vpo/list-of-programs/:id
function Program({ data }) {

    return (
        <Container className='my-5 wrapper'>
            {
                (data != undefined) &&
                <div>
                    <h2 className='mb-5'>{data.name}</h2>
                    <div className='business-listOfPrograms__info'>
                        <div dangerouslySetInnerHTML={{ __html: data.newsFull }}>
                        </div>
                    </div>
                </div>
            }
            {/* <Link to={data.length > 0 && data[0].value.split(',')[1]}> */}
            {/* <Link to={`#`}>
                <Button className='btn-rout btn-disabled'>
                    Дізнатись більше
                </Button>
            </Link> */}

        </Container>
    )
}

export default Program;