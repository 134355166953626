import React from 'react'
import ReactCaptcha from 'modern-react-captcha';
import "./capcha.css"
import { Row } from 'react-bootstrap';

function Capcha({ setCapcha }) {
  const handleSuccess = () => {
    setCapcha(true);
    //alert('capcha true')
  }
  const handleFailure = () => {
    setCapcha(false);
    // console.log('fail')
  };
  return (


    <ReactCaptcha
      charset='ln'
      length={4}
      color='white'
      bgColor='#C0D2F1'
      reloadText='Змінити картинку'
      placeholder='введіть капчу'
      reload
      handleSuccess={handleSuccess}
      handleFailure={handleFailure}
    />

  )
}

export default Capcha
