import React from 'react';
import { Container, Nav, NavDropdown } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import logout from '../../myFunction/Logout';
import { useMediaQuery } from 'react-responsive';

// Навігація для залогіненого користувача
const NavibarLogIn = (props) => {
  // Для визначення ширини екрана
  const isDesktop = useMediaQuery({ minWidth: 769 });

  return (
    <div className="navibar">
      <Container className="navibar-container">
        <Navbar ref={props.dropdownRef} className="wrapper-navbar" collapseOnSelect expand="md" expanded={!props.isNavCollapsed}>
          <Navbar.Brand className='navibar__brand'>
            <Link to={`/`}>
              <img className='navibar__brand-img' src="/images/Logo/oplich_logo-01.png" alt="ЛОГОТИП" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={props.handleNavCollapse} />
          {
            isDesktop ?
              // {/* Для комп'ютора */ }
              <Navbar.Collapse id="basic-navbar-nav">
                <NavDropdown title="Бізнес" className="me-5" id="">
                  <NavDropdown.Item>
                    <Link to={`/business`}>
                      Бізнес
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to={`/business/list-of-programs`}>
                      Програми підтримки
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to={`/business/mentoring-program`}>
                      Менторська підтримка
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to={`/business/relocation-request`}>
                      Запит на релокацію
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to={`business/accomodation-offers`}>
                      Пропозиції розміщення
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="ВПО" className="me-5" id="">
                  <NavDropdown.Item href="#">
                    <Link to={`/vpo`}>
                      ВПО
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#">
                    <Link to={`/vpo/list-of-programs`}>
                      Програми підтримки
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#">
                    <Link to={`/vpo/job-search`}>
                      Пошук Роботи
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#">
                    <Link to={`/vpo/housing-search`}>
                      Пошук Житла
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Захисники" className="me-5" id="">
                  <NavDropdown.Item>
                    <Link to={`/veterans`}>
                      Захисники
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Link to={`/veterans/list-of-programs`}>
                      Програми підтримки бізнесу Захисників
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://loda.gov.ua/key-projects/75381" target="_blank">
                    Сім'ям Захисників та Захисниць
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://loda.gov.ua/key-projects/70237" target="_blank">
                    Медична допомога
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://loda.gov.ua/key-projects/70247" target="_blank">
                    Соціальне забезпечення
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://loda.gov.ua/key-projects/70246" target="_blank">
                    Психологічна допомога
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://loda.gov.ua/key-projects/75375" target="_blank">
                    Юридична допомога
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://loda.gov.ua/key-projects/75378" target="_blank">
                    Дозвілля
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://loda.gov.ua/key-projects/70238" target="_blank">
                    Навчання
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://docs.google.com/forms/d/e/1FAIpQLSeLQbWvQZ0AxJOHE1nU48hUblms_503h5Hg-Rd-9him4Z5i3w/viewform?vc=0&c=0&w=1&flr=0" target="_blank">
                    Активний ветеран
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://loda.gov.ua/key-projects/75393" target="_blank">
                    Спорт
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://loda.gov.ua/key-projects/75376" target="_blank">
                    Корисні контакти
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link className="me-5" as={Link} to={`/support`}>
                  Підтримка
                </Nav.Link>
                <Nav.Link className="me-5" as={Link} to={`/profile`}>
                  Профіль
                </Nav.Link>
                <Nav.Link className="" onClick={() => logout()}>
                  Вийти
                </Nav.Link>
              </Navbar.Collapse>
              :
              // {/* Для мобільних */}
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav.Link className="me-5" as={Link} to={`/business`}>
                  Бізнес
                </Nav.Link>
                <Nav.Link className="me-5" as={Link} to={`/vpo`}>
                  ВПО
                </Nav.Link>
                <Nav.Link className="me-5" as={Link} to={`/veterans`}>
                  Захисники
                </Nav.Link>
                <Nav.Link className="me-5" as={Link} to={`/support`}>
                  Підтримка
                </Nav.Link>

                {
                  props.localName ?
                    <>
                      <Nav.Link className="me-5" as={Link} to={`/profile`}>
                        Профіль
                      </Nav.Link>
                      <Nav.Link className="" onClick={() => logout()}>
                        Вийти
                      </Nav.Link>
                    </>
                    :
                    <Nav.Link className="" as={Link} to={`/login`}>
                      Вхід/реєстрація
                    </Nav.Link>
                }

              </Navbar.Collapse>
          }
        </Navbar>
      </Container>
    </div >
  );
};

export default NavibarLogIn;