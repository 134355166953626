import Navibar from './components/Header/Navibar';
import { Outlet } from 'react-router-dom';
import './App.css';
import './styles/content.scss'
import './styles/formsMobile.scss'
import Footer from './components/Footer/Footer';
import ScrollToTop from './myFunction/ScrollToTop';

function App() {
  return (
    <div className="App">
      {/* При зміні URL проскролити на верх сторінки */}
      <ScrollToTop />

      <Navibar />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
