import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Footer = () => {
    return (
        <div className='footer'>
            <Container className='my-3 pt-3 footer__container'>
                <Row className='my-3 footer-row footer-row__1'>
                    <Col className="col-lg-7 col-12 footer__logo-text">
                        <Row>
                            <Col className="col-lg-4 col-md-6 col-12 footer__logo" >
                                <img src="/images/Logo/oplich_logo-01.png" alt="ЛОГОТИП" />
                            </Col>
                            <Col className="col-lg-8 col-md-6 col-12 footer__text">
                                <span>
                                    Платформа розроблена в рамках Регіонального партнерства зайнятості у Львівській області "ОПЛІЧ" за підтримки Міжнародної організації праці та фінансування Міністерства закордонних справ Данії
                                </span>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-lg-5 col-12 footer__partners">
                        <img className='me-2' src="/images/Footer/Denmark logo.png" alt="MINISTRY OF FOREICH AFFAIRS OF DENMARK" />
                        <img src="/images/Footer/ILO logo.png" alt="Міжнародна організація праці" />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;