import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const JobSearchVPO = (props) => {
    return (
        //         <div>
        //             Тут ви можете переглянути "Єдиний портал <br />
        //             вакансій" сформувати запит і знайти відповідні <br />
        //             вакансії для подальшого працевлаштування.<br />
        //             Також, на порталі можна завантажити <br />
        //             своє Резюме, і зацікавлені роботодавці <br />
        //             зв'яжуться, безпосередньо з Вами.
        //         </div>

        <Container className="my-sm-5 business-mentoringProgram__container wrapper">
            <Row className='vpo-search' >
                <Col className="col-6">
                    <Image className="" src="/images/VPO/hanging-files-filling-cabinet-office.jpg" alt="ВПО - вакансії" width={400} />
                </Col>
                <Col className="col-6 d-flex direction-column" style={{ flexDirection: "column", justifyContent: "center", fontSize: "20px"}}>
                    {
                        (props.data.length > 0) &&
                        <div className='vpo-search__content' dangerouslySetInnerHTML={{ __html: props.data[0].content }}></div>
                    }
                    <Link className="mt-4" target="_blank" to="https://lviv.dcz.gov.ua/userSearch/vacancy">
                        <Button className="btn-rout">
                            Ознайомитись
                        </Button>
                    </Link>
                </Col>
            </Row>
        </Container>
    );
};

export default JobSearchVPO;