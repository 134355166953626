import React from 'react';
import { Image } from 'react-bootstrap';

// Зображення для сторінок (під header)
const PromoImg = (props) => {
    return (
        <div className="business-promo__container">
            <Image className="business-promo__img" src={props.imgSrc} alt={props.alt} />
        </div>
    );
};

export default PromoImg;